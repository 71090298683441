import React from 'react'
import Practicesmain from './Practicesmain'
import Practicescontent from './Practicescontent'

const Practices = () => {
  return (
    <>
        <Practicesmain />
        <Practicescontent />
    </>
  )
}

export default Practices
