import React from 'react'
import fullstackbanner1 from "../../Images/fullstackbanner1.jpg"
const Fullstackmain = () => {
  return (
    <>
      <section className='industrialsolution_banner'>
        <div className='bgimage_'>
          <img src={fullstackbanner1} className='img-fluid' alt='..' />
        </div>
        <div className='banner_wrap'>
          <h2 className='sec_headingbannertset'> End to end <span> FULLSTACK DEVELOPMENT </span> </h2>
        </div>
      </section>
    </>
  )
}

export default Fullstackmain
