import React from 'react'
import Fullstackmain from './Fullstackmain'
import Fullstackcontent from './Fullstackcontent'
import Fullstackmaincontent from './Fullstackmaincontent'

const Fullstack = () => {
  return (
      <>
            <Fullstackmain />
            <Fullstackcontent />
            <Fullstackmaincontent />
      </>
  )
}

export default Fullstack
