import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';

const GotoTop = () => {
    const routepath = useLocation();
    useEffect(() => {
        onTop()
    }, [routepath.pathname]);

    const onTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        })
    }
    return (
        <>

        </>
    )
}

export default GotoTop
