import React from 'react'
import img1 from "../../Images/Cygnus Pictures/Industrial Solutions/nathan-dumlao-66RxrYlPShI-unsplash.jpg";
const CustomerServiceSolution = () => {
  return (
    <section className="healthcare_main" id='LogisticsTransportation'>
    <div className="cus_container">
        <div className="healthcares_div">
            <div className="healthcares_div_left">
                <div className="">
                    <h4 className="hlthcaretetx_heading_text">Customer Service Solution</h4>
                    <p className="hlthcaretetx_para_text">
                    The Customer Service Solution allows you to deliver responsive customer service from anywhere, anytime, and from any device. This will equip your service engineers with everything they need to be more productive, resolve more cases, and satisfy your customers. They can log complaints, have a 360-degree view of information, allocate resources, track warranties, manage requests, and build preventive maintenance reports.
                    </p>
                </div>
            </div>
            <div className="healthcares_div_right">
                <div className="healthcares_div_img">
                    <img src={img1} alt="..." />
                </div>
            </div>
        </div>
        {/* <div className="healthcareswipper">

            <Swiper
                slidesPerView={3}
                spaceBetween={30}
                navigation={true}
                breakpoints={{
                    0: {
                        slidesPerView: 1,
                    },
                    400: {
                        slidesPerView: 2,
                    },
                    639: {
                        slidesPerView: 3,
                    },
                }}
                modules={[Navigation]}
                className='mySwiper'
            >
                <SwiperSlide className="">
                    <div className="healthcare_divsld_img">
                        <img src={img2} alt="..." />
                    </div>
                    <div className="">
                        <p className="slide_text_dtls_pp">
                            Dealer Management & Delivery
                        </p>
                    </div>
                </SwiperSlide>
                <SwiperSlide className="">
                    <div className="healthcare_divsld_img">
                        <img src={img3} alt="..." />
                    </div>
                    <div className="">
                        <p className="slide_text_dtls_pp">
                            Warehouse Management
                        </p>
                    </div>
                </SwiperSlide>
                <SwiperSlide className="">
                    <div className="healthcare_divsld_img">
                        <img src={img4} alt="..." />
                    </div>
                    <div className="">
                        <p className="slide_text_dtls_pp">
                            Transportation Analytics & Data Integration
                        </p>
                    </div>
                </SwiperSlide>
                <SwiperSlide className="">
                    <div className="healthcare_divsld_img">
                        <img src={img3} alt="..." />
                    </div>
                    <div className="">
                        <p className="slide_text_dtls_pp">
                            Warehouse Management
                        </p>
                    </div>
                </SwiperSlide>
                <SwiperSlide className="">
                    <div className="healthcare_divsld_img">
                        <img src={img4} alt="..." />
                    </div>
                    <div className="">
                        <p className="slide_text_dtls_pp">
                            Transportation Analytics & Data Integration
                        </p>
                    </div>
                </SwiperSlide>
                <SwiperSlide className="">
                    <div className="healthcare_divsld_img">
                        <img src={img3} alt="..." />
                    </div>
                    <div className="">
                        <p className="slide_text_dtls_pp">
                            Warehouse Management
                        </p>
                    </div>
                </SwiperSlide>
                <SwiperSlide className="">
                    <div className="healthcare_divsld_img">
                        <img src={img4} alt="..." />
                    </div>
                    <div className="">
                        <p className="slide_text_dtls_pp">
                            Transportation Analytics & Data Integration
                        </p>
                    </div>
                </SwiperSlide>
            </Swiper>
        </div> */}
    </div>
</section>
  )
}

export default CustomerServiceSolution
