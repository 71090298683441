import React from 'react'

const Legacymodernizationcontent = () => {
    return (
        <>
            <section className='SectorWeServer_sec'>
                <div className='cus_container'>
                    <h2 className='sec_heading'>Your Legacy Modernization Expert</h2>
                    <p className='hlthcaretetx_para_text'>
                    Modernizing legacy systems is essential to stay competitive in today's rapidly evolving technological landscape. We offer Legacy Modernization services tailored to upgrade and transform outdated systems, enhancing their functionality and performance. 
                    </p>

              


                </div>
            </section>
        </>
    )
}

export default Legacymodernizationcontent
