import React from 'react'
import expertiesdoman from "../../Images/expertiesdoman.jpg";
import automativesoftwareengineer from "../../Images/automativesoftwareengineer.jpg";
import comprehensivesolution from "../../Images/comprehensivesolution.jpg";
import collboratepartnership from "../../Images/collboratepartnership.jpg";
import driveninnovation from "../../Images/driveninnovation.jpg";
import trailoredsavvallsolution from "../../Images/trailoredsavvallsolution.jpg";
import cygnuspriidegttsimg from "../../Images/cygnuspriidegttsimg.jpg"

const Automotivecontentmain = () => {
  return (
    <>
      <section className="softwaresevices_main" >
        <div className="cus_container">
          <div className="healthcares_divnewadd">
            <div className="healthcares_div_left">
              <div className="">
                <h4 className="hlthcaretetx_heading_text">Expertise in the Automotive Domain</h4>
                <p className="hlthcaretetx_para_text">
                  Having collaborated with various esteemed clients in the automotive industry, we bring a wealth of specialized experience to every engagement. Our dedicated team is uniquely positioned to address the distinctive challenges faced by automotive enterprises, enabling them to enhance operational efficiency, foster innovation, and realize their strategic ambitions.
                </p>
              </div>
            </div>
            <div className="healthcares_div_right">
              <div className="healthcares_div_img">
                <img src={expertiesdoman} alt="..." />
              </div>
            </div>
          </div>

          <div className="healthcares_divnewadd">
            <div className="healthcares_div_left">
              <div className="">
                <h4 className="hlthcaretetx_heading_text">Automotive Software Engineering</h4>
                <p className="hlthcaretetx_para_text">
                  Our core strength lies in automotive software engineering, where we harness cutting-edge technologies and methodologies to devise robust solutions. From the development of embedded systems to ensuring compliance with stringent industry standards, our engineers excel in creating software that meets the rigorous demands of today’s automotive landscape.
                </p>
              </div>
            </div>
            <div className="healthcares_div_right">
              <div className="healthcares_div_img">
                <img src={automativesoftwareengineer} alt="..." />
              </div>
            </div>
          </div>

          <div className="healthcares_divnewadd">
            <div className="healthcares_div_left">
              <div className="">
                <h4 className="hlthcaretetx_heading_text">Comprehensive Solutions</h4>
                <p className="hlthcaretetx_para_text">
                  We offer an extensive suite of services that encompass every facet of automotive software development. This includes meticulous requirements analysis, sophisticated software design, rigorous coding, comprehensive testing, and ongoing support. By managing the entire software lifecycle, we assure that our clients benefit from streamlined operations and timely project delivery.
                </p>
              </div>
            </div>
            <div className="healthcares_div_right">
              <div className="healthcares_div_img">
                <img src={comprehensivesolution} alt="..." />
              </div>
            </div>
          </div>

          <div className="healthcares_divnewadd">
            <div className="healthcares_div_left">
              <div className="">
                <h4 className="hlthcaretetx_heading_text">Collaborative Partnerships</h4>
                <p className="hlthcaretetx_para_text">
                  Recognizing the paramount importance of collaboration in the automotive domain, we engage closely with our clients to cultivate effective partnerships. Our approach is anchored in transparent communication, ensuring that our strategies are intricately aligned with your objectives and deliver outcomes that reflect your vision.
                </p>
              </div>
            </div>
            <div className="healthcares_div_right">
              <div className="healthcares_div_img">
                <img src={collboratepartnership} alt="..." />
              </div>
            </div>
          </div>

          <div className="healthcares_divnewadd">
            <div className="healthcares_div_left">
              <div className="">
                <h4 className="hlthcaretetx_heading_text">Driven by Innovation</h4>
                <p className="hlthcaretetx_para_text">
                  We place a strong emphasis on innovation in all our endeavors. By remaining attuned to the latest technological advancements in the automotive field, we equip our clients to leverage emerging trends such as connected vehicles, autonomous driving, and electrification, thereby maintaining a competitive edge in a rapidly evolving market.
                </p>
              </div>
            </div>
            <div className="healthcares_div_right">
              <div className="healthcares_div_img">
                <img src={driveninnovation} alt="..." />
              </div>
            </div>
          </div>

          <div className="healthcares_divnewadd">
            <div className="healthcares_div_left">
              <div className="">
                <h4 className="hlthcaretetx_heading_text">Tailored and Scalable Solutions</h4>
                <p className="hlthcaretetx_para_text">
                  Understanding that each organization possesses unique requirements, we customize our services to meet your specific needs while allowing for scalability as your business expands. Whether you are an emerging startup or a seasoned leader in the automotive sector, we offer the expertise necessary to enhance your journey.
                </p>
              </div>
            </div>
            <div className="healthcares_div_right">
              <div className="healthcares_div_img">
                <img src={trailoredsavvallsolution} alt="..." />
              </div>
            </div>
          </div>



        </div>
      </section>



      <section className="softwaresevices_main" >
        <div className="cus_container">
          <div className='choosedivflx'>
            <div className='choosedivleft'>
              <h4 className="hlthcaretetx_heading_text">Why Choose Cygnus Professionals?</h4>
              <p className="hlthcaretetx_para_text_new">
                <span> 	Industry Acumen : </span> Our team comprises seasoned professionals with extensive experience in the automotive sector, enabling us to provide insights and solutions that are both relevant and impactful.
              </p>

              <p className="hlthcaretetx_para_text_new">
                <span>	Client-Centric Focus :</span> We prioritize your aspirations and endeavor to forge enduring relationships. By gaining a deep understanding of your challenges, we devise strategies that drive meaningful success.
              </p>


              <p className="hlthcaretetx_para_text_new">
                <span> 	Commitment to Quality :  </span>Our dedication to excellence ensures that every project undergoes rigorous quality assurance processes, delivering reliable and high-caliber software solutions.
              </p>


              <p className="hlthcaretetx_para_text_new">
                <span> Proven Excellence : </span> With a robust history of supporting numerous automotive clients, we have consistently delivered projects on time and within budget, reflecting our commitment to excellence.
              </p>

            </div>
            <div className='choosedivright'>
              <div className='choosedivrightimgmain'>
                <img src={cygnuspriidegttsimg} alt='...' />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="softwaresevices_main" >
        <div className="cus_container">
          <div className=''>
            <h4 className="hlthcaretetx_heading_text">Partner With Us</h4>
            <p className="hlthcaretetx_para_text">
              Join the distinguished automotive organizations that have transformed their operations through the expertise of Cygnus Professionals. Together, we can traverse the intricacies of automotive software engineering, driving your business toward innovation and sustainable success.
              Contact us today to discover how we can propel your automotive initiatives and ensure your projects achieve their highest potential with precision and excellence.

            </p>
          </div>
        </div>
      </section>

    </>
  )
}

export default Automotivecontentmain
