import React from 'react'
import Machinelearning from "../../Images/Machinelearning.png";
import discover from "../../Images/discover.jpg"
const MachineLearning = () => {
    return (
        <>
            <section className="softwaresevices_main" >
                <div className="cus_container">
                    <div className="healthcares_div">
                        <div className="healthcares_div_left">
                            <div className="">
                                <h4 className="hlthcaretetx_heading_text">MACHINE LEARNING AND AI EXPERTISE</h4>
                                <p className="hlthcaretetx_para_text">
                                    AI has one witticism – an amalgamation of the advantages of the human brain and the computer processor. With the assistance of various tools like machine learning and NLP, one can utilize past experiences and client data to propose insights and automate convoluted activities for the business while improving user experience as well.
                                </p>
                            </div>
                        </div>
                        <div className="healthcares_div_right">
                            <div className="healthcares_div_img">
                                <img src={Machinelearning} alt="..." />
                            </div>
                        </div>
                    </div>

                    <div className=''>
                        <h4 className='assisttxtpp'>WE ASSIST YOU WITH</h4>
                        <p className='assisttxtppmainpp'>
                            Full lifecycle, from concept creation to development and after support. Designing working prototypes or systems, with all required documentation, onsite consulting, and training from experts during the production process. Putting data analysis processes in place to identify data and user behavior, and accordingly, enabling the machine to make a prediction or categorize information based on the provided data without a shred of programming.
                        </p>
                        <h4 className='assisttxtpp'>YOU GOT PROBLEM ; WE GOT SKILLS !</h4>


                        <div className='disprocremnchamdivtopbottommixflx'>
                            <div className='disprocremnchamdivtopbottommix'>
                                <div className='disprocremnchamdivtopbottobg'>
                                    <div className='disprocremnchamdivtop'>
                                        <img src={discover} alt='...' />
                                    </div>
                                    <div className='disprocremnchamdivbottom'>
                                        <h4 className='assitstxtpphhhh'>
                                            DISCOVERY
                                        </h4>
                                        <p className='assitstxtpphhhhparaa'>
                                            In this phase, we review your existing skills and define future business goals to make suggestions for tools, technology, and architecture.
                                        </p>
                                    </div>
                                </div>
                            </div>


                            <div className='disprocremnchamdivtopbottommix'>
                                <div className='disprocremnchamdivtopbottobg'>
                                    <div className='disprocremnchamdivtop'>
                                        <img src={discover} alt='...' />
                                    </div>
                                    <div className='disprocremnchamdivbottom'>
                                        <h4 className='assitstxtpphhhh'>
                                            PROOF OF CONCEPT
                                        </h4>
                                        <p className='assitstxtpphhhhparaa'>
                                            Not every problem needs machine learning. That’s why; we run a test on a small system just to verify the viability of the machine-learning model.
                                        </p>
                                    </div>
                                </div>
                            </div>


                            <div className='disprocremnchamdivtopbottommix'>
                                <div className='disprocremnchamdivtopbottobg'>
                                    <div className='disprocremnchamdivtop'>
                                        <img src={discover} alt='...' />
                                    </div>
                                    <div className='disprocremnchamdivbottom'>
                                        <h4 className='assitstxtpphhhh'>
                                            CREATION
                                        </h4>
                                        <p className='assitstxtpphhhhparaa'>
                                            If the test turns out to be positive then we introduce the system into the production while keeping the costs of implementation and maintenance during deployment in check.
                                        </p>
                                    </div>
                                </div>
                            </div>


                            <div className='disprocremnchamdivtopbottommix'>
                                <div className='disprocremnchamdivtopbottobg'>
                                    <div className='disprocremnchamdivtop'>
                                        <img src={discover} alt='...' />
                                    </div>
                                    <div className='disprocremnchamdivbottom'>
                                        <h4 className='assitstxtpphhhh'>
                                            ENHANCEMENT
                                        </h4>
                                        <p className='assitstxtpphhhhparaa'>
                                            Today’s technology keeps changing. Thus, we keep improving the previously built models to maintain the quality and to keep up with the competition.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>

                </div>
            </section>
        </>
    )
}

export default MachineLearning
