import React from 'react'
import "../../Css/Work.css";
import Workwith from "./Workwith"
import Whywork from './Whywork';
import Carreropertunity from './Carreropertunity';
import ClientSays from './ClientSays';
const workmain = () => {
  return (
  <>
    <Workwith/>
    <Whywork/>
    <Carreropertunity/>
    {/* <ClientSays/> */}
  </>
  )
}

export default workmain
