import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';

import { Navigation } from 'swiper/modules';
// import img1 from "../../Images/Cygnus Pictures/Industrial Solutions/ElectronicHealth.jpg";
import img2 from "../../Images/Cygnus Pictures/Industrial Solutions/ElectronicHealth.jpg";
import img3 from "../../Images/Cygnus Pictures/Industrial Solutions/HospitalManagement.png";
import img4 from "../../Images/Cygnus Pictures/Industrial Solutions/PracticeManagement.png";
import img5 from "../../Images/Cygnus Pictures/Industrial Solutions/pina-messina-kfJkpeI6Lgc-unsplash.jpg";
import img6 from "../../Images/Cygnus Pictures/Industrial Solutions/thisisengineering-mvbtVeRVJzg-unsplash.jpg"
import img7 from "../../Images/Cygnus Pictures/Industrial Solutions/Screenshot 2024-07-11 172342.png";
import img8 from "../../Images/Cygnus Pictures/Industrial Solutions/Screenshot 2024-07-11 172510.png";
import img9 from "../../Images/Cygnus Pictures/Industrial Solutions/nik-73_kRzs9sqo-unsplash.jpg";
import img10 from "../../Images/Cygnus Pictures/Industrial Solutions/Screenshot 2024-07-11 202251.png";
import img11 from "../../Images/Cygnus Pictures/Industrial Solutions/grin-dcULNhRiRrc-unsplash.jpg";
import img12 from "../../Images/Cygnus Pictures/Industrial Solutions/brooke-cagle--uHVRvDr7pg-unsplash.jpg";
import img13 from "../../Images/Cygnus Pictures/Industrial Solutions/centre-for-ageing-better-j3ay5s6Irlk-unsplash.jpg";
import img14 from "../../Images/Cygnus Pictures/Industrial Solutions/luke-chesser-JKUTrJ4vK00-unsplash.jpg";
import img15 from "../../Images/Cygnus Pictures/Industrial Solutions/cdc-zGzZvLO5Brs-unsplash.jpg";
import img16 from "../../Images/Cygnus Pictures/Industrial Solutions/azzedine-rouichi-VMB8B-5PeR4-unsplash.jpg";
import img17 from "../../Images/Cygnus Pictures/Industrial Solutions/thisisengineering-jqD8bSQY6Ic-unsplash.jpg";
import img18 from "../../Images/Cygnus Pictures/Industrial Solutions/nathan-dumlao-66RxrYlPShI-unsplash.jpg";
import img19 from "../../Images/Cygnus Pictures/Industrial Solutions/nathan-dumlao-66RxrYlPShI-unsplash.jpg";
import img20 from "../../Images/Cygnus Pictures/Industrial Solutions/nathan-dumlao-66RxrYlPShI-unsplash.jpg";
import img21 from "../../Images/Cygnus Pictures/Industrial Solutions/jason-goodman-Oalh2MojUuk-unsplash.jpg";
import img22 from "../../Images/Cygnus Pictures/Industrial Solutions/ruchindra-gunasekara-GK8x_XCcDZg-unsplash.jpg";
import img23 from "../../Images/Cygnus Pictures/Industrial Solutions/jakub-zerdzicki-ykgLX_CwtDw-unsplash.jpg";
import img24 from "../../Images/Cygnus Pictures/Industrial Solutions/maksym-kaharlytskyi-kDVaFjoQf4M-unsplash.jpg";
const IndustrialSolutionallslider = () => {
    return (
        <section className='IndustrialSolutionallslider'>
        <div className="cus_container">
            <div className="healthcareswipper">

                <Swiper
                    slidesPerView={3}
                    spaceBetween={30}
                    navigation={true}
                    modules={[Navigation]}
                    breakpoints={{
                        0: {
                            slidesPerView: 1,
                        },
                        400: {
                            slidesPerView: 2,
                        },
                        639: {
                            slidesPerView: 3,
                        },
                    }}
                    className='mySwiper'
                >
                    <SwiperSlide className="">
                        <div className="healthcare_divsld_img">
                            <img src={img2} alt="..." />
                        </div>
                        <div className="">
                            <p className="slide_text_dtls_pp">
                                Electronic Health records and Management
                            </p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="">
                        <div className="healthcare_divsld_img">
                            <img src={img3} alt="..." />
                        </div>
                        <div className="">
                            <p className="slide_text_dtls_pp">Hospital Management System</p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="">
                        <div className="healthcare_divsld_img">
                            <img src={img4} alt="..." />
                        </div>
                        <div className="">
                            <p className="slide_text_dtls_pp">Practice Management Software</p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="">
                        <div className="healthcare_divsld_img">
                            <img src={img5} alt="..." />
                        </div>
                        <div className="">
                            <p className="slide_text_dtls_pp">
                            Pharmaceutical and Life Sciences Industry
                            </p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="">
                        <div className="healthcare_divsld_img">
                            <img src={img6} alt="..." />
                        </div>
                        <div className="">
                            <p className="slide_text_dtls_pp">Clinical Trail Management Software Development</p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="">
                        <div className="healthcare_divsld_img">
                            <img src={img7} alt="..." />
                        </div>
                        <div className="">
                            <p className="slide_text_dtls_pp">Pharma Manufacturing & commercialization Software Development</p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="">
                        <div className="healthcare_divsld_img">
                            <img src={img8} alt="..." />
                        </div>
                        <div className="">
                            <p className="slide_text_dtls_pp">Pharma Distribution Software Development</p>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide className="">
                        <div className="healthcare_divsld_img">
                            <img src={img9} alt="..." />
                        </div>
                        <div className="">
                            <p className="slide_text_dtls_pp">Payment Gateway & Easy Integration</p>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide className="">
                        <div className="healthcare_divsld_img">
                            <img src={img10} alt="..." />
                        </div>
                        <div className="">
                            <p className="slide_text_dtls_pp">Mobile Banking & Loan Management Software</p>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide className="">
                        <div className="healthcare_divsld_img">
                            <img src={img11} alt="..." />
                        </div>
                        <div className="">
                            <p className="slide_text_dtls_pp">Analytics and reporting with Omnichannel experience</p>
                        </div>
                    </SwiperSlide>

                    
                    <SwiperSlide className="">
                        <div className="healthcare_divsld_img">
                            <img src={img12} alt="..." />
                        </div>
                        <div className="">
                            <p className="slide_text_dtls_pp">Network Planning and Designing</p>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide className="">
                        <div className="healthcare_divsld_img">
                            <img src={img13} alt="..." />
                        </div>
                        <div className="">
                            <p className="slide_text_dtls_pp">Inventory Management Solutions to Enhance Operation</p>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide className="">
                        <div className="healthcare_divsld_img">
                            <img src={img14} alt="..." />
                        </div>
                        <div className="">
                            <p className="slide_text_dtls_pp">Geo-Spatial Data Analytics</p>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide className="">
                        <div className="healthcare_divsld_img">
                            <img src={img15} alt="..." />
                        </div>
                        <div className="">
                            <p className="slide_text_dtls_pp">General Health & Safety</p>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide className="">
                        <div className="healthcare_divsld_img">
                            <img src={img16} alt="..." />
                        </div>
                        <div className="">
                            <p className="slide_text_dtls_pp">Prescient Maintainace</p>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide className="">
                        <div className="healthcare_divsld_img">
                            <img src={img17} alt="..." />
                        </div>
                        <div className="">
                            <p className="slide_text_dtls_pp">Delivers World-class Management Software</p>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide className="">
                        <div className="healthcare_divsld_img">
                            <img src={img18} alt="..." />
                        </div>
                        <div className="">
                            <p className="slide_text_dtls_pp">Customer Service Solution</p>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide className="">
                        <div className="healthcare_divsld_img">
                            <img src={img19} alt="..." />
                        </div>
                        <div className="">
                            <p className="slide_text_dtls_pp">Support Multiple Tax rats & Payment Models</p>
                        </div>
                    </SwiperSlide>

                    
                    <SwiperSlide className="">
                        <div className="healthcare_divsld_img">
                            <img src={img20} alt="..." />
                        </div>
                        <div className="">
                            <p className="slide_text_dtls_pp">Supply Chain Planning during the crisis</p>
                        </div>
                    </SwiperSlide>

                         
                    <SwiperSlide className="">
                        <div className="healthcare_divsld_img">
                            <img src={img21} alt="..." />
                        </div>
                        <div className="">
                            <p className="slide_text_dtls_pp">Dealer Management & Delivery</p>
                        </div>
                    </SwiperSlide>


                         
                    <SwiperSlide className="">
                        <div className="healthcare_divsld_img">
                            <img src={img22} alt="..." />
                        </div>
                        <div className="">
                            <p className="slide_text_dtls_pp">Warehouse Management</p>
                        </div>
                    </SwiperSlide>

                         
                    <SwiperSlide className="">
                        <div className="healthcare_divsld_img">
                            <img src={img23} alt="..." />
                        </div>
                        <div className="">
                            <p className="slide_text_dtls_pp">Advanced Analytics</p>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide className="">
                        <div className="healthcare_divsld_img">
                            <img src={img24} alt="..." />
                        </div>
                        <div className="">
                            <p className="slide_text_dtls_pp">Network Protection</p>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
        </div>
        </section>
    )
}

export default IndustrialSolutionallslider
