import React from 'react'

const Mobileappscontent = () => {
    return (
        <>
            <section className='SectorWeServer_sec'>
                <div className='cus_container'>
                    <h2 className='sec_heading'>Your Mobile App Development Partner</h2>
                    <p className='hlthcaretetx_para_text'>
                        In the dynamic world of mobile app development, where over 3.3 million apps compete on the Google Play Store, standing out is no easy feat. Cygnus understands the challenges developers face in this crowded space and offers comprehensive mobile app development services to help your app shine.
                    </p>
                    <p className='hlthcaretetx_para_text'>
                        At Cygnus, we take a holistic approach to app development, encompassing everything from initial concept to continuous support. Our focus is on delivering top-notch apps that resonate with users and drive tangible results. Here's how we pave the way for your app's success
                    </p>

                </div>
            </section>
        </>
    )
}

export default Mobileappscontent
