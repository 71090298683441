import React from 'react'
import awards from "../../Images/Cygnus Pictures/Homepage/awards.png";
import img1 from "../../Images/Cygnus Pictures/img1.png";
import img2 from "../../Images/Cygnus Pictures/img2.png";
import img3 from "../../Images/Cygnus Pictures/img3.jpeg";
import img4 from "../../Images/Cygnus Pictures/img4.jpeg";
import img5 from "../../Images/Cygnus Pictures/img5.jpeg";
const Awards = () => {
  return (
   <>
        <section className='achebvennemeygsttsection' style={{ backgroundImage: `url(${awards})` }}>
            <div className='cus_container'>
                <div className='innovatioinbgimg' >
                    <div className='innovationndivoimmggpading'>
                        <h4 className='iinovvationntxtppop'>Achievements</h4>
                        <p className='certtaiinitxtppp'>
                            Certificates and awards that we were awarded by.
                        </p>

                        <div className='certtsiisfoimdivflx'>

                            <div className='certtsiisfoimdivimgmain'>
                                <img src={img1} alt='...' />
                            </div>


                            <div className='certtsiisfoimdivimgmain'>
                                <img src={img2} alt='...' />
                            </div>

                            <div className='certtsiisfoimdivimgmain'>
                                <img src={img3} alt='...' />
                            </div>


                            <div className='certtsiisfoimdivimgmain'>
                                <img src={img4} alt='...' />

                            </div>
                            <div className='certtsiisfoimdivimgmain'>
                                <img src={img5} alt='...' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
   
   </>
  )
}

export default Awards
