import React from 'react'
import CLOUDTECHNOLOGIESnweww from "../../Images/CLOUDTECHNOLOGIESnweww.jpg"
const Cloudtechnologiesmain = () => {
  return (
    <section className='industrialsolution_banner'>
        <div className='bgimage_'>
          <img src={CLOUDTECHNOLOGIESnweww} className='img-fluid' alt='..' />
        </div>
        <div className='banner_wrap'>
          <h2 className='sec_headingbannertset'> End to end <span> CLOUD TECHNOLOGIES  </span> </h2>
        </div>
      </section>
  )
}

export default Cloudtechnologiesmain
