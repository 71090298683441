import React from 'react'

const Mspprogramcontent = () => {
  return (
    //     <section className='SectorWeServer_sec'>
    //     <div className='cus_container'>
    //         <h2 className='sec_heading'>The Sector We Serve  MSP PROGRAMS</h2>


    //         <p className='hlthcaretetx_para_text'>
    //         Cygnus Professionals offers a comprehensive managed service program that provides our clients with a flexible, cost-effective, and scalable solution for their engineering staffing needs. Our managed service program is designed to help clients optimize their workforce, reduce costs, and improve operational efficiency. Here are some of the highlights of our managed service program
    //         </p>

    //     </div>
    // </section>

    <>
      <section className='SectorWeServer_sec'>
        <div className='cus_container'>
          {/* <h2 className='sec_heading'>Driving Innovation and Efficiency in a Digital World </h2> */}
          <h2 className='sec_heading'>The Sector We Serve  MSP PROGRAMS</h2>
          <p className='hlthcaretetx_para_text'>
            Cygnus Professionals offers a comprehensive managed service program that provides our clients with a flexible, cost-effective, and scalable solution for their engineering staffing needs. Our managed service program is designed to help clients optimize their workforce, reduce costs, and improve operational efficiency. Here are some of the highlights of our managed service program
          </p>
        </div>
      </section>

    </>
  )
}

export default Mspprogramcontent
