import React from 'react'
import Softwaremain from './Softwaremain'
import Softwaresolutioncontent from './Softwaresolutioncontent'
import MachineLearning from './MachineLearning'
import Softwarealexa from './Softwarealexa'
import Mobileappdevlopment from './Mobileappdevlopment'
import SoftwareIot from './SoftwareIot'
import AzureData from './AzureData'

const Index = () => {
  return (
  <>
   <Softwaremain/>
   <Softwaresolutioncontent/>
   <MachineLearning/>
   <Softwarealexa/>
    <Mobileappdevlopment/>
    <SoftwareIot/>
    <AzureData/>
  </>
  )
}

export default Index
