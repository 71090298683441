import React from 'react'

const Excutivesearchcontent = () => {
  return (
    <>
      <section className='SectorWeServer_sec'>
        <div className='cus_container'>
          {/* <h2 className='sec_heading'>Driving Innovation and Efficiency in a Digital World </h2> */}

          <p className='hlthcaretetx_para_text'>
                  In today’s dynamic business environment, agility and expertise are paramount. At Cygnus, our Contingent Staffing services are crafted to provide you with exceptional talent and strategic solutions that drive your success. We offer a range of specialized staffing services tailored to meet the unique demands of various sectors.
                </p>



        </div>
      </section>
    </>
  )
}

export default Excutivesearchcontent
