import React from 'react'
import img1 from "../../Images/Cygnus Pictures/Industrial Solutions/ElectronicHealth.jpg";
import img3 from "../../Images/Cygnus Pictures/Industrial Solutions/HospitalManagement.png";
import img4 from "../../Images/Cygnus Pictures/Industrial Solutions/PracticeManagement.png";
const Healthhospitalpractice = () => {
    return (
        <>
            <section className='healthcarepractice'>
                <div className='cus_container'>
                    <div className='healthcarepracticeflx'>
                        <div className='healthcarepracticedibimg'>
                            <div className='healthcarepracticedibimgmain'>
                                 <img src={img1} alt="..." />
                            </div>
                            <p className='slide_text_dtls_pp'>Electronic health records Management</p>
                        </div>
                        <div className='healthcarepracticedibimg'>
                            <div className='healthcarepracticedibimgmain'>
                            <img src={img3} alt="..." />
                            </div>
                            <p className='slide_text_dtls_pp'>Hospital Management System</p>
                        </div>
                        <div className='healthcarepracticedibimg'>
                            <div className='healthcarepracticedibimgmain'>
                            <img src={img4} alt="..." />
                            </div>
                            <p className='slide_text_dtls_pp'>Practice Management Software</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Healthhospitalpractice
