import React from 'react'
import Mobileappsmain from './Mobileappsmain'
import Mobileappscontent from './Mobileappscontent'
import Mobileappsmaincontent from './Mobileappsmaincontent'

const Mobileapps = () => {
    return (
        <>
            <Mobileappsmain />
            <Mobileappscontent />
            <Mobileappsmaincontent />
        </>
    )
}

export default Mobileapps
