import React from 'react'

const Aimlcontent = () => {
  return (
     <>
       <section className='SectorWeServer_sec'>
        <div className='cus_container'>
            <h2 className='sec_heading'>Your AI Partner for Business Transformation</h2>
          <p className='hlthcaretetx_para_text'>
          At Cygnus, we leverage Artificial Intelligence (AI) and Machine Learning (ML) to revolutionize business operations, driving innovation and efficiency through data-driven strategies. Our AI/ML services enhance user experience, streamline processes, and deliver actionable insights
          </p>

          <h2 className='ggpappadinbtm'>
          Here's how we make it happen :
          </h2>


        </div>
      </section>
     </>
  )
}

export default Aimlcontent
