import React from 'react'
import img1 from "../../Images/Cygnus Pictures/Industrial Solutions/Screenshot 2024-07-11 172342.png";
const PharmaManufacturing = () => {
  return (
   <>
       <section className="healthcare_main" id='Bankingtelecomindustry'>
                <div className="cus_container">
                    <div className="healthcares_div">
                        <div className="healthcares_div_left">
                            <div className="">
                                <h4 className="hlthcaretetx_heading_text">Pharma Manufacturing & commercialization Software Development</h4>
                                <p className="hlthcaretetx_para_text">
                                Stay coordinated, keep up severe quality administration and smooth out pharma assembling to meet customer’s determinations and administrative prerequisites with the assistance such as Production planning and procurement software, Traceability and recall management software, Product quality management software.

                                </p>
                            </div>
                        </div>
                        <div className="healthcares_div_right">
                            <div className="healthcares_div_img">
                                <img src={img1} alt="..." />
                            </div>
                        </div>
                    </div>
                    {/* <div className="healthcareswipper">

                        <Swiper
                            slidesPerView={3}
                            spaceBetween={30}
                            navigation={true}
                       
                            breakpoints={{
                                0: {
                                    slidesPerView: 1,
                                },
                                400: {
                                    slidesPerView: 2,
                                },
                                639: {
                                    slidesPerView: 3,
                                },
                            }}
                            modules={[Navigation]}
                            className='mySwiper'
                        >
                            <SwiperSlide className="">
                                <div className="healthcare_divsld_img">
                                    <img src={img2} alt="..." />
                                </div>
                                <div className="">
                                    <p className="slide_text_dtls_pp">
                                        Payment Gateway & Easy Integration
                                    </p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="">
                                <div className="healthcare_divsld_img">
                                    <img src={img3} alt="..." />
                                </div>
                                <div className="">
                                    <p className="slide_text_dtls_pp">
                                        Mobile Banking & Loan Management Software
                                    </p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="">
                                <div className="healthcare_divsld_img">
                                    <img src={img4} alt="..." />
                                </div>
                                <div className="">
                                    <p className="slide_text_dtls_pp">
                                        Analytics and reporting with Omnichannel experience
                                    </p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="">
                                <div className="healthcare_divsld_img">
                                    <img src={img3} alt="..." />
                                </div>
                                <p className="slide_text_dtls_pp">
                                    Mobile Banking & Loan Management Software
                                </p>
                            </SwiperSlide>
                            <SwiperSlide className="">
                                <div className="healthcare_divsld_img">
                                    <img src={img4} alt="..." />
                                </div>
                                <p className="slide_text_dtls_pp">
                                    Analytics and reporting with Omnichannel experience
                                </p>
                            </SwiperSlide>
                            <SwiperSlide className="">
                                <p className="slide_text_dtls_pp">
                                    Mobile Banking & Loan Management Software
                                </p>
                                <div className="">
                                    <p className="slide_text_dtls_pp">Pharma Manufacturing & commercialization Software Development</p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="">
                                <div className="healthcare_divsld_img">
                                    <img src={img4} alt="..." />
                                </div>
                                <p className="slide_text_dtls_pp">
                                    Analytics and reporting with Omnichannel experience
                                </p>
                            </SwiperSlide>
                        </Swiper>
                    </div> */}
                </div>
        </section>
   </>
  )
}

export default PharmaManufacturing
