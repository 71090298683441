import React from 'react'
import Machinelearning from "../../Images/Machinelearning.png";
import TechnologyStaffing from "../../Images/TechnologyStaffing.jpg";
import ScientificClinicalStaffing from "../../Images/ScientificClinicalStaffing.jpg";
import ProfessionalStaffing from "../../Images/Professional Staffing.jpg";
import IndustrySpecializedStaffing  from "../../Images/IndustrySpecializedStaffing.jpg"
const ContingentStaffingcontentmain = () => {
  return (
    <>
      <section className="softwaresevices_main" >
        <div className="cus_container">
          <div className="healthcares_divnewadd">
            <div className="healthcares_div_left">
              <div className="">
                <h4 className="hlthcaretetx_heading_text"> Technology Staffing</h4>
                <p className="hlthcaretetx_para_text">
                In a world where technological innovation is the driving force behind business success, having the right tech talent is crucial. Our Technology Staffing solutions connect you with skilled professionals who possess cutting-edge expertise in fields such as software development, IT infrastructure, and cybersecurity. We ensure that you have access to top-tier technology experts who can propel your digital initiatives and enhance your technological capabilities.
                </p>
              </div>
            </div>
            <div className="healthcares_div_right">
              <div className="healthcares_div_img">
                <img src={TechnologyStaffing} alt="..." />
              </div>
            </div>
          </div>

          <div className="healthcares_divnewadd">
            <div className="healthcares_div_left">
              <div className="">
                <h4 className="hlthcaretetx_heading_text"> Scientific & Clinical Staffing</h4>
                <p className="hlthcaretetx_para_text">
                The scientific and clinical sectors demand specialized knowledge and precision. Our Scientific & Clinical Staffing services deliver highly qualified professionals who excel in research, development, and clinical operations. From lab technicians and clinical researchers to regulatory experts, we provide talent that meets the stringent requirements of the pharmaceutical, biotechnology, and healthcare industries, ensuring that your projects are executed with the highest level of expertise and compliance.
                </p>
              </div>
            </div>
            <div className="healthcares_div_right">
              <div className="healthcares_div_img">
                <img src={ScientificClinicalStaffing} alt="..." />
              </div>
            </div>
          </div>

          <div className="healthcares_divnewadd">
            <div className="healthcares_div_left">
              <div className="">
                <h4 className="hlthcaretetx_heading_text">Professional Staffing</h4>
                <p className="hlthcaretetx_para_text">
                In the realm of professional services, finding candidates who possess both the technical skills and strategic acumen is essential. Our Professional Staffing solutions cater to a wide range of industries, offering access to experienced professionals across fields such as finance, marketing, and operations. We focus on delivering candidates who not only fit your technical needs but also align with your organizational culture and goals.
                </p>
              </div>
            </div>
            <div className="healthcares_div_right">
              <div className="healthcares_div_img">
                <img src={ProfessionalStaffing} alt="..." />
              </div>
            </div>
          </div>

          <div className="healthcares_divnewadd">
            <div className="healthcares_div_left">
              <div className="">
                <h4 className="hlthcaretetx_heading_text"> Industry Specialized Staffing</h4>
                <p className="hlthcaretetx_para_text">
                Every industry has its unique requirements and challenges. Our Industry Specialized Staffing services address these needs by providing professionals with deep expertise in specific sectors, including manufacturing, energy, and telecommunications. By leveraging our industry-specific knowledge, we connect you with talent that brings both specialized skills and relevant experience, driving operational excellence and strategic growth.
                </p>
              </div>
            </div>
            <div className="healthcares_div_right">
              <div className="healthcares_div_img">
                <img src={IndustrySpecializedStaffing} alt="..." />
              </div>
            </div>
          </div>

         

        </div>
      </section>
      <section className="softwaresevices_main" >
                <div className="cus_container">
                    <div className=''>
                        <h4 className="hlthcaretetx_heading_text">Conclusion</h4>
                        <p className="hlthcaretetx_para_text">
                        At Cygnus, we are dedicated to simplifying your staffing needs, allowing you to concentrate on your core business activities while we manage the complexities of talent acquisition. Reach out to us today and let our expertise in Contingent Staffing help you build a high-performing team that meets your exact needs and accelerates your organizational success.
                        </p>
                    </div>
                </div>
      </section>
    </>
  )
}

export default ContingentStaffingcontentmain
