import React from 'react'
import legacy from "../../Images/legacy.png";
const Legacymodernizationmaincontent = () => {
  return (
    <>
      <section className="softwaresevices_main" >
        <div className="cus_container">
          <div className="healthcares_divnewadd">
            <div className="healthcares_div_left">
              <div className="">
                
                <p className="hlthcaretetx_para_text">
                Cygnus Professionals INC boasts a team of highly skilled IBM RPGLE developers proficient in SQL, CLLE, and RPG. Our tailored services cater to diverse client needs, from bug fixes to new functionality development. Specializing in Maintenance, Application Development, Migration, and Conversion, our experts ensure seamless transitions and effective business document generation. With a focus on international security standards, we guarantee comprehensive integration of IBMi systems with diverse technologies. Our expertise in EDI solutions enhances operational efficiency across industries like retail, manufacturing, logistics, healthcare, and insurance. Leveraging a wide range of RPG languages and iSeries supporting software, we offer services in IBMi Application Development, ERP Integrations, SQL server integration, custom development, and open-source app integration, empowering businesses in various domains.
                </p>
              </div>
            </div>
            <div className="healthcares_div_right">
              <div className="healthcares_div_img">
                <img src={legacy} alt="..." />
              </div>
            </div>
          </div>

         
        </div>
      </section>
    </>
  )
}

export default Legacymodernizationmaincontent
