import React from 'react'
import bannerImg from "../../Images/Cygnus Pictures/Homepage/home-banner.png";
// import arrowmany from "../../Images/arrowmany.png"
import { useNavigate } from 'react-router-dom';
const HomeBanner = () => {
   const navigate=useNavigate()
    const knowbtnbnavigateclick =()=>{
        navigate("/storymain")
    }
    return (
        <>
            <section className='HomeBanner_sec'>
                <div className='cus_container'>
                    <div className='row'>
                        <div className='col-lg-8 col-md-12 col-12 m-auto'>
                            <div className='content_wrap'>
                               
                                <h2 className='sec_headingspcl'>we are the next 
                                    
                                </h2>
                                <p className='sec_headingsub'>Generation Technology</p>
                                {/* <p className='sec_desc'>Unleashing the Potential of tomorrow with the game changing technology and reimagining your potential with cutting-edges and tech solutions.</p> */}
                                <p className='sec_desc'>Unleashing the Potential of Tomorrow for Empowering the world with Game changing Technology</p>
                                <button className='Knowmore_btn' onClick={knowbtnbnavigateclick}>Know More</button>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-12 col-12'>
                            <div className='image_wrap'>
                                <img src={bannerImg} className='img-fluid' alt='...' />
                                {/* <div class="holder one"></div>
                                <div class="holder two"></div>
                                <div class="holder three"></div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HomeBanner