import React from 'react'

const Softwaresolutioncontent = () => {
  return (
    <>
      <section className='SectorWeServer_sec'>
                <div className='cus_container'>
                    <h2 className='sec_heading'>The Sector We Serve  Software Services</h2>

                    {/* <ul className='seclist'>
                        <li><a href='#healthcare_main'>Healthcare</a></li>
                        <li><a href='#PharmaceuticalIndustry'>Pharmaceutical Industry</a></li>
                        <li><a href='#Bankingtelecomindustry'>Banking and Financial Industries</a></li>
                        <li><a href='#Telecom'>Telecom</a></li>
                        <li><a href='#PublicSector'>Public Sector</a></li>
                        <li><a href='#RetailManagement'>Retail Management</a></li>
                        <li><a href='#Manufacturing'>Manufacturing</a></li>
                        <li><a href='#LogisticsTransportation'>Logistics & Transportation</a></li>
                    </ul> */}

                    <p className='hlthcaretetx_para_text'>
                    Would you like to work with someone who doesn’t understand your business completely and mostly works for profit ? We know the answer is a big “No.” We not only understand your business needs but also believe in building a strong relationship with our clients by developing innovative and user-centric applications that offer scalability, usability, and versatility. Cygnus software solutions work with a broad range of customers, from Startups to worldwide Technology corporations just as exceptionally regarded in advancing technologies like Artificial Intelligence to Edge Computing. We are imaginatively sorted out to give an extremely high quality of services to clients. We work with companies on the guard of technology to drive your business forward.
                    </p>

                </div>
            </section>
    </>
  )
}

export default Softwaresolutioncontent
