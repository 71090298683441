import React from 'react'

const Generativeaicontent = () => {
  return (
    <section className='SectorWeServer_sec'>
    <div className='cus_container'>
        <h2 className='sec_heading'>Harnessing the Power of Generative AI </h2>

        <p className='hlthcaretetx_para_text'>
        At the forefront of technological innovation, Generative AI is redefining possibilities with its advanced capabilities. At Cygnus, we leverage Generative AI to revolutionize creativity, efficiency, and insight, delivering cutting-edge solutions that drive your business forward.
        </p>

    </div>
</section>
  )
}

export default Generativeaicontent
