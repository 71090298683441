import React from 'react'

const Pharmacompliencecontent = () => {
  return (
     <>
     <section className='SectorWeServer_sec'>
                <div className='cus_container'>
                    <h2 className='sec_heading'>Your Partner in Pharma Compliance and Operational Excellence</h2>
                    <p className='hlthcaretetx_para_text'>
                    In the fast-paced world of pharmaceuticals and life sciences, navigating compliance and clinical management is crucial. At Cygnus, we offer cutting-edge solutions that not only ensure regulatory compliance but also enhance operational efficiency and elevate patient care.
                    </p>
                    <p className='hlthcaretetx_para_text'>
                    Our comprehensive expertise spans :
                    </p>

                </div>
            </section>
     </>
  )
}

export default Pharmacompliencecontent
