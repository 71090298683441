import React from 'react'
import StrategicMarket from "../../Images/StrategicMarket.jpg";
import UnmatchedTechnical from "../../Images/UnmatchedTechnical.jpg";
import EfficientCompliance from "../../Images/EfficientCompliance.jpg";
import RigorousAdherence from "../../Images/RigorousAdherence.jpg"
const Engineeringcontentmain = () => {
    return (
        <>
            <section className="softwaresevices_main" >
                <div className="cus_container">
                    <div className="healthcares_divnewadd">
                        <div className="healthcares_div_left">
                            <div className="">
                                <h4 className="hlthcaretetx_heading_text">Unmatched Technical Expertise</h4>
                                <p className="hlthcaretetx_para_text">
                                Our engineers possess extensive technical knowledge across diverse industries, enabling us to address complex challenges with customized solutions.
                                </p>
                            </div>
                        </div>
                        <div className="healthcares_div_right">
                            <div className="healthcares_div_imgmainalada">
                                <img src={UnmatchedTechnical } alt="..." />
                            </div>
                        </div>
                    </div>

                    <div className="healthcares_divnewadd">
                        <div className="healthcares_div_left">
                            <div className="">
                                <h4 className="hlthcaretetx_heading_text">Efficient Compliance and Scalable Solutions</h4>
                                <p className="hlthcaretetx_para_text">
                                 Seamlessly navigate regulatory demands with our tailored compliance and scalability solutions, ensuring operational flexibility and efficiency.
                                </p>
                            </div>
                        </div>
                        <div className="healthcares_div_right">
                            <div className="healthcares_div_imgmainalada">
                                <img src={EfficientCompliance} alt="..." />
                            </div>
                        </div>
                    </div>

                    <div className="healthcares_divnewadd">
                        <div className="healthcares_div_left">
                            <div className="">
                                <h4 className="hlthcaretetx_heading_text">Rigorous Adherence to Standards</h4>
                                <p className="hlthcaretetx_para_text">
                                We prioritize strict adherence to industry regulations, mitigating risks and ensuring project compliance with all necessary guidelines.
                                </p>
                            </div>
                        </div>
                        <div className="healthcares_div_right">
                            <div className="healthcares_div_imgmainalada">
                                <img src={RigorousAdherence} alt="..." />
                            </div>
                        </div>
                    </div>

                    <div className="healthcares_divnewadd">
                        <div className="healthcares_div_left">
                            <div className="">
                                <h4 className="hlthcaretetx_heading_text"> Strategic Market Insights for Workforce Planning</h4>
                                <p className="hlthcaretetx_para_text">
                                Benefit from our market insights to refine your workforce planning and agility, gaining a competitive edge by anticipating market trends and optimizing human resources.
                                </p>
                            </div>
                        </div>
                        <div className="healthcares_div_right">
                            <div className="healthcares_div_imgmainalada">
                                <img src={StrategicMarket} alt="..." />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="softwaresevices_main" >
                <div className="cus_container">
                    <div className=''>
                        <h4 className="hlthcaretetx_heading_text">Conclusion</h4>
                        <p className="hlthcaretetx_para_text">
                        At Cygnus, we are committed to delivering excellence by combining technical prowess with tailored solutions to elevate your operations and propel your business towards success.
                        </p>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Engineeringcontentmain
