import React from 'react'
import Automotivemain from './Automotivemain'
import Automotivecontent from './Automotivecontent'
import Automotivecontentmain from './Automotivecontentmain'

const Automotiove = () => {
    return (
        <>
            <Automotivemain />
            <Automotivecontent />
            <Automotivecontentmain />
        </>
    )
}

export default Automotiove
