import React from 'react'
import Pharmacompliencemain from './Pharmacompliencemain'
import Pharmacompliencecontent from './Pharmacompliencecontent'
import Pharmacompliencemaincontent from './Pharmacompliencemaincontent'

const Pharmacomplience = () => {
    return (
        <>
            <Pharmacompliencemain />
            <Pharmacompliencecontent />
            <Pharmacompliencemaincontent />
        </>
    )
}

export default Pharmacomplience
