import React from 'react'
import img1 from "../../Images/Cygnus Pictures/Industrial Solutions/grin-dcULNhRiRrc-unsplash.jpg";
import img3 from "../../Images/Cygnus Pictures/Industrial Solutions/brooke-cagle--uHVRvDr7pg-unsplash.jpg"
import img4 from "../../Images/Cygnus Pictures/Industrial Solutions/centre-for-ageing-better-j3ay5s6Irlk-unsplash.jpg";
const Retamanulogistics = () => {
  return (
    <section className='healthcarepractice'>
    <div className='cus_container'>
        <div className='healthcarepracticeflx'>
            <div className='healthcarepracticedibimg'>
                <div className='healthcarepracticedibimgmain'>
                    <img src={img1} alt="..." />
                </div>
                <p className='slide_text_dtls_pp'>Analytics and reporting with Omnichannel experience</p>
            </div>
            <div className='healthcarepracticedibimg'>
                <div className='healthcarepracticedibimgmain'>
                    <img src={img3} alt="..." />
                </div>
                <p className='slide_text_dtls_pp'>Network Planning and Designing</p>
            </div>
            <div className='healthcarepracticedibimg'>
                <div className='healthcarepracticedibimgmain'>
                    <img src={img4} alt="..." />
                </div>
                <p className='slide_text_dtls_pp'>Inventory Management Solutions to Enhance Operation</p>
            </div>
        </div>
    </div>
    </section>
  )
}

export default Retamanulogistics
