import React from 'react'

const Pharmacontent = () => {
  return (
    <section className='SectorWeServer_sec'>
    <div className='cus_container'>

      <p className='hlthcaretetx_para_text'>
      At Cygnus, we comprehend the industry of healthcare outcomes, upgraded cost alongside advancing consistence needs well and have adjusted our services strategies to help healthcare organizations explore their digital journey of tomorrow. Our Healthcare industry services are planned with standards of digital transformation in line with Internet of Things, Artificial Intelligence and Healthcare Management System According to the reports of Healthcare IT industry latest news there is a rise in the adoption of healthcare IT services in the recent years. From patient monitoring to improved care assistance, it is raised 13% to 26% when compared. Advancing technology, increased use of the smartphones and internet activities are contributed towards the adoption and growth of IT in the healthcare sector. Due to the pandemic COVID-19 opens a several opportunities for the marketplace to gain profit on competitive edge by investing in opportunities. It has strengthened the demand for online medical consulting globally.
      </p>



    </div>
  </section>
  )
}

export default Pharmacontent
