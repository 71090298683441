import React from 'react'
import Excutivesearchmain from './Excutivesearchmain'
import Excutivesearchcontent from './Excutivesearchcontent'
import Excutivesearchcontentmain from './Excutivesearchcontentmain'

const Excutivesearch = () => {
    return (
        <>
            <Excutivesearchmain />
            <Excutivesearchcontent />
            <Excutivesearchcontentmain />
        </>
    )
}

export default Excutivesearch
