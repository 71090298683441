import React from 'react'
import EXCUTIVESEARCH from "../../Images/EXCUTIVESEARCH.jpg"
const Excutivesearchmain = () => {
    return (
        <>
            <section className='industrialsolution_banner'>
                <div className='bgimage_'>
                    <img src={EXCUTIVESEARCH} className='img-fluid' alt='..' />
                </div>
                <div className='banner_wrap'>
                    <h2 className='sec_headingbannertset'> End to end <span> EXECUTIVE SEARCH </span> </h2>
                </div>
            </section>
        </>
    )
}

export default Excutivesearchmain
