import React from 'react'
import img1 from "../../Images/Cygnus Pictures/Industrial Solutions/luke-chesser-JKUTrJ4vK00-unsplash.jpg";
import img3 from "../../Images/Cygnus Pictures/Industrial Solutions/cdc-zGzZvLO5Brs-unsplash.jpg"
import img4 from "../../Images/Cygnus Pictures/Industrial Solutions/azzedine-rouichi-VMB8B-5PeR4-unsplash.jpg";
const Geohealthmaintaince = () => {
    return (
        <section className='healthcarepractice'>
            <div className='cus_container'>
                <div className='healthcarepracticeflx'>
                    <div className='healthcarepracticedibimg'>
                        <div className='healthcarepracticedibimgmain'>
                            <img src={img1} alt="..." />
                        </div>
                        <p className='slide_text_dtls_pp'>Geo-Spatial Data Analytics</p>
                    </div>
                    <div className='healthcarepracticedibimg'>
                        <div className='healthcarepracticedibimgmain'>
                            <img src={img3} alt="..." />
                        </div>
                        <p className='slide_text_dtls_pp'>General Health & Safety</p>
                    </div>
                    <div className='healthcarepracticedibimg'>
                        <div className='healthcarepracticedibimgmain'>
                            <img src={img4} alt="..." />
                        </div>
                        <p className='slide_text_dtls_pp'>Prescient Maintainace</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Geohealthmaintaince
