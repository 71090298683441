import { BrowserRouter, Routes, Route } from 'react-router-dom';
import MainLayout from './Layout/MainLayout';
import './App.css';
import './Css/Home.css';
import './Css/Industrial.css';
import './Css/Contact.css';
import HomePage from "./View/Home/Index";
import IndustrialSolutionInnerPage from './View/IndustrialSolution/IndustrialSolutionInnerPage';
import ContactUs from './View/Contact/ContactUs';
import Myteam from './View/MyTeam/Myteam';
import Storymain from './View/OurStory/Storymain';
import Workmain from "./View/Work/Workmain"
import Innovation from './View/Achievements/Innovation';
import Achievements from './View/Achievements/Achievements';
import Awards from './View/Achievements/Awards';
import GotoTop from './Component/GotoTop';
import Index from './View/SoftwareSevices/Index';
import Engineeringindex from './View/EngineeringService/Engineeringindex';
import Mspprogramindex from './View/MspProgram/Mspprogramindex';
import Generativeindex from './View/GenerativeAi/Generativeindex';
import BusinessConsulting from './View/BusinessConsulting/BusinessConsulting';
import Staffingservice from './View/Staffingservices/Staffingservice';
import ContingentStaffing from './View/ContingentStaffing/ContingentStaffing';
import Excutivesearch from './View/ExcutiveSearch/Excutivesearch';
import Digitalandanalytics from './View/DigitalandAnalytics/Digitalandanalytics';
import Cloudtechnologies from './View/CloudTechnologies/Cloudtechnologies';
import Aiml from './View/AIML/Aiml';
import Fullstack from './View/FullStack/Fullstack';
import Legacymodernization from './View/LegacyModernization/Legacymodernization';
import Sapandoracle from './View/Sapandoracle/Sapandoracle';
import Mobileapps from './View/MobileApps/Mobileapps';
import Practices from './View/Practices/Practices';
import Pharmacomplience from './View/Pharmacomplience/Pharmacomplience';
import Cybersecurity from './View/CyberSecurity/Cybersecurity';
import Bfsi from './View/Bfsi/Bfsi';
import Automotiove from './View/Automotive/Automotiove';
import Retail from './View/Retail/Retail';
import Pharma from './View/Pharma/Pharma';
function App() {
  return (
    <>
      <BrowserRouter>
      <GotoTop/>
        <Routes>
          <Route path="/" element={<MainLayout />}>
              <Route path='/' element={<HomePage />} />
              <Route path='/industrial-solution-inner' element={<IndustrialSolutionInnerPage />} />
              <Route path='/contact-us' element={<ContactUs />} />
              <Route path='/myteam' element={<Myteam/>}/>
              <Route path='/storymain' element={<Storymain/>}/>
              <Route path='/workmain' element={<Workmain/>}/>
              <Route path='/innovation' element={<Innovation/>}/>
              <Route path='/achievement' element={<Achievements/>}/>
              <Route path='/awards' element={<Awards/>}/>
              <Route path='/softwareservice' element={<Index/>}/>
              <Route path='/engineeringindex' element={<Engineeringindex/>}/>
              <Route path='/mspprogramindex' element={<Mspprogramindex/>}/>
              <Route path='/generativeai' element={<Generativeindex/>}/>
              <Route path='/businessconsulting' element={<BusinessConsulting/>}/>
              <Route path='/staffingservice' element={<Staffingservice/>}/>
              <Route path='/contigentstuffing' element={<ContingentStaffing/>}/>
              <Route path='/excutivesearch' element={<Excutivesearch/>}/>
              <Route path="/digitalanalytics" element={<Digitalandanalytics/>}/>
              <Route path='/cloudtechnologies' element={<Cloudtechnologies/>}/>
              <Route path='/aiml' element={<Aiml/>}/>
              <Route path='/fullstack' element={<Fullstack/>}/>
              <Route path='/legacymodernization' element={<Legacymodernization/>}/>
              <Route path='/sapandoracle' element={<Sapandoracle/>}/>
              <Route path='/mobileapps' element={<Mobileapps/>}/>
              <Route path='/practices' element={<Practices/>}/>
              <Route path='/pharmacomplience' element={<Pharmacomplience/>}/>
              <Route path="/cybersecurity" element={<Cybersecurity/>}/>
              <Route path='/bfsi' element={<Bfsi/>}/>
              <Route path='/automotive' element={<Automotiove/>}/>
              <Route path='/retail' element={<Retail/>}/>
              <Route path='/pharma' element={<Pharma/>}/>
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
