import React from 'react'
import img1 from "../../Images/Cygnus Pictures/Industrial Solutions/homa-appliances-4rym6Ltlq-E-unsplash.jpg";
import img3 from "../../Images/Cygnus Pictures/Industrial Solutions/jason-goodman-Oalh2MojUuk-unsplash.jpg"
import img4 from "../../Images/Cygnus Pictures/Industrial Solutions/ruchindra-gunasekara-GK8x_XCcDZg-unsplash.jpg";
const Supplmanagementwarehouse = () => {
  return (
    <section className='healthcarepractice'>
    <div className='cus_container'>
        <div className='healthcarepracticeflx'>
            <div className='healthcarepracticedibimg'>
                <div className='healthcarepracticedibimgmain'>
                    <img src={img1} alt="..." />
                </div>
                <p className='slide_text_dtls_pp'>Supply Chain Planning during the crisis</p>
            </div>
            <div className='healthcarepracticedibimg'>
                <div className='healthcarepracticedibimgmain'>
                    <img src={img3} alt="..." />
                </div>
                <p className='slide_text_dtls_pp'>Dealer Management & Delivery</p>
            </div>
            <div className='healthcarepracticedibimg'>
                <div className='healthcarepracticedibimgmain'>
                    <img src={img4} alt="..." />
                </div>
                <p className='slide_text_dtls_pp'>Warehouse Management</p>
            </div>
        </div>
    </div>
    </section>
  )
}

export default Supplmanagementwarehouse
