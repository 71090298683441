import React from 'react'
import artificialintelliengnce from "../../Images/artificialintelliengnce.jpg"
const Aimlmain = () => {
  return (
    <>
      <section className='industrialsolution_banner'>
        <div className='bgimage_'>
          <img src={artificialintelliengnce} className='img-fluid' alt='..' />
        </div>
        <div className='banner_wrap'>
          <h2 className='sec_headingbannertset'> End to end <span> AI/ML  </span> </h2>
        </div>
      </section>
    </>
  )
}

export default Aimlmain
