import React from 'react'

const Digitalandanalyticscontent = () => {
  return (
    <>
      <section className='SectorWeServer_sec'>
        <div className='cus_container'>
         
          <p className='hlthcaretetx_para_text'>
          In today’s competitive landscape, leveraging digital and analytics capabilities is crucial. At Cygnus, we specialize in cutting-edge Digital & Analytics services that empower organizations to harness the power of their data for informed decision-making. 
          </p>

          <h2 className='ggpappadinbtm'>

          Our offerings include :
          </h2>
        

        </div>
      </section>
    </>
  )
}

export default Digitalandanalyticscontent
