import React from 'react'
import support from "../../Images/support.jpg";
import legacybanner from "../../Images/legacybanner.jpg";
import CustomizationOptimization from "../../Images/CustomizationOptimization.jpg";
import intigration from "../../Images/intigration.png"
const Sapandoraclemaincontent = () => {
  return (
    <>
      <section className="softwaresevices_main" >
        <div className="cus_container">
          <div className="healthcares_divnewadd">
            <div className="healthcares_div_left">
              <div className="">
                <h4 className="hlthcaretetx_heading_text">SAP/Oracle Implementation: </h4>
                <p className="hlthcaretetx_para_text">
                We manage the end-to-end implementation of SAP and Oracle systems, including planning, configuration, and deployment, to ensure a smooth and successful integration into your business operations.
                </p>
              </div>
            </div>
            <div className="healthcares_div_right">
              <div className="healthcares_div_imgmainalada">
                <img src={legacybanner} alt="..." />
              </div>
            </div>
          </div>

          <div className="healthcares_divnewadd">
            <div className="healthcares_div_left">
              <div className="">
                <h4 className="hlthcaretetx_heading_text">Customization and Optimization</h4>
                <p className="hlthcaretetx_para_text">
                We manage the end-to-end implementation of SAP and Oracle systems, including planning, configuration, and deployment, to ensure a smooth and successful integration into your business operations.
                </p>
              </div>
            </div>
            <div className="healthcares_div_right">
              <div className="healthcares_div_imgmainalada">
                <img src={CustomizationOptimization} alt="..." />
              </div>
            </div>
          </div>

          <div className="healthcares_divnewadd">
            <div className="healthcares_div_left">
              <div className="">
                <h4 className="hlthcaretetx_heading_text">Integration Services</h4>
                <p className="hlthcaretetx_para_text">
                We integrate SAP and Oracle systems with your existing applications and data sources, ensuring seamless data flow and process efficiency across your enterprise.
                </p>
              </div>
            </div>
            <div className="healthcares_div_right">
              <div className="healthcares_div_imgmainalada">
                <img src={intigration} alt="..." />
              </div>
            </div>
          </div>

          <div className="healthcares_divnewadd">
            <div className="healthcares_div_left">
              <div className="">
                <h4 className="hlthcaretetx_heading_text">Support and Maintenance</h4>
                <p className="hlthcaretetx_para_text">
                We offer comprehensive support and maintenance services for your SAP and Oracle systems, including troubleshooting, upgrades, and performance tuning, to keep your systems running smoothly and effectively.

                </p>
              </div>
            </div>
            <div className="healthcares_div_right">
              <div className="healthcares_div_imgmainalada">
                <img src={support} alt="..." />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Sapandoraclemaincontent
