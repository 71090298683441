import React from 'react'
import img1 from "../../Images/Cygnus Pictures/Industrial Solutions/jason-goodman-Oalh2MojUuk-unsplash.jpg";

const DealerManagement = () => {
  return (
    <section className="healthcare_main" id='LogisticsTransportation'>
    <div className="cus_container">
        <div className="healthcares_div">
            <div className="healthcares_div_left">
                <div className="">
                    <h4 className="hlthcaretetx_heading_text">Dealer Management & Delivery</h4>
                    <p className="hlthcaretetx_para_text">
                    Productive seller the executive’s framework that enables you to deal with your business simpler. We make innovation frameworks that can be effortlessly utilized from your work area, cell phone and different gadgets empowering you to deal with your business in a hurry. Amazing delivery and conveyance measure the board on the run, we give you transportation and coordination web and application digitalization that permit you access your orders, armada, conveyance framework and others.
                    </p>
                </div>
            </div>
            <div className="healthcares_div_right">
                <div className="healthcares_div_img">
                    <img src={img1} alt="..." />
                </div>
            </div>
        </div>
        {/* <div className="healthcareswipper">

            <Swiper
                slidesPerView={3}
                spaceBetween={30}
                navigation={true}
                breakpoints={{
                    0: {
                        slidesPerView: 1,
                    },
                    400: {
                        slidesPerView: 2,
                    },
                    639: {
                        slidesPerView: 3,
                    },
                }}
                modules={[Navigation]}
                className='mySwiper'
            >
                <SwiperSlide className="">
                    <div className="healthcare_divsld_img">
                        <img src={img2} alt="..." />
                    </div>
                    <div className="">
                        <p className="slide_text_dtls_pp">
                            Dealer Management & Delivery
                        </p>
                    </div>
                </SwiperSlide>
                <SwiperSlide className="">
                    <div className="healthcare_divsld_img">
                        <img src={img3} alt="..." />
                    </div>
                    <div className="">
                        <p className="slide_text_dtls_pp">
                            Warehouse Management
                        </p>
                    </div>
                </SwiperSlide>
                <SwiperSlide className="">
                    <div className="healthcare_divsld_img">
                        <img src={img4} alt="..." />
                    </div>
                    <div className="">
                        <p className="slide_text_dtls_pp">
                            Transportation Analytics & Data Integration
                        </p>
                    </div>
                </SwiperSlide>
                <SwiperSlide className="">
                    <div className="healthcare_divsld_img">
                        <img src={img3} alt="..." />
                    </div>
                    <div className="">
                        <p className="slide_text_dtls_pp">
                            Warehouse Management
                        </p>
                    </div>
                </SwiperSlide>
                <SwiperSlide className="">
                    <div className="healthcare_divsld_img">
                        <img src={img4} alt="..." />
                    </div>
                    <div className="">
                        <p className="slide_text_dtls_pp">
                            Transportation Analytics & Data Integration
                        </p>
                    </div>
                </SwiperSlide>
                <SwiperSlide className="">
                    <div className="healthcare_divsld_img">
                        <img src={img3} alt="..." />
                    </div>
                    <div className="">
                        <p className="slide_text_dtls_pp">
                            Warehouse Management
                        </p>
                    </div>
                </SwiperSlide>
                <SwiperSlide className="">
                    <div className="healthcare_divsld_img">
                        <img src={img4} alt="..." />
                    </div>
                    <div className="">
                        <p className="slide_text_dtls_pp">
                            Transportation Analytics & Data Integration
                        </p>
                    </div>
                </SwiperSlide>
            </Swiper>
        </div> */}
    </div>
</section>
  )
}

export default DealerManagement
