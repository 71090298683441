import React from 'react'
import DIGITALANALYTICS1 from "../../Images/DIGITALANALYTICS1.jpg"
const Digitalandanalyticsmain = () => {
  return (
    <>
      <section className='industrialsolution_banner'>
        <div className='bgimage_'>
          <img src={DIGITALANALYTICS1} className='img-fluid' alt='..' />
        </div>
        <div className='banner_wrap'>
          <h2 className='sec_headingbannertset'> End to end <span> DIGITAL & ANALYTICS  </span> </h2>
        </div>
      </section>
    </>
  )
}

export default Digitalandanalyticsmain
