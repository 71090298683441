import React from 'react'

const ContactUs = () => {
    return (
        <>
            <section className='contactus_sec'>
                <div className='bgcolor'></div>
                <div className='cus_container'>
                    <div className='left_content'>
                        <h2 className='sec_heading'>Contact Us</h2>
                        <p className='sec_desc'>Feel free to contact us any time, we will get back to you as soon as we can!</p>

                        <form>
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    id=""
                                    aria-describedby=""
                                    placeholder="Name"
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="email"
                                    className="form-control"
                                    id=""
                                    aria-describedby=""
                                    placeholder="Email"
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    id=""
                                    aria-describedby=""
                                    placeholder="Company Name"
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    id=""
                                    aria-describedby=""
                                    placeholder="Message"
                                />
                            </div>

                            <div className='row mb-5 mt-4'>
                                <div className='col-lg-6 col-md-6 col-12'>
                                    <div className="form-check">
                                        <input type="checkbox" className="form-check-input" id="indiacheck" />
                                        <label className="form-check-label" htmlFor="indiacheck">
                                            India
                                        </label>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-12'>
                                    <div className="form-check">
                                        <input type="checkbox" className="form-check-input" id="unitedcheck" />
                                        <label className="form-check-label" htmlFor="unitedcheck">
                                            United States
                                        </label>
                                    </div>
                                </div>
                            </div>


                            <button type="submit" className="btn Submit_btn">
                                Submit
                            </button>

                        </form>

                    </div>

                    <div className='right_content'>
                        <h4 className='head'>Info</h4>
                        <div className='info_box'>
                            <i className="fa-solid fa-envelope"></i>
                            <span>info@cygnuspro.com</span>
                        </div>
                        <div className='info_box'>
                            <i className="fa-solid fa-phone"></i>
                            <span>+1 (732) 485-0000</span>
                        </div>

                        <div className='info_box'>
                            <i className="fa-solid fa-building"></i>
                            <div>
                                <div className='d-flex'>
                                    <p className='text1'>United States </p>
                                    <p className='text2'>3490 US Highway 1, #4, Princeton, New Jersey 08540</p>
                                </div>
                                <div className='d-flex'>
                                    <p className='text1'>India</p>
                                    <p className='text2'>Suite C, First Floor, Linus Opp. to U.S. Consulate Begumpet, Hyderabad, Telangana 500016</p>
                                </div>
                            </div>
                        </div>

                        {/* <div className='info_box'>
                            <i className="fa-solid fa-phone"></i>
                            <span>10:30 to 7:00</span>
                        </div> */}
                    </div>
                </div>
            </section>
        </>
    )
}

export default ContactUs