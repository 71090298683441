import React from 'react'
import Machinelearning from "../../Images/Machinelearning.png";
import discover from "../../Images/discover.jpg";
import digitaltransformation from "../../Images/digitaltransformation.jpg";
import Cybersecurity from "../../Images/Cybersecurity.jpg";
import ITInfrastructure from "../../Images/ITInfrastructure.jpg";
import customer from "../../Images/customer.jpg";
import dataanalytics from "../../Images/dataanalytics.png";
import AutomationEfficiency from "../../Images/AutomationEfficiency.jpg";
import changemanagement from "../../Images/changemanagement.jpg"
const Consultingcontentmain = () => {
    return (
        <>

            <section className="softwaresevices_main" >
                <div className="cus_container">
                    <div className="healthcares_divnewadd">
                        <div className="healthcares_div_left">
                            <div className="">
                                <h4 className="hlthcaretetx_heading_text">Digital Transformation Strategy</h4>
                                <p className="hlthcaretetx_para_text">
                                    We partner with our clients to develop and execute digital transformation strategies that modernize operations, enhance customer engagement, and drive competitive advantage. By leveraging cutting-edge technologies, we help organizations transition smoothly into the digital age, ensuring long-term success.
                                </p>
                            </div>
                        </div>
                        <div className="healthcares_div_right">
                            <div className="healthcares_div_img">
                                <img src={digitaltransformation} alt="..." />
                            </div>
                        </div>
                    </div>

                    <div className="healthcares_divnewadd">
                        <div className="healthcares_div_left">
                            <div className="">
                                <h4 className="hlthcaretetx_heading_text">Cybersecurity and Data Protection</h4>
                                <p className="hlthcaretetx_para_text">
                                    With the increasing threat of cyberattacks, robust cybersecurity measures are crucial. We conduct thorough cybersecurity assessments, implement state-of-the-art security protocols, and ensure data protection to safeguard sensitive information, helping our clients stay ahead of potential threats
                                </p>
                            </div>
                        </div>
                        <div className="healthcares_div_right">
                            <div className="healthcares_div_img">
                                <img src={Cybersecurity} alt="..." />
                            </div>
                        </div>
                    </div>

                    <div className="healthcares_divnewadd">
                        <div className="healthcares_div_left">
                            <div className="">
                                <h4 className="hlthcaretetx_heading_text">IT Infrastructure Optimization</h4>
                                <p className="hlthcaretetx_para_text">
                                    A well-optimized IT infrastructure is foundational for scalability and performance. Our team provides services in cloud adoption, legacy system modernization, and data center optimization, ensuring that our clients' IT systems are resilient, efficient, and cost-effective
                                </p>
                            </div>
                        </div>
                        <div className="healthcares_div_right">
                            <div className="healthcares_div_img">
                                <img src={ITInfrastructure} alt="..." />
                            </div>
                        </div>
                    </div>

                    <div className="healthcares_divnewadd">
                        <div className="healthcares_div_left">
                            <div className="">
                                <h4 className="hlthcaretetx_heading_text">Customer Relationship Management (CRM)</h4>
                                <p className="hlthcaretetx_para_text">
                                    Understanding and engaging with customers is key to success in any industry. We assist organizations in implementing CRM solutions tailored to their specific needs, enhancing customer relationships and driving growth through personalized services.
                                </p>
                            </div>
                        </div>
                        <div className="healthcares_div_right">
                            <div className="healthcares_div_img">
                                <img src={customer} alt="..." />
                            </div>
                        </div>
                    </div>

                    <div className="healthcares_divnewadd">
                        <div className="healthcares_div_left">
                            <div className="">
                                <h4 className="hlthcaretetx_heading_text">Data Analytics and Business Intelligence</h4>
                                <p className="hlthcaretetx_para_text">
                                    Data is one of the most valuable assets in today’s economy. Our consultants help organizations harness the power of data analytics and business intelligence to make informed decisions, optimize processes, and identify new opportunities for growth.
                                </p>
                            </div>
                        </div>
                        <div className="healthcares_div_right">
                            <div className="healthcares_div_img">
                                <img src={dataanalytics} alt="..." />
                            </div>
                        </div>
                    </div>

                    <div className="healthcares_divnewadd">
                        <div className="healthcares_div_left">
                            <div className="">
                                <h4 className="hlthcaretetx_heading_text">Comprehensive IT Governance</h4>
                                <p className="hlthcaretetx_para_text">
                                    Effective IT governance aligns IT strategies with business objectives, enhancing transparency and accountability. We assist our clients in developing governance structures, policies, and procedures that strengthen risk management and ensure alignment with organizational goals
                                </p>
                            </div>
                        </div>
                        <div className="healthcares_div_right">
                            <div className="healthcares_div_img">
                                <img src={AutomationEfficiency} alt="..." />
                            </div>
                        </div>
                    </div>

                    <div className="healthcares_divnewadd">
                        <div className="healthcares_div_left">
                            <div className="">
                                <h4 className="hlthcaretetx_heading_text">	Change Management and Training</h4>
                                <p className="hlthcaretetx_para_text">
                                    Implementing new IT solutions often requires significant organizational change. We provide change management and training services that help organizations manage transitions smoothly, minimize resistance Understanding and engaging with customers is key to success in any industry. We assist organizations in implementing CRM solutions tailored to their specific needs, enhancing customer relationships and driving growth through personalized services., and maximize the benefits of new technologies and processes.
                                </p>
                            </div>
                        </div>
                        <div className="healthcares_div_right">
                            <div className="healthcares_div_img">
                                <img src={changemanagement} alt="..." />
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <section className="softwaresevices_main" >
                <div className="cus_container">
                    <div className='choosedivflx'>
                        <div className='choosedivleft'>
                            <h4 className="hlthcaretetx_heading_text">Why Choose Cygnus?</h4>
                            <p className="hlthcaretetx_para_text">
                                At Cygnus, we combine industry expertise with a deep understanding of the latest technological advancements to deliver solutions that drive innovation and efficiency. Our client-centric approach ensures that every solution is tailored to meet the specific needs and challenges of our clients, helping them achieve their strategic goals and thrive in a competitive marketplace.
                            </p>
                        </div>
                        <div className='choosedivright'>
                            <div className='choosedivrightimgmain'>
                                <img src={discover} alt='...' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="softwaresevices_main" >
                <div className="cus_container">
                    <div className=''>
                        <h4 className="hlthcaretetx_heading_text">Conclusion</h4>
                        <p className="hlthcaretetx_para_text">
                        In an era where technology is at the core of business success, partnering with a trusted IT consulting firm like Cygnus can make all the difference. Our comprehensive suite of services is designed to empower organizations, enabling them to leverage technology to its fullest potential and secure a prosperous future in the digital world.
                        </p>
                    </div>
                </div>
            </section>



            {/* <section className="softwaresevices_main" >
                <div className="cus_container">



                    <div className="healthcares_div">
                        <div className="healthcares_div_left">
                            <div className="">
                                <h4 className="hlthcaretetx_heading_text">Permanent Staffing</h4>
                                <p className="hlthcaretetx_para_text">
                                    Cygnus comes with a deep understanding of the staffing market and has the capabilities to partner with small to large businesses to hire and deliver the best talent in the industry. With an active database of the relevant candidates, our team of experts can source resources with the right combination of talent, skills, aptitude, and attitude which is the qualification an organization looks for in a candidate. We have worked with brands across various domains to deliver astounding outcomes with our Permanent Staffing Services.
                                </p>
                            </div>
                        </div>
                        <div className="healthcares_div_right">
                            <div className="healthcares_div_img">
                                <img src={Machinelearning} alt="..." />
                            </div>
                        </div>
                    </div>



                </div>
            </section>


            <section className='softwaresevices_main'>
                <div className='cus_container'>
                  

                    <div className="healthcares_div">
                        <div className="healthcares_div_left">
                            <div className="">
                                <h4 className="hlthcaretetx_heading_text">Pro Services</h4>
                                <p className="hlthcaretetx_para_text">
                                For the organizations looking to save money on hiring cost, improve recruiting effectiveness, and outsource their recruitment process to enlistment cycle to third party talent acquisition companies. Cygnus offers Recruitment Process Outsourcing Services (RPO) for all size of organizations across different verticals our altered and flexible enlistment measure encourages your organization to remain competitive and relevant in today’s challenging business environment. A portion of the benefits of Recruitment Process Outsourcing incorporates adaptability, affordability, and reduced opportunity to advertise.
                                </p>
                            </div>
                        </div>
                        <div className="healthcares_div_right">
                            <div className="healthcares_div_img">
                                <img src={Machinelearning} alt="..." />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='softwaresevices_main'>
                <div className="cus_container">
                    <div className='disprocremnchamdivtopbottommixflx'>
                        <div className='disprocremnchamdivtopbottommix'>
                            <div className='disprocremnchamdivtopbottobg'>
                                <div className='disprocremnchamdivtop'>
                                    <img src={discover} alt='...' />
                                </div>
                                <div className='disprocremnchamdivbottom'>
                                    <h4 className='assitstxtpphhhh'>
                                        Flexible array of services
                                    </h4>
                                    <p className='assitstxtpphhhhparaa'>
                                        With flexible services and a well-trained & dedicated team to materialize the permanent staffing solutions.
                                    </p>
                                </div>
                            </div>
                        </div>


                        <div className='disprocremnchamdivtopbottommix'>
                            <div className='disprocremnchamdivtopbottobg'>
                                <div className='disprocremnchamdivtop'>
                                    <img src={discover} alt='...' />
                                </div>
                                <div className='disprocremnchamdivbottom'>
                                    <h4 className='assitstxtpphhhh'>
                                        Well-maintained repository of candidates
                                    </h4>
                                    <p className='assitstxtpphhhhparaa'>
                                        We maintain a perfect liaison disciplined process in the recruitment of temporary candidates for business.
                                    </p>
                                </div>
                            </div>
                        </div>


                        <div className='disprocremnchamdivtopbottommix'>
                            <div className='disprocremnchamdivtopbottobg'>
                                <div className='disprocremnchamdivtop'>
                                    <img src={discover} alt='...' />
                                </div>
                                <div className='disprocremnchamdivbottom'>
                                    <h4 className='assitstxtpphhhh'>
                                        Utility adherence on a comprehensive basis
                                    </h4>
                                    <p className='assitstxtpphhhhparaa'>
                                        We assist clients on the seasonal staffing requirements by providing the temporary staffing services.
                                    </p>
                                </div>
                            </div>
                        </div>


                        <div className='disprocremnchamdivtopbottommix'>
                            <div className='disprocremnchamdivtopbottobg'>
                                <div className='disprocremnchamdivtop'>
                                    <img src={discover} alt='...' />
                                </div>
                                <div className='disprocremnchamdivbottom'>
                                    <h4 className='assitstxtpphhhh'>
                                        Multi industry delivery capabilities
                                    </h4>
                                    <p className='assitstxtpphhhhparaa'>
                                        With the Goal of bringing Associates and clients at one place we serve the multiple industries.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}






        </>
    )
}

export default Consultingcontentmain
