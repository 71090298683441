import React from 'react'
import automotivebanner from "../../Images/automotivebanner.jpg"
const Automotivemain = () => {
  return (
    <section className='industrialsolution_banner'>
      <div className='bgimage_'>
        <img src={automotivebanner} className='img-fluid' alt='..' />
        <div className='banner_wrap'>
          <h2 className='sec_headingbannertset'> Automotive  </h2>
        </div>

      </div>

    </section>
  )
}

export default Automotivemain
