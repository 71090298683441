import React from 'react'
import img1 from "../../Images/Cygnus Pictures/Industrial Solutions/thisisengineering-jqD8bSQY6Ic-unsplash.jpg";
import img3 from "../../Images/Cygnus Pictures/Industrial Solutions/nathan-dumlao-66RxrYlPShI-unsplash.jpg"
import img4 from "../../Images/Cygnus Pictures/Industrial Solutions/kelly-sikkema-M98NRBuzbpc-unsplash.jpg";
const Delivercustomermultiple = () => {
    return (
        <section className='healthcarepractice'>
        <div className='cus_container'>
            <div className='healthcarepracticeflx'>
                <div className='healthcarepracticedibimg'>
                    <div className='healthcarepracticedibimgmain'>
                        <img src={img1} alt="..." />
                    </div>
                    <p className='slide_text_dtls_pp'>Delivers World-class Management Software</p>
                </div>
                <div className='healthcarepracticedibimg'>
                    <div className='healthcarepracticedibimgmain'>
                        <img src={img3} alt="..." />
                    </div>
                    <p className='slide_text_dtls_pp'>Customer Service Solution</p>
                </div>
                <div className='healthcarepracticedibimg'>
                    <div className='healthcarepracticedibimgmain'>
                        <img src={img4} alt="..." />
                    </div>
                    <p className='slide_text_dtls_pp'>Support Multiple Tax rats & Payment Models</p>
                </div>
            </div>
        </div>
        </section>
    )
}

export default Delivercustomermultiple
