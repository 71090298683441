import React from 'react'
import mision from "../../Images/Cygnus Pictures/Our story page/mision.jpg";
import vision from "../../Images/Cygnus Pictures/Our story page/vision.jpg";
import mmisiiontemaimg from "../../Images/Cygnus Pictures/Our story page/clark-tibbs-oqStl2L5oxI-unsplash.jpg"
const Missionvission = () => {
    return (
        <section className='missionvissionmain'>
            <div className='cus_container'>
                <div className=''>
                    <h4 className='missionvissontxtpp'> Mission and Vision</h4>
                </div>

                <div className='miisoonvissiondiv'>
                    <div className='miisondiv'>
                        <div className='miisondivbrdr'>
                            <div className='imhmisionvison'>
                                <img src={mision} alt='...' />
                            </div>
                            <div className=''>
                                <h4 className='misisontxtpp'>Our Mission</h4>
                            </div>

                            <div className='misisontxtppmainodiv'>
                                <p className='misisontxtppmaino'>
                                Mission empower us to serve clients better Transform into an esteemed global company at delivering state-of-the-art IT Consulting and Solutions to foster Business IT Integration Reinforce a loyal Partnership in Innovation and Delivery Excellence Establish a thought Leadership in synthesizing Strong Domain Knowledge with Technical Expertise to ensure Business Goals and Business Outcome.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='visiondiv'>
                        <div className='visondivbrdr'>
                            <div className='imhmisionvison'>
                                <img src={vision} alt='...' />
                            </div>
                            <div className=''>
                                <h4 className='misisontxtpp'>Our Vision</h4>
                              </div>
                              <div className='misisontxtppmainodiv'>
                                <p className='misisontxtppmaino'>
                                Vision is to become global leader in Business-IT transformation solutions and consulting by delivering excellence and innovation to its customers. Cygnus are committed to help their customers in achieving their goals. We exhibit the sense of ownership in each step while serving customers, applying innovation to sustain competitive advantage and, develop a healthy partnership ecosystem.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>


                <div className='imhagmiidllestpittstmaion'>
                    <img src={mmisiiontemaimg} alt='...' />
                    </div>
            </div>
        </section>
    )
}

export default Missionvission
