import React from 'react'

const Automotivecontent = () => {
  return (
    <section className='SectorWeServer_sec'>
                <div className='cus_container'>
                    
                    <p className='hlthcaretetx_para_text'>
                    At Cygnus Professionals, we are at the forefront of consulting services tailored specifically for the automotive sector. With an in-depth understanding of industry nuances and a relentless commitment to innovation, we empower our clients to adeptly navigate the complexities of automotive software engineering.
                    </p>
                 

                </div>
            </section>
  )
}

export default Automotivecontent
