import React from 'react'
import img1 from "../../Images/Cygnus Pictures/Industrial Solutions/Screenshot 2024-07-11 172510.png";
import img3 from "../../Images/Cygnus Pictures/Industrial Solutions/nik-73_kRzs9sqo-unsplash.jpg"
import img4 from "../../Images/Cygnus Pictures/Industrial Solutions/Screenshot 2024-07-11 202251.png";
const Distelesector = () => {
    return (
        <section className='healthcarepractice'>
            <div className='cus_container'>
                <div className='healthcarepracticeflx'>
                    <div className='healthcarepracticedibimg'>
                        <div className='healthcarepracticedibimgmain'>
                            <img src={img1} alt="..." />
                        </div>
                        <p className='slide_text_dtls_pp'>Pharma Distribution Software Development</p>
                    </div>
                    <div className='healthcarepracticedibimg'>
                        <div className='healthcarepracticedibimgmain'>
                            <img src={img3} alt="..." />
                        </div>
                        <p className='slide_text_dtls_pp'>Payment Gateway & Easy Integration</p>
                    </div>
                    <div className='healthcarepracticedibimg'>
                        <div className='healthcarepracticedibimgmain'>
                            <img src={img4} alt="..." />
                        </div>
                        <p className='slide_text_dtls_pp'>Mobile Banking & Loan Management Software</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Distelesector
