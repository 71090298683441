import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';

import { Navigation } from 'swiper/modules';

import img1 from "../../Images/Cygnus Pictures/Industrial Solutions/brooke-cagle--uHVRvDr7pg-unsplash.jpg";
import img2 from "../../Images/Cygnus Pictures/Industrial Solutions/homa-appliances-4rym6Ltlq-E-unsplash.jpg";
import img3 from "../../Images/Cygnus Pictures/Industrial Solutions/annie-spratt-QckxruozjRg-unsplash.jpg";
import img4 from "../../Images/Cygnus Pictures/Industrial Solutions/jakub-zerdzicki-ykgLX_CwtDw-unsplash.jpg";


const Manufacturing = () => {
    return (
        <>
            <section className="healthcare_main" id='Manufacturing'>
                <div className="cus_container">
                    <div className="healthcares_div">
                        <div className="healthcares_div_left">
                            <div className="">
                                <h4 className="hlthcaretetx_heading_text">Network Planning and Designing</h4>
                                <p className="hlthcaretetx_para_text">
                                Wise Designs with Advanced and Emerging innovations is our guarantee. We Offer a wide scope of services which cater Copper, Fiber and Hybrid fiber coaxial innovations in all territories. Cygnus has a blend of specialists with experience in Telecom NOCs, Field Implementations, GIS applications and IT Applications

                                </p>
                            </div>
                        </div>
                        <div className="healthcares_div_right">
                            <div className="healthcares_div_img">
                                <img src={img1} alt="..." />
                            </div>
                        </div>
                    </div>
                    {/* <div className="healthcareswipper">

                        <Swiper
                            slidesPerView={3}
                            spaceBetween={30}
                            navigation={true}
                            breakpoints={{
                                0: {
                                    slidesPerView: 1,
                                },
                                400: {
                                    slidesPerView: 2,
                                },
                                639: {
                                    slidesPerView: 3,
                                },
                            }}
                            modules={[Navigation]}
                            className='mySwiper'
                        >
                            <SwiperSlide className="">
                                <div className="healthcare_divsld_img">
                                    <img src={img2} alt="..." />
                                </div>
                                <div className="">
                                    <p className="slide_text_dtls_pp">
                                        Supply Chain Planning during the crisis
                                    </p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="">
                                <div className="healthcare_divsld_img">
                                    <img src={img3} alt="..." />
                                </div>
                                <div className="">
                                    <p className="slide_text_dtls_pp">
                                        Business Intelligence and Visualization
                                    </p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="">
                                <div className="healthcare_divsld_img">
                                    <img src={img4} alt="..." />
                                </div>
                                <div className="">
                                    <p className="slide_text_dtls_pp">
                                        Advanced Analytics
                                    </p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="">
                                <div className="healthcare_divsld_img">
                                    <img src={img3} alt="..." />
                                </div>
                                <div className="">
                                    <p className="slide_text_dtls_pp">
                                        Business Intelligence and Visualization
                                    </p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="">
                                <div className="healthcare_divsld_img">
                                    <img src={img4} alt="..." />
                                </div>
                                <div className="">
                                    <p className="slide_text_dtls_pp">
                                        Advanced Analytics
                                    </p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="">
                                <div className="healthcare_divsld_img">
                                    <img src={img3} alt="..." />
                                </div>
                                <div className="">
                                    <p className="slide_text_dtls_pp">
                                        Business Intelligence and Visualization
                                    </p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="">
                                <div className="healthcare_divsld_img">
                                    <img src={img4} alt="..." />
                                </div>
                                <div className="">
                                    <p className="slide_text_dtls_pp">
                                        Advanced Analytics
                                    </p>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div> */}
                </div>
            </section>
        </>
    )
}

export default Manufacturing