import React from 'react'
import Bfsimain from './Bfsimain'
import Bfsicontent from './Bfsicontent'
import Bfsicontentmain from './Bfsicontentmain'

const Bfsi = () => {
  return (
      <>
            <Bfsimain />
            <Bfsicontent />
            <Bfsicontentmain />
      </>
  )
}

export default Bfsi
