import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';

import { Navigation } from 'swiper/modules';

import img1 from "../../Images/Cygnus Pictures/Industrial Solutions/grin-dcULNhRiRrc-unsplash.jpg";
import img2 from "../../Images/Cygnus Pictures/Industrial Solutions/thisisengineering-jqD8bSQY6Ic-unsplash.jpg";
import img3 from "../../Images/Cygnus Pictures/Industrial Solutions/nathan-dumlao-66RxrYlPShI-unsplash.jpg";
import img4 from "../../Images/Cygnus Pictures/Industrial Solutions/kelly-sikkema-M98NRBuzbpc-unsplash.jpg";

const RetailManagement = () => {
    return (
        <>
            <section className="healthcare_main" id='RetailManagement'>
                <div className="cus_container">
                    <div className="healthcares_div">
                        <div className="healthcares_div_left">
                            <div className="">
                                <h4 className="hlthcaretetx_heading_text">Analytics and reporting with Omnichannel experience</h4>
                                <p className="hlthcaretetx_para_text">
                                We empower monetary specialist co-ops to settle on educated choices a lot quicker with perception dashboards and gauging highlights to make computerized interfaces for banking applications and web stages and acquaint gamification into client’s associations with draw in them to utilize your item.We empower monetary specialist co-ops to settle on educated choices a lot quicker with perception dashboards and gauging highlights to make computerized interfaces for banking applications and web stages and acquaint gamification into client’s associations with draw in them to utilize your item.

                                </p>
                            </div>
                        </div>
                        <div className="healthcares_div_right">
                            <div className="healthcares_div_img">
                                <img src={img1} alt="..." />
                            </div>
                        </div>
                    </div>
                    {/* <div className="healthcareswipper">

                        <Swiper
                            slidesPerView={3}
                            spaceBetween={30}
                            navigation={true}
                            breakpoints={{
                                0: {
                                    slidesPerView: 1,
                                },
                                400: {
                                    slidesPerView: 2,
                                },
                                639: {
                                    slidesPerView: 3,
                                },
                            }}
                            modules={[Navigation]}
                            className='mySwiper'
                        >
                            <SwiperSlide className="">
                                <div className="healthcare_divsld_img">
                                    <img src={img2} alt="..." />
                                </div>
                                <div className="">
                                    <p className="slide_text_dtls_pp">
                                        Delivers World-class Management Software
                                    </p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="">
                                <div className="healthcare_divsld_img">
                                    <img src={img3} alt="..." />
                                </div>
                                <div className="">
                                    <p className="slide_text_dtls_pp">
                                        Customer Service Solution
                                    </p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="">
                                <div className="healthcare_divsld_img">
                                    <img src={img4} alt="..." />
                                </div>
                                <div className="">
                                    <p className="slide_text_dtls_pp">
                                        Support Multiple Tax rats & Payment Models
                                    </p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="">
                                <div className="healthcare_divsld_img">
                                    <img src={img3} alt="..." />
                                </div>
                                <div className="">
                                    <p className="slide_text_dtls_pp">
                                        Customer Service Solution
                                    </p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="">
                                <div className="healthcare_divsld_img">
                                    <img src={img4} alt="..." />
                                </div>
                                <div className="">
                                    <p className="slide_text_dtls_pp">
                                        Support Multiple Tax rats & Payment Models
                                    </p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="">
                                <div className="healthcare_divsld_img">
                                    <img src={img3} alt="..." />
                                </div>
                                <div className="">
                                    <p className="slide_text_dtls_pp">
                                        Customer Service Solution
                                    </p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="">
                                <div className="healthcare_divsld_img">
                                    <img src={img4} alt="..." />
                                </div>
                                <div className="">
                                    <p className="slide_text_dtls_pp">
                                        Support Multiple Tax rats & Payment Models
                                    </p>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div> */}
                </div>
            </section>
        </>
    )
}

export default RetailManagement