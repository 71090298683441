import React from 'react'
import workwith from "../../Images/workwith.png"
const Whywork = () => {
    return (
        <>
            <section className=''>
                <div className='cus_container'>
                    <div className=''>
                        <h4 className='worlttstxthhh'>Why Work With Us ?</h4>
                    </div>

                    <div className='whyworkdivmaindivflx'>

                        <div className='whyworkdivmain'>
                            <div className='whyworkdivmainimg'>
                                <img src={workwith} alt="..." />
                            </div>
                            <div className='whyworkdivmaintxt'>
                                <p className='whyworkdivmaintxtpphhhdr'>Innovative Projects</p>
                                <p className='whyworkdivmaintxtpp'>
                                Join us and be part of cutting-edge projects that push the boundaries of technology. At our IT company, you'll work on innovative solutions that make a real impact.
                                </p>
                            </div>
                        </div>
                        <div className='whyworkdivmain'>
                            <div className='whyworkdivmainimg'>
                                <img src={workwith} alt="..." />
                            </div>
                            <div className='whyworkdivmaintxt'>
                            <p className='whyworkdivmaintxtpphhhdr'>Career Development</p>
                                <p className='whyworkdivmaintxtpp'>
                                We prioritize your professional growth with continuous learning opportunities, certifications, and pathways for advancement. Your career progression is paramount to us.
                                </p>
                            </div>
                        </div>

                        <div className='whyworkdivmain'>
                            <div className='whyworkdivmainimg'>
                                <img src={workwith} alt="..." />
                            </div>
                            <div className='whyworkdivmaintxt'>
                            <p className='whyworkdivmaintxtpphhhdr'>Dynamic Work Environment</p>
                                <p className='whyworkdivmaintxtpp'>
                                Experience a vibrant and collaborative workplace where your ideas are valued. Our inclusive culture promotes creativity and teamwork, making it an exciting place to work.
                                </p>
                            </div>
                        </div>

                        <div className='whyworkdivmain'>
                            <div className='whyworkdivmainimg'>
                                <img src={workwith} alt="..." />
                            </div>
                            <div className='whyworkdivmaintxt'>
                            <p className='whyworkdivmaintxtpphhhdr'>Work-Life Balance</p>
                                <p className='whyworkdivmaintxtpp'>
                                We understand the importance of balancing work with personal life. Our flexible work policies, like remote working options and flexible hours, ensure you have a healthy work-life balance.
                                </p>
                            </div>
                        </div>

                        <div className='whyworkdivmain'>
                            <div className='whyworkdivmainimg'>
                                <img src={workwith} alt="..." />
                            </div>
                            <div className='whyworkdivmaintxt'>
                            <p className='whyworkdivmaintxtpphhhdr'>Competitive Compensation</p>
                                <p className='whyworkdivmaintxtpp'>
                                Benefit from a competitive salary and comprehensive benefits package. We believe in rewarding our employees fairly for their hard work and dedication.
                                </p>
                            </div>
                        </div>

                        <div className='whyworkdivmain'>
                            <div className='whyworkdivmainimg'>
                                <img src={workwith} alt="..." />
                            </div>
                            <div className='whyworkdivmaintxt'>
                            <p className='whyworkdivmaintxtpphhhdr'>Impactful Work</p>
                                <p className='whyworkdivmaintxtpp'>
                                Contribute to projects that make a difference in the world. By working with us, you'll be part of a company that uses technology to solve real-world problems and improve lives.
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

export default Whywork
