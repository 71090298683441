import React from 'react'
import carrergrw from "../../Images/Cygnus Pictures/Homepage/carrergrw.jpg"
import { useNavigate } from 'react-router-dom'
const HomeCareer = () => {
    const navigate=useNavigate()
    const joinclickcarrer =()=>{
        navigate("/workmain")
    }
    return (
        <section className='homecarrersectionmaib'>
            <div className='cus_container'>
                <div className='carrergrowdivflx'>
                    <div className='carrergrowhmomedivlerft'>
                        <div className='carrergrowhmomedivlerftimg'>
                            <img src={carrergrw} alt='...' />
                            <div className='letstdotogetherttxtppmain'>
                                <h4 className='letstdotogetherttxtpp'>Let’s do innovation together</h4>
                            </div>
                        </div>
                    </div>
                    <div className='carrergrowhmomedivright'>
                        <p className='cattrretxtppsngl'>Careers</p>
                        <p className='grwtxttspppcarrte'>Grow Your Career With Us</p>
                        <p className='jointxtthometxttpp'>
                            Join Our Dynamic team and shape the future of technology,Let's build tomorrow,today!
                        </p>
                        <button className='joimnusbtnnbns' onClick={joinclickcarrer}>
                            Join us
                            <span>

                                <i class="fa-solid fa-arrow-right"></i>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HomeCareer
