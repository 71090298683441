import React from 'react'
import mobileappsbanner from "../../Images/mobileappsbanner.jpg"
const Mobileappsmain = () => {
  return (
      <>
          <section className='industrialsolution_banner'>
        <div className='bgimage_'>
          <img src={mobileappsbanner} className='img-fluid' alt='..' />
        </div>
        <div className='banner_wrap'>
          <h2 className='sec_headingbannertset'> End to end <span> MOBILE APP SERVICES </span> </h2>
        </div>
      </section>
      </>
  )
}

export default Mobileappsmain
