import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';

import { Navigation } from 'swiper/modules';

import img1 from "../../Images/Cygnus Pictures/Industrial Solutions/pina-messina-kfJkpeI6Lgc-unsplash.jpg";
import img2 from "../../Images/Cygnus Pictures/Industrial Solutions/thisisengineering-mvbtVeRVJzg-unsplash.jpg";
import img3 from "../../Images/Cygnus Pictures/Industrial Solutions/Screenshot 2024-07-11 172342.png";
import img4 from "../../Images/Cygnus Pictures/Industrial Solutions/Screenshot 2024-07-11 172510.png";

const PharmaceuticalIndustry = () => {
    return (
        <>
            <section className="healthcare_main" id='PharmaceuticalIndustry'>
                <div className="cus_container">
                    <div className="healthcares_div">
                        <div className="healthcares_div_left">
                            <div className="">
                                <h4 className="hlthcaretetx_heading_text">Pharmaceutical and Life Sciences Industry</h4>
                                <p className="hlthcaretetx_para_text">
                                Serving the needs of pharmaceutical and life-science medical services through the technology with an extraordinary depth and breadth of services. With agenda of Innovative, cost effective solutions to meet the demands companies that are seeking ways to enrich the R&D productivity, increase the efficiency of its operations.

                                </p>
                            </div>
                        </div>
                        <div className="healthcares_div_right">
                            <div className="healthcares_div_img">
                                <img src={img1} alt="..." />
                            </div>
                        </div>
                    </div>
                    {/* <div className="healthcareswipper">

                    <Swiper
                            slidesPerView={3}
                            spaceBetween={30}
                            navigation={true} 
                            modules={[Navigation]}
                            breakpoints={{
                                0: {
                                  slidesPerView: 1,
                                },
                                400:{
                                  slidesPerView:2,
                                },
                                639: {
                                  slidesPerView: 3,
                                },
                              }}
                            className='mySwiper'
                        >
                            <SwiperSlide className="">
                                <div className="healthcare_divsld_img">
                                    <img src={img2} alt="..." />
                                </div>
                                <div className="">
                                    <p className="slide_text_dtls_pp">
                                        Clinical Management Software Development
                                    </p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="">
                                <div className="healthcare_divsld_img">
                                    <img src={img3} alt="..." />
                                </div>
                                <div className="">
                                    <p className="slide_text_dtls_pp">Pharma Manufacturing & commercialization Software Development</p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="">
                                <div className="healthcare_divsld_img">
                                    <img src={img4} alt="..." />
                                </div>
                                <div className="">
                                    <p className="slide_text_dtls_pp">Pharma Distribution Software Development</p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="">
                                <div className="healthcare_divsld_img">
                                    <img src={img3} alt="..." />
                                </div>
                                <div className="">
                                    <p className="slide_text_dtls_pp">Pharma Manufacturing & commercialization Software Development</p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="">
                                <div className="healthcare_divsld_img">
                                    <img src={img4} alt="..." />
                                </div>
                                <div className="">
                                    <p className="slide_text_dtls_pp">Pharma Distribution Software Development</p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="">
                                <div className="healthcare_divsld_img">
                                    <img src={img3} alt="..." />
                                </div>
                                <div className="">
                                    <p className="slide_text_dtls_pp">Pharma Manufacturing & commercialization Software Development</p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="">
                                <div className="healthcare_divsld_img">
                                    <img src={img4} alt="..." />
                                </div>
                                <div className="">
                                    <p className="slide_text_dtls_pp">Pharma Distribution Software Development</p>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div> */}
                </div>
            </section>
        </>
    )
}

export default PharmaceuticalIndustry