import React from 'react'

const Consultingcontent = () => {
    return (
        <>
            <section className='SectorWeServer_sec'>
                <div className='cus_container'>
                    <h2 className='sec_heading'>Driving Innovation and Efficiency in a Digital World </h2>

                  

                    <p className='hlthcaretetx_para_text'>
                    In today's fast-paced digital landscape, businesses encounter a myriad of challenges and opportunities that necessitate robust and innovative IT strategies. Business IT consulting services have become essential for organizations aiming to optimize operations, enhance customer experiences, and maintain a competitive edge.
                    </p>
                    <p className='hlthcaretetx_para_text'>
                    At Cygnus, we specialize in delivering comprehensive Business IT consulting services tailored to the unique needs of our clients. Our expertise spans across various critical areas, ensuring that our clients are equipped to navigate the complexities of modern IT environments.
                    </p>

                </div>
            </section>
        </>
    )
}

export default Consultingcontent
