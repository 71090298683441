import React from 'react'
import guruusgg from "../../Images/guruusgg.png"
import gurudas from '../../Images/02 (1).png'
const GurudasComp = () => {
    return (
        <>
            <section className='ourfounder'>
                <div className='cus_container'>
                    <div className='ourfounderdivflx'>
                        <div className='founderimgdivmain'>
                          
                            <div className='founderimgdiv'>

                                <img src={gurudas} alt='...' />
                            </div>
                            <p className='Vemtetxtppp'>
                                Gurudas Sarkar
                            </p>
                            <p className='founndtethhtstp'>
                            CEO
                            </p>
                        </div>

                        <div className='foundertext'>
                            <p className='foundertextpp'>
                            Gurudas Sarkar is a seasoned business leader with over 28 years of experience in Business IT Consulting, Solutions, and Services, primarily focused on the pharmaceutical, life science, and healthcare domains. He excels in driving technological innovation and business transformation engagements. Gurudas has a Bachelor’s degree from the University of Calcutta, an MS Degree from California Coast University & a fellow at the Wharton School, University of Pennsylvania.
                            His leadership has been pivotal in several successful merger and acquisition initiatives. He served as CEO of the South East Asia region of a publicly traded corporation at NASDAQ. A thought leader in his field, Gurudas had been a Member of the Board of Governors of PDSIT, a School of Information Technology under Bengal Engineering College Deemed University, and has acted as a strategic advisor for several IT Products and Services organizations. As an additional responsibility, Gurudas is also a Managing Partner of Onyx/SIGMA Construction.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default GurudasComp
