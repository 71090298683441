import React from 'react'
import Aimlmain from './Aimlmain'
import Aimlcontent from './Aimlcontent'
import Aimlmaincontent from './Aimlmaincontent'

const Aiml = () => {
    return (
        <>
            <Aimlmain />
            <Aimlcontent />
            <Aimlmaincontent />
        </>
    )
}

export default Aiml
