import React from 'react'
import CyberSecuritybanner from "../../Images/CyberSecuritybanner.jpg"
const Cybersecuritymain = () => {
  return (
    <>
     <section className='industrialsolution_banner'>
        <div className='bgimage_'>
          <img src={CyberSecuritybanner} className='img-fluid' alt='..' />
        </div>
        <div className='banner_wrap'>
          <h2 className='sec_headingbannertset'> Cyber <span className='security-span'>Security</span></h2>
        </div>
      </section>
    </>
      
  )
}

export default Cybersecuritymain
