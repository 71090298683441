import React from 'react'
import Storydetails from './Storydetails'
import Missionvission from './Missionvission'

const Storymain = () => {
  return (
    <>
      <Storydetails />
      <Missionvission/>
    </>
  )
}

export default Storymain
