import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';

import { Navigation } from 'swiper/modules';

import img1 from "../../Images/Cygnus Pictures/Industrial Solutions/cdc-zGzZvLO5Brs-unsplash.jpg";
import img2 from "../../Images/Cygnus Pictures/Industrial Solutions/ElectronicHealth.jpg";
import img3 from "../../Images/Cygnus Pictures/Industrial Solutions/HospitalManagement.png";
import img4 from "../../Images/Cygnus Pictures/Industrial Solutions/PracticeManagement.png";
const HospitalManagement = () => {
  return (
   <>
        <section className="healthcare_main" id='healthcare_main'>
                <div className="cus_container">
                    <div className="healthcares_div">
                        <div className="healthcares_div_left">
                            <div className="">
                                <h4 className="hlthcaretetx_heading_text">Hospital Management
                                System
                               </h4>
                                <p className="hlthcaretetx_para_text">
                                Fully integrated system capable of managing the entire hospital capable of managing appointments and electronic health records for both inpatient records. Managing doctor’s catalogs, surgeries, other procedures, and all the aspects related to them.
                                </p>
                            </div>
                        </div>
                        <div className="healthcares_div_right">
                            <div className="healthcares_div_img">
                                <img src={img3} alt="..." />
                            </div>
                        </div>
                    </div>
                    {/* <div className="healthcareswipper">

                        <Swiper
                            slidesPerView={3}
                            spaceBetween={30}
                            navigation={true} 
                            modules={[Navigation]}
                            breakpoints={{
                                0: {
                                  slidesPerView: 1,
                                },
                                400:{
                                  slidesPerView:2,
                                },
                                639: {
                                  slidesPerView: 3,
                                },
                              }}
                            className='mySwiper'
                        >
                            <SwiperSlide className="">
                                <div className="healthcare_divsld_img">
                                    <img src={img2} alt="..." />
                                </div>
                                <div className="">
                                    <p className="slide_text_dtls_pp">
                                        Electronic Health records and Management
                                    </p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="">
                                <div className="healthcare_divsld_img">
                                    <img src={img3} alt="..." />
                                </div>
                                <div className="">
                                    <p className="slide_text_dtls_pp">Hospital Management System</p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="">
                                <div className="healthcare_divsld_img">
                                    <img src={img4} alt="..." />
                                </div>
                                <div className="">
                                    <p className="slide_text_dtls_pp">Practice Management Software</p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="">
                                <div className="healthcare_divsld_img">
                                    <img src={img3} alt="..." />
                                </div>
                                <div className="">
                                    <p className="slide_text_dtls_pp">
                                        Electronic Health records and Management
                                    </p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="">
                                <div className="healthcare_divsld_img">
                                    <img src={img4} alt="..." />
                                </div>
                                <div className="">
                                    <p className="slide_text_dtls_pp">Hospital Management System</p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="">
                                <div className="healthcare_divsld_img">
                                    <img src={img3} alt="..." />
                                </div>
                                <div className="">
                                    <p className="slide_text_dtls_pp">Hospital Management System</p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="">
                                <div className="healthcare_divsld_img">
                                    <img src={img4} alt="..." />
                                </div>
                                <div className="">
                                    <p className="slide_text_dtls_pp">Practice Management Software</p>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div> */}
                </div>
            </section>
   </>
  )
}

export default HospitalManagement
