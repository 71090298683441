import React from 'react'
import pharramcompliendcebanner from "../../Images/pharramcompliendcebanner.jpg"
const Pharmacompliencemain = () => {
  return (
    <>
     <section className='industrialsolution_banner'>
        <div className='bgimage_'>
          <img src={pharramcompliendcebanner} className='img-fluid' alt='..' />
        </div>
        <div className='banner_wrap'>
          <h2 className='sec_headingbannertset'> Pharma <span className='compliance-span'>Compliance</span></h2>
        </div>
      </section>
    </>
  )
}

export default Pharmacompliencemain
