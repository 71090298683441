import React from 'react'
import ContingentStaffingmain from './ContingentStaffingmain'
import ContingentStaffingcontent from './ContingentStaffingcontent'
import ContingentStaffingcontentmain from './ContingentStaffingcontentmain'

const ContingentStaffing = () => {
    return (
        <>
            <ContingentStaffingmain />
            <ContingentStaffingcontent />
            <ContingentStaffingcontentmain />
        </>
    )
}

export default ContingentStaffing
