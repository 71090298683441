import React from 'react'
import core_banking from "../../Images/core_banking.jpg";
import mobilakjjajjj from "../../Images/mobilakjjajjj.jpg";
import cloudbaseddigital_contactcentera from "../../Images/cloudbaseddigital_contactcentera.jpg";
import leading from "../../Images/leading.jpg";
import FraudDetectionTechnology from "../../Images/FraudDetectionTechnology.jpg";
import riskksooah from "../../Images/riskksooah.jpg";
import opayypartner from "../../Images/opayypartner.jpg"
const Bfsicontentmain = () => {
  return (
    <>
      <section className="softwaresevices_main" >
        <div className="cus_container">
          <div className="healthcares_divnewadd">
            <div className="healthcares_div_left">
              <div className="">
                <h4 className="hlthcaretetx_heading_text">Core Banking Software</h4>
                <p className="hlthcaretetx_para_text">
                  Our cutting-edge core banking solutions are engineered to streamline banking operations, fortify customer relationships, and ensure rigorous compliance with regulatory standards. Our focus on scalability and security empowers financial institutions to enhance their service portfolios and operational efficacy.
                </p>
              </div>
            </div>
            <div className="healthcares_div_right">
              <div className="healthcares_div_img">
                <img src={core_banking} alt="..." />
              </div>
            </div>
          </div>

          <div className="healthcares_divnewadd">
            <div className="healthcares_div_left">
              <div className="">
                <h4 className="hlthcaretetx_heading_text">Mobile Banking Applications</h4>
                <p className="hlthcaretetx_para_text">
                  In today’s fast-paced digital economy, delivering an exceptional mobile banking experience is paramount. We specialize in developing sophisticated mobile banking applications that afford users seamless access to services, enabling them to manage their financial affairs with convenience and confidence. Security and user-centric design are at the core of our offerings.
                </p>
              </div>
            </div>
            <div className="healthcares_div_right">
              <div className="healthcares_div_img">
                <img src={mobilakjjajjj} alt="..." />
              </div>
            </div>
          </div>

          <div className="healthcares_divnewadd">
            <div className="healthcares_div_left">
              <div className="">
                <h4 className="hlthcaretetx_heading_text">Cloud-Based Digital Contact Center</h4>
                <p className="hlthcaretetx_para_text">
                  Revolutionize your customer service capabilities with our cloud-based digital contact center solutions. Designed for optimal flexibility and efficiency, our platforms integrate state-of-the-art technology to foster superior customer interactions, thereby enabling real-time communication and insightful analytics.
                </p>
              </div>
            </div>
            <div className="healthcares_div_right">
              <div className="healthcares_div_img">
                <img src={cloudbaseddigital_contactcentera} alt="..." />
              </div>
            </div>
          </div>

          <div className="healthcares_divnewadd">
            <div className="healthcares_div_left">
              <div className="">
                <h4 className="hlthcaretetx_heading_text">Lending and Leasing Systems</h4>
                <p className="hlthcaretetx_para_text">
                  Transform your lending operations with our bespoke lending and leasing systems. Our solutions facilitate expedited loan processing, comprehensive risk assessment, and adherence to compliance standards, ensuring that your organization meets and exceeds the expectations of its clientele.
                </p>
              </div>
            </div>
            <div className="healthcares_div_right">
              <div className="healthcares_div_img">
                <img src={leading} alt="..." />
              </div>
            </div>
          </div>

          <div className="healthcares_divnewadd">
            <div className="healthcares_div_left">
              <div className="">
                <h4 className="hlthcaretetx_heading_text">Fraud Detection Technology</h4>
                <p className="hlthcaretetx_para_text">
                  Safeguard your assets and sensitive information with our advanced fraud detection technologies. Utilizing sophisticated algorithms and machine learning, we empower you to proactively identify and mitigate potential threats, ensuring your organization remains a step ahead of emerging risks.
                </p>
              </div>
            </div>
            <div className="healthcares_div_right">
              <div className="healthcares_div_img">
                <img src={FraudDetectionTechnology} alt="..." />
              </div>
            </div>
          </div>

          <div className="healthcares_divnewadd">
            <div className="healthcares_div_left">
              <div className="">
                <h4 className="hlthcaretetx_heading_text">Risk Management Compliance</h4>
                <p className="hlthcaretetx_para_text">
                  Navigating the intricate web of regulatory requirements can be a formidable challenge. Our risk management compliance services provide you with the insights and tools necessary to understand and mitigate risks effectively, ensuring your organization adheres to obligatory standards while preserving its esteemed reputation.
                </p>
              </div>
            </div>
            <div className="healthcares_div_right">
              <div className="healthcares_div_img">
                <img src={riskksooah} alt="..." />
              </div>
            </div>
          </div>



        </div>
      </section>

      <section className="softwaresevices_main" >
        <div className="cus_container">
          <div className='choosedivflx'>
            <div className='choosedivleft'>
              <h4 className="hlthcaretetx_heading_text">Why Partner with Cygnus Professionals?</h4>
              <p className="hlthcaretetx_para_text_new">
                <span> Expertise : </span> Our team comprises seasoned professionals with extensive experience across the BFSI domain, providing you with invaluable insights and innovative solutions tailored to your specific challenges.
              </p>

              <p className="hlthcaretetx_para_text_new">
                <span>Client-Centric Philosophy :</span> We prioritize a deep understanding of your unique needs, collaborating closely to ensure our services align seamlessly with your strategic objectives
              </p>


              <p className="hlthcaretetx_para_text_new">
                <span> Innovative Solutions :  </span> We harness the latest technologies and methodologies, positioning our clients at the forefront of an increasingly digital marketplace
              </p>


              <p className="hlthcaretetx_para_text_new">
                <span> Commitment to Excellence : </span> Our steadfast focus on accuracy and quality means you can trust us to yield outstanding results that surpass expectations.
              </p>

            </div>
            <div className='choosedivright'>
              <div className='choosedivrightimgmain'>
                <img src={opayypartner} alt='...' />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="softwaresevices_main" >
        <div className="cus_container">
          <div className=''>
            <h4 className="hlthcaretetx_heading_text">Engage with Us</h4>
            <p className="hlthcaretetx_para_text">
              Join the esteemed cadre of organizations that have transformed their operations with Cygnus Professionals. Together, let us expertly navigate the complexities of the BFSI landscape and propel your organization toward sustained success.
              Contact us today to discover how we can assist you in accomplishing your business aspirations

            </p>
          </div>
        </div>
      </section>
    </>
  )
}

export default Bfsicontentmain
