import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';


// import required modules
import { Pagination, Navigation } from 'swiper/modules';

import consulting from  "../../Images/consulting.png"
import CONTIGENTSERVICE from "../../Images/CONTIGENTSERVICE.jpg";
import EXCUTIVESEARCH from "../../Images/EXCUTIVESEARCH.jpg";
import engineering from "../../Images/engineering.jpeg";
import staffingservices from "../../Images/staffingservices.png";
import DIGITALANALYTICS1 from "../../Images/DIGITALANALYTICS1.jpg"
import { Link, useNavigate } from 'react-router-dom';


const ServicesHome = () => {
   
  
    return (
        <>
            <section className='ServicesHome_sec'>
                <div className='cus_container'>

                    <h2 className='sec_heading'>Services</h2>


                    <div className="healthcareswipper">

                        <Swiper
                            slidesPerView={3}
                            spaceBetween={30}
                            loop={true}              // Enable looping
                            pagination={{
                                type: 'fraction',
                            }}
                            navigation={true}
                            modules={[Pagination, Navigation]}

                            breakpoints={{
                                0: {
                                    slidesPerView: 1,
                                },
                                400: {
                                    slidesPerView: 2,
                                },
                                639: {
                                    slidesPerView: 3,
                                },

                            }}
                            className='mySwiper'
                        >

                            <SwiperSlide className="" >
                                <Link to="/businessconsulting">
                                <div className="healthcare_divsld_img" >
                                <img src={consulting} className='img-fluid' alt='..' />  
                                </div>
                                <div className="servicetextarrowduvflx">
                                    <p className="slide_text_dtls_pp">
                                    BUSINESS  <br></br> CONSULTING
                                    </p>
                                    <span className='arrowdivrignt'>
                                        <i class="fa-solid fa-right-long"></i>
                                    </span>
                                </div>
                                </Link>
                            </SwiperSlide>

                            <SwiperSlide className="" >
                                <Link to="/staffingservice">
                                <div className="healthcare_divsld_img" >
                                <img src={staffingservices} className='img-fluid' alt='..' />
                                </div>
                                <div className="servicetextarrowduvflx">
                                    <p className="slide_text_dtls_pp">
                                    STAFFING  <br></br> SERVICES
                                    </p>
                                    <span className='arrowdivrignt'>
                                        <i class="fa-solid fa-right-long"></i>
                                    </span>
                                </div>
                                </Link>
                            </SwiperSlide>

                            <SwiperSlide className="" >
                                <Link to="/contigentstuffing">
                                <div className="healthcare_divsld_img">
                                <img src={CONTIGENTSERVICE} className='img-fluid' alt='..' />
                                </div>
                                <div className="servicetextarrowduvflx">
                                    <p className="slide_text_dtls_pp">
                                    CONTINGENT <br></br> SERVICES
                                    </p>
                                    <span className='arrowdivrignt'>
                                        <i class="fa-solid fa-right-long"></i>
                                    </span>
                                </div>
                                </Link>
                            </SwiperSlide>

                            <SwiperSlide className="">
                                <Link to="/excutivesearch">
                                <div className="healthcare_divsld_img">
                                <img src={EXCUTIVESEARCH} className='img-fluid' alt='..' />
                                </div>
                                <div className="servicetextarrowduvflx">
                                    <p className="slide_text_dtls_pp">
                                    EXECUTIVE SEARCH<br></br>  SERVICES

                                    </p>
                                    <span className='arrowdivrignt'>
                                        <i class="fa-solid fa-right-long"></i>
                                    </span>
                                </div>
                                </Link>
                            </SwiperSlide>

                            <SwiperSlide className="">
                                <Link to="/engineeringindex">
                                <div className="healthcare_divsld_img">
                                     <img src={engineering} className='img-fluid' alt='..' />
                                </div>
                                <div className="servicetextarrowduvflx">
                                    <p className="slide_text_dtls_pp">
                                        ENGINEERING<br></br> SERVICES
                                    </p>
                                    <span className='arrowdivrignt'>
                                        <i class="fa-solid fa-right-long"></i>
                                    </span>
                                </div>
                                </Link>
                            </SwiperSlide>

                            <SwiperSlide className="">
                                <Link to="/digitalanalytics">
                                <div className="healthcare_divsld_img">
                                <img src={DIGITALANALYTICS1} className='img-fluid' alt='..' />
                                </div>
                                <div className="servicetextarrowduvflx">
                                    <p className="slide_text_dtls_pp">
                                    DIGITAL & ANALYTICS<br></br> SERVICES
                                    </p>
                                    <span className='arrowdivrignt'>
                                        <i class="fa-solid fa-right-long"></i>
                                    </span>
                                </div>
                                </Link>
                            </SwiperSlide>

                     

{/* 
                            <SwiperSlide className="">
                                <div className="healthcare_divsld_img">
                                    <img src={img4} alt="..." />
                                </div>
                                <div className="servicetextarrowduvflx">
                                    <p className="slide_text_dtls_pp">
                                        Consulting<br></br> Program

                                    </p>
                                    <span className='arrowdivrignt'>
                                        <i class="fa-solid fa-right-long"></i>
                                    </span>
                                </div>
                            </SwiperSlide> */}

                            {/* <SwiperSlide className="" onClick={handleinnnerclick}>
                                <div className="healthcare_divsld_img">
                                    <img src={img1} alt="..." />
                                </div>
                                <div className="servicetextarrowduvflx">
                                    <p className="slide_text_dtls_pp">
                                        Software<br></br> Services

                                    </p>
                                    <span className='arrowdivrignt'>
                                        <i class="fa-solid fa-right-long"></i>
                                    </span>
                                </div>
                            </SwiperSlide> */}


                        </Swiper>

                    </div>
                </div>
            </section>
        </>
    )
}

export default ServicesHome