import React from 'react'
import Machinelearning from "../../Images/Machinelearning.png";
import CyberSecuritybanner1 from "../../Images/CyberSecuritybanner1.jpg";
import CyberSecuritybanner2 from "../../Images/CyberSecuritybanner2.jpg";
import CyberSecuritybanner3 from "../../Images/CyberSecuritybanner3.jpg";
import CyberSecuritybanner4 from "../../Images/CyberSecuritybanner4.jpg";
import CyberSecuritybanner5 from "../../Images/CyberSecuritybanner5.jpg"
const Cybersecuritymaincontent = () => {
  return (
    <>
        <section className="softwaresevices_main" >
                <div className="cus_container">
                    <div className="healthcares_divnewadd">
                        <div className="healthcares_div_left">
                            <div className="">
                                <h4 className="hlthcaretetx_heading_text">Proactive Risk Assessment and Management </h4>
                                <p className="hlthcaretetx_para_text">
                                Stay ahead of emerging threats with our preventive risk assessment and management services. We meticulously analyse your IT landscape to identify vulnerabilities and deploy robust strategies to mitigate risks.
                                </p>
                            </div>
                        </div>
                        <div className="healthcares_div_right">
                            <div className="healthcares_div_imgmainalada">
                                <img src={CyberSecuritybanner1} alt="..." />
                            </div>
                        </div>
                    </div>

                    <div className="healthcares_divnewadd">
                        <div className="healthcares_div_left">
                            <div className="">
                                <h4 className="hlthcaretetx_heading_text">Advanced Threat Detection and Response</h4>
                                <p className="hlthcaretetx_para_text">
                                Leverage next-generation technologies with our sophisticated threat detection and response solutions. We utilize cutting-edge monitoring tools and strategic incident response plans to swiftly identify and neutralize cyber threats, minimizing impact and preserving business integrity.
                                </p>
                            </div>
                        </div>
                        <div className="healthcares_div_right">
                            <div className="healthcares_div_imgmainalada">
                                <img src={CyberSecuritybanner2} alt="..." />
                            </div>
                        </div>
                    </div>

                    <div className="healthcares_divnewadd">
                        <div className="healthcares_div_left">
                            <div className="">
                                <h4 className="hlthcaretetx_heading_text">Premier Data Protection and Encryption</h4>
                                <p className="hlthcaretetx_para_text">
                                Safeguard your most critical assets with our state-of-the-art data protection and encryption services. Our solutions ensure your sensitive information is shielded from unauthorized access and breaches, while facilitating compliance with rigorous data protection regulations.
                                </p>
                            </div>
                        </div>
                        <div className="healthcares_div_right">
                            <div className="healthcares_div_imgmainalada">
                                <img src={CyberSecuritybanner3} alt="..." />
                            </div>
                        </div>
                    </div>

                    <div className="healthcares_divnewadd">
                        <div className="healthcares_div_left">
                            <div className="">
                                <h4 className="hlthcaretetx_heading_text">	Comprehensive Compliance and Governance</h4>
                                <p className="hlthcaretetx_para_text">
                                Achieve and maintain compliance with industry standards such as GDPR, HIPAA, and more. Our governance frameworks are seamlessly integrated into your operations, ensuring your cyber security practices align with regulatory mandates and best practices.

                                </p>
                            </div>
                        </div>
                        <div className="healthcares_div_right">
                            <div className="healthcares_div_imgmainalada">
                                <img src={CyberSecuritybanner4} alt="..." />
                            </div>
                        </div>
                    </div>

                    <div className="healthcares_divnewadd">
                        <div className="healthcares_div_left">
                            <div className="">
                                <h4 className="hlthcaretetx_heading_text">	Empowering Security Awareness Training</h4>
                                <p className="hlthcaretetx_para_text">
                                Foster a security-conscious culture with our customized training programs. We equip your team with the knowledge to recognize and counteract cyber threats effectively, reducing human error and reinforcing your organization's defences.

                                </p>
                            </div>
                        </div>
                        <div className="healthcares_div_right">
                            <div className="healthcares_div_imgmainalada">
                                <img src={CyberSecuritybanner5} alt="..." />
                            </div>
                        </div>
                    </div>

                    
                </div>
            </section>
            <section className="softwaresevices_main">
                <div className="cus_container">
                    <div>
                        <p className="hlthcaretetx_para_text">
                        At Cygnus, our mission is to deliver innovative Cybersecurity solutions that not only protect but also empower you to excel in the digital landscape. By integrating cutting-edge technology with strategic expertise, we provide a security framework that is resilient, adaptive, and aligned with your business aspirations.
                        </p>
                    </div>
                </div>
            </section>
    </>
  )
}

export default Cybersecuritymaincontent
