import React from 'react'
import Machinelearning from "../../Images/Machinelearning.png";
import mobileapp from  "../../Images/mobileapp.png";
import mobileapps from "../../Images/mobileapps.jpg";
import fullstack from "../../Images/fullstack.jpg";
import ITInfrastructure from "../../Images/ITInfrastructure.jpg"
const Mobileappsmaincontent = () => {
    return (
        <>
            <section className="softwaresevices_main" >
                <div className="cus_container">
                    <div className="healthcares_divnewadd">
                        <div className="healthcares_div_left">
                            <div className="">
                                <h4 className="hlthcaretetx_heading_text">End-to-End Development: </h4>
                                <p className="hlthcaretetx_para_text">
                                    From ideation to post-launch support, we cover it all to ensure your app is a standout success.
                                </p>
                            </div>
                        </div>
                        <div className="healthcares_div_right">
                            <div className="healthcares_div_imgmainalada">
                                <img src={mobileapp} alt="..." />
                            </div>
                        </div>
                    </div>

                    <div className="healthcares_divnewadd">
                        <div className="healthcares_div_left">
                            <div className="">
                                <h4 className="hlthcaretetx_heading_text">User-Centric Design</h4>
                                <p className="hlthcaretetx_para_text">
                                    Our design philosophy revolves around creating intuitive, visually appealing interfaces that keep users engaged.
                                </p>
                            </div>
                        </div>
                        <div className="healthcares_div_right">
                            <div className="healthcares_div_imgmainalada">
                                <img src={mobileapps} alt="..." />
                            </div>
                        </div>
                    </div>

                    <div className="healthcares_divnewadd">
                        <div className="healthcares_div_left">
                            <div className="">
                                <h4 className="hlthcaretetx_heading_text">Agile and Transparent Process</h4>
                                <p className="hlthcaretetx_para_text">
                                    We follow an agile development approach, emphasizing flexibility, transparency, and collaboration to meet your needs efficiently.
                                </p>
                            </div>
                        </div>
                        <div className="healthcares_div_right">
                            <div className="healthcares_div_imgmainalada">
                                <img src={fullstack} alt="..." />
                            </div>
                        </div>
                    </div>

                    <div className="healthcares_divnewadd">
                        <div className="healthcares_div_left">
                            <div className="">
                                <h4 className="hlthcaretetx_heading_text">Quality and Efficiency</h4>
                                <p className="hlthcaretetx_para_text">
                                    With a commitment to quality and efficiency, we develop high-performing apps that align with the highest standards, all while being cost-effective and timely.

                                </p>
                            </div>
                        </div>
                        <div className="healthcares_div_right">
                            <div className="healthcares_div_imgmainalada">
                                <img src={ITInfrastructure} alt="..." />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="softwaresevices_main">
                <div className="cus_container">
                    <div>
                        <p className="hlthcaretetx_para_text">
                        Partner with Cygnus to turn your app vision into a thriving reality. Our blend of technical prowess and market insight ensures that your mobile app not only meets but exceeds expectations. Let's create something exceptional together !
                        </p>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Mobileappsmaincontent
