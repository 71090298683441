import React from 'react'

const Cloudtechnologiescontent = () => {
  return (
    <>
      <section className='SectorWeServer_sec'>
        <div className='cus_container'>
            <h2 className='sec_heading'>Your Data-Driven Advantage</h2>
          <p className='hlthcaretetx_para_text'>
          In today's data-driven world, harnessing digital and analytics capabilities is essential for staying competitive. At Cygnus, we offer cutting-edge Digital & Analytics services designed to transform your data into actionable insights and drive strategic decision-making.
          </p>

          <h2 className='ggpappadinbtm'>
          Our services include :
          </h2>


        </div>
      </section>
    </>
  )
}

export default Cloudtechnologiescontent
