import React from 'react'
import Consultingmain from './Consultingmain'
import Consultingcontent from './Consultingcontent'
import Consultingcontentmain from './Consultingcontentmain'

const BusinessConsulting = () => {
    return (
        <>
      
            <Consultingmain />
            <Consultingcontent />
            <Consultingcontentmain />
            </>
    )
}

export default BusinessConsulting
