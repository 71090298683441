import React from 'react'
import Digitalandanalyticsmain from './Digitalandanalyticsmain'
import Digitalandanalyticscontent from './Digitalandanalyticscontent'
import Digitalandanalyticsmaincontent from './Digitalandanalyticsmaincontent'

const Digitalandanalytics = () => {
    return (
        <>
            <Digitalandanalyticsmain />
            <Digitalandanalyticscontent />
            <Digitalandanalyticsmaincontent />
        </>
    )
}

export default Digitalandanalytics
