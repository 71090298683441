import React from 'react'
import { Link } from 'react-router-dom'

const Practicescontent = () => {
  return (
    <>
      <section className='SectorWeServer_sec'>
        <div className='cus_container'>

          <p className='hlthcaretetx_para_text'>
            At Cygnus, our commitment to excellence is evident in our meticulous practices across key areas. Our deep expertise in Pharma Compliance and Cyber Security ensures that your operations are not only streamlined but also fortified and in strict adherence to industry standards. Learn how we excel in these critical fields:
          </p>
          <ul className='paggacompleijkenndul'>
            <li className='paggacompleijkenndli'>
              <Link to="/pharmacomplience" className='paggacompleijkenndlitxtpp'>
                Pharma Compliance
              </Link>
            </li>
            <li className=''>
              <Link to="/cybersecurity" className='paggacompleijkenndlitxtpp'>
                Cyber Security
              </Link>
            </li>
          </ul>
        </div>
      </section>
    </>
  )
}

export default Practicescontent
