import React from 'react'

const Cybersecuritycontent = () => {
  return (
   <>
       <section className='SectorWeServer_sec'>
                <div className='cus_container'>
                    <h2 className='sec_heading'>Your Cybersecurity Fortress</h2>
                    <p className='hlthcaretetx_para_text'>
                    In today's digital age, protecting your organization's assets and data is more critical than ever. At Cygnus, we offer top-tier Cybersecurity solutions designed to fortify your defences, safeguard sensitive information, and ensure uninterrupted operational continuity. Our comprehensive strategy combines cutting-edge technology with expert insight, delivering unparalleled security and resilience.
                    </p>
                    <h4 className='ggpappadinbtm'>
                    Key Services :
                    </h4>

                </div>
            </section>
   </>
  )
}

export default Cybersecuritycontent
