import React from 'react';
import HealthcareIndustrial from './HealthcareIndustrial';
import PharmaceuticalIndustry from './PharmaceuticalIndustry';
import BankingTelecomIndustry from './BankingTelecomIndustry';
import IndustrialSolutionBanner from './IndustrialSolutionBanner';
import SectorWeServer from './SectorWeServer';
import Telecom from './Telecom';
import PublicSector from './PublicSector';
import RetailManagement from './RetailManagement';
import Manufacturing from './Manufacturing';
import LogisticsTransportation from './LogisticsTransportation';
import Industrialsolutioncontent from './Industrialsolutioncontent';
import HospitalManagement from './HospitalManagement';
import PracticeManagement from './PracticeManagement';
import PharmaManufacturing from './PharmaManufacturing';
import PharmaDistribution from './PharmaDistribution';
import GeoSpatial from "./GeoSpatial";
import GeneralHealthSafety from "./GeneralHealthSafety"
import PrescientMaintainace from './PrescientMaintainace';
import DeliversWorldclass from './DeliversWorldclass';
import CustomerServiceSolution from './CustomerServiceSolution';
import SupportMultiple from './SupportMultiple';
import SupplyChain from './SupplyChain';
import DealerManagement from './DealerManagement';
import WarehouseManagement from './WarehouseManagement';
import AdvancedAnalytics from "./AdvancedAnalytics"
import NetworkProtection from './NetworkProtection';
import IndustrialSolutionallslider from './IndustrialSolutionallslider';
import Healthhospitalpractice from './Healthhospitalpractice';
import Pharmabankingfacturing from './Pharmabankingfacturing';
import Distelesector from './Distelesector';
import Retamanulogistics from './Retamanulogistics';
import Geohealthmaintaince from './Geohealthmaintaince';
import Delivercustomermultiple from './Delivercustomermultiple';
import Supplmanagementwarehouse from './Supplmanagementwarehouse';
const IndustrialSolutionInnerPage = () => {
  return (
    <>

      <IndustrialSolutionBanner />

      <SectorWeServer />

      <Industrialsolutioncontent/>

      <HealthcareIndustrial />
      <HospitalManagement />
      <PracticeManagement/> 
      <Healthhospitalpractice/>

      <PharmaceuticalIndustry />
      <BankingTelecomIndustry />
      <PharmaManufacturing/>
      <Pharmabankingfacturing/>


      <PharmaDistribution/> 
      <Telecom />
      <PublicSector />
      <Distelesector/>


      <RetailManagement />
      <Manufacturing />
      <LogisticsTransportation />
      <Retamanulogistics/>


        
        <GeoSpatial/>
        <GeneralHealthSafety/>
        <PrescientMaintainace/>
        <Geohealthmaintaince/>


        <DeliversWorldclass/>
        <CustomerServiceSolution/>
        <SupportMultiple/>
        <Delivercustomermultiple/> 


        <SupplyChain/>
        <DealerManagement/> 
        <WarehouseManagement/>
        <Supplmanagementwarehouse/>


        <AdvancedAnalytics/>
        <NetworkProtection/>
        
        <IndustrialSolutionallslider/>

    </>
  )
}

export default IndustrialSolutionInnerPage