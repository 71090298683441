import React from 'react'

const Fullstackcontent = () => {
  return (
     <>
        <section className='SectorWeServer_sec'>
        <div className='cus_container'>
            <h2 className='sec_heading'>Your Full Stack Development Partner</h2>
          <p className='hlthcaretetx_para_text'>
          At Cygnus, we understand the importance of Full Stack development in creating comprehensive and scalable applications that meet all user requirements. Our Full Stack services cover front-end and back-end development to deliver robust, end-to-end solutions. 
          </p>

          <h2 className='ggpappadinbtm'>
          Our offerings include : 
          </h2>


        </div>
      </section>
     </>
  )
}

export default Fullstackcontent
