import React from 'react'
import CONTIGENTSERVICE from "../../Images/CONTIGENTSERVICE.jpg"
const ContingentStaffingmain = () => {
  return (
    <>
      <section className='industrialsolution_banner'>
        <div className='bgimage_'>
          <img src={CONTIGENTSERVICE} className='img-fluid' alt='..' />
        </div>
        <div className='banner_wrap'>
          <h2 className='sec_headingbannertset'> End to end <span> CONTINGENT SERVICE  </span> </h2>
        </div>
      </section>
    </>
  )
}

export default ContingentStaffingmain
