import React from 'react'
import TechnologyStaffing from "../../Images/TechnologyStaffing.jpg"
const Bfsimain = () => {
  return (
    <section className='industrialsolution_banner'>
            <div className='bgimage_'>
                <img src={TechnologyStaffing} className='img-fluid' alt='..' />  
                <div className='banner_wrap'>
                    <h2 className='sec_headingbannertset'> BFSI </h2>
                </div>
              
            </div>

        </section>
  )
}

export default Bfsimain
