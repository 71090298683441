import React from 'react'

const Engineeringcontent = () => {
  return (
    <section className='SectorWeServer_sec'>
    <div className='cus_container'>
        {/* <h2 className='sec_heading'>The Sector We Serve  Engineering Services</h2> */}
        <p className='hlthcaretetx_para_text'>
        In an era defined by rapid technological progress and intricate manufacturing environments, the demand for skilled engineering and technical proficiency is soaring. At Cygnus, we specialize in offering tailored engineering services designed to meet the unique requirements of each client. Our team boasts unparalleled technical expertise spanning various industries and technologies, ensuring we assist you in optimizing operations and achieving strategic goals.
        </p>
        <h2 className='ggpappadinbtm'>
        Key highlights of our services at Cygnus include 
        </h2>

    </div>
</section>
  )
}

export default Engineeringcontent
