import React from 'react'
import consulting from "../../Images/consulting.png"
const Consultingmain = () => {
    return (
        <section className='industrialsolution_banner'>
            <div className='bgimage_'>
                <img src={consulting} className='img-fluid' alt='..' />  
                <div className='banner_wrap'>
                    <h2 className='sec_headingbannertset'> End to end <span> BUSINESS CONSULTING SERVICES  </span> </h2>
                </div>
              
            </div>

        </section>
    )
}

export default Consultingmain
