import React from 'react'
import Machinelearning from "../../Images/Machinelearning.png";
import aipoewerimg  from "../../Images/aipoewerimg.png";
import nlp from "../../Images/nlp.jpg";
import consulting from "../../Images/consulting.png"
const Aimlmaincontent = () => {
  return (
    <>
      <section className="softwaresevices_main" >
        <div className="cus_container">
          <div className="healthcares_divnewadd">
            <div className="healthcares_div_left">
              <div className="">
                <h4 className="hlthcaretetx_heading_text">AI Strategy and Consulting</h4>
                <p className="hlthcaretetx_para_text">
                  Collaborate with us to develop a customized AI strategy aligned with your business goals. Our AI tools, including machine learning and natural language processing (NLP), unlock opportunities and optimize
                </p>
              </div>
            </div>
            <div className="healthcares_div_right">
              <div className="healthcares_div_imgmainalada">
                <img src={aipoewerimg} alt="..." />
              </div>
            </div>
          </div>

          <div className="healthcares_divnewadd">
            <div className="healthcares_div_left">
              <div className="">
                <h4 className="hlthcaretetx_heading_text">Machine Learning Models</h4>
                <p className="hlthcaretetx_para_text">
                  Our team creates and implements advanced ML models that analyze data, automate tasks, improve decision-making, and provide predictive insights for business success.
                </p>
              </div>
            </div>
            <div className="healthcares_div_right">
              <div className="healthcares_div_imgmainalada">
                <img src={Machinelearning} alt="..." />
              </div>
            </div>
          </div>

          <div className="healthcares_divnewadd">
            <div className="healthcares_div_left">
              <div className="">
                <h4 className="hlthcaretetx_heading_text">Natural Language Processing (NLP)</h4>
                <p className="hlthcaretetx_para_text">
                  We deploy NLP technologies to enhance human-machine interactions, automate communication processes, and improve customer engagement through accurate language understanding and responses.
                </p>
              </div>
            </div>
            <div className="healthcares_div_right">
              <div className="healthcares_div_imgmainalada">
                <img src={nlp} alt="..." />
              </div>
            </div>
          </div>

          <div className="healthcares_divnewadd">
            <div className="healthcares_div_left">
              <div className="">
                <h4 className="hlthcaretetx_heading_text">AI Integration</h4>
                <p className="hlthcaretetx_para_text">
                  Seamlessly integrate AI and ML solutions into your infrastructure for improved performance and significant organizational value. Our expertise bridges human intelligence with machine capabilities to offer innovative solutions that boost efficiency and user experience.

                </p>
              </div>
            </div>
            <div className="healthcares_div_right">
              <div className="healthcares_div_imgmainalada">
                <img src={consulting} alt="..." />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="softwaresevices_main">
        <div className="cus_container">
          <div className>
       
            <p className="hlthcaretetx_para_text">With Cygnus, experience the synergy of human cognition and computational power, unlocking the potential of AI and ML for your business transformation.</p>
          </div>
        </div>
      </section>

    </>
  )
}

export default Aimlmaincontent
