import React from 'react';
import img1 from "../../Images/Cygnus Pictures/Homepage/experinence.png";
import img2 from "../../Images/Cygnus Pictures/file (1).png";
import img3 from "../../Images/Cygnus Pictures/WhatsApp Image 2024-07-04 at 15.48.30_9c3e438c-Photoroom.png";
import img4 from "../../Images/Cygnus Pictures/file (2).png";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import { Link } from 'react-router-dom';
import experince from "../../Images/Cygnus Pictures/Homepage/experince.png";
import innovationnnn from "../../Images/Cygnus Pictures/Homepage/innovationnnn.png";
import achievementtt from "../../Images/Cygnus Pictures/Homepage/achievementtt.png";
import awardsimg from "../../Images/Cygnus Pictures/Homepage/awardsimg.png";
import img11 from "../../Images/Cygnus Pictures/img1.png";
import img22 from "../../Images/Cygnus Pictures/img2.png";
import img33 from "../../Images/Cygnus Pictures/img3.jpeg";
import img44 from "../../Images/Cygnus Pictures/img4.jpeg";
import img55 from "../../Images/Cygnus Pictures/img5.jpeg";
import img66 from "../../Images/wipro.jpeg";
import img77 from "../../Images/capgemini.jpeg";
import img88 from "../../Images/hcl.jpeg";
import img99 from "../../Images/bmw.jpeg";
import certificationslogoimg from "../../Images/certificationslogoimg.jpeg"
const OurExperienceSlider = () => {

    return (
        <>
            <section className='OurExperienceSlider_sec'>
                <div className='cus_container'>








                    <div className='experenceallbgimg' id='experince'>
                        <div className='experenceallbgimgmain'>
                            <img src={experince} alt='' />

                            <div className='maincontentwrap'>
                                <h2 className='sec_headingexpertxtpp'>Our Experience</h2>
                                <p className='sec_desctextppecp'>At Cygnus , we believe that adaptability is key to excellence. Our commitment to tailor our techniques to the unique needs of each client has driven our success across various sectors. With a wealth of experience in general IT services, advanced software development, and specialized industrial solutions, we bring unparalleled expertise and dedication to every project we undertake.</p>

                                <div className='row progsecaera'>
                                    <div className='col-lg-6 col-md-6 col-6 marfbf-22 '>
                                        <h4 className='progressName'>Software Services</h4>
                                        <div className="progress">
                                            <div
                                                className="progress-bar"
                                                role="progressbar"
                                                style={{ width: "70%" }}
                                                aria-valuenow={70}
                                                aria-valuemin={0}
                                                aria-valuemax={100}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-6  marfbf-22'>
                                        <h4 className='progressName'>Staffing Solutions</h4>
                                        <div className="progress">
                                            <div
                                                className="progress-bar"
                                                role="progressbar"
                                                style={{ width: "80%" }}
                                                aria-valuenow={80}
                                                aria-valuemin={0}
                                                aria-valuemax={100}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-6 marfbf-22 '>
                                        <h4 className='progressName'>Consulting Services</h4>
                                        <div className="progress">
                                            <div
                                                className="progress-bar"
                                                role="progressbar"
                                                style={{ width: "40%" }}
                                                aria-valuenow={40}
                                                aria-valuemin={0}
                                                aria-valuemax={100}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-6  marfbf-22'>
                                        <h4 className='progressName'>Business Solutions</h4>
                                        <div className="progress">
                                            <div
                                                className="progress-bar"
                                                role="progressbar"
                                                style={{ width: "90%" }}
                                                aria-valuenow={90}
                                                aria-valuemin={0}
                                                aria-valuemax={100}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/* <div className='certtsiisfoimdivflx'>

                                    <div className='certtsiisfoimdivimgmain'>
                                        <img src={img66} alt='...' />
                                    </div>


                                    <div className='certtsiisfoimdivimgmain'>
                                        <img src={img77} alt='...' />
                                    </div>

                                    <div className='certtsiisfoimdivimgmain'>
                                        <img src={img88} alt='...' />
                                    </div>


                                    <div className='certtsiisfoimdivimgmain'>
                                    <img src={img99} alt='...' />

                                    </div>
                                 
                                </div> */}

                            </div>
                        </div>

                    </div>


                    <div className='experenceallbgimg' id='innovstion'>
                        <div className='experenceallbgimgmain'>
                            <img src={innovationnnn} alt='' />
                            <div className='maincontentwrap'>
                                <h2 className='sec_headingexpertxtpp'>Innovation</h2>
                                <p className='sec_desctextppecp'>We stay at the forefront of technology, consistently adopting the latest innovations and best practices to provide cutting-edge solutions. Sustainability across all industries and geographies we bring deep, functional expertise and capture silos across boundaries
                                    Our commitment to innovation drives our work in automation and software services, ensuring that our clients stay ahead of the curve. Our automated solutions are designed to streamline processes, reduce manual intervention, and increase efficiency across various sectors.
                                </p>
                                {/* <Link className='exploreMore_btn' to="/innovation">Explore More</Link> */}
                                <div className='row progsecaera'>
                                    <div className='col-lg-6 col-md-6 col-6 marfbf-22'>
                                        <h4 className='progressName'>Digital Transformation</h4>

                                    </div>
                                    <div className='col-lg-6 col-md-6 col-6 marfbf-22'>
                                        <h4 className='progressName'>Catalysts for Growth</h4>

                                    </div>
                                    <div className='col-lg-6 col-md-6 col-6 marfbf-22'>
                                        <h4 className='progressName'>Business Intelligence</h4>

                                    </div>
                                    <div className='col-lg-6 col-md-6 col-6 marfbf-22'>
                                        <h4 className='progressName'>Accelerated Innovation</h4>

                                    </div>
                                </div>

                                {/* <div className='certtsiisfoimdivflx'>

                                    <div className='certtsiisfoimdivimgmain'>
                                        <img src={img11} alt='...' />
                                    </div>


                                    <div className='certtsiisfoimdivimgmain'>
                                        <img src={img22} alt='...' />
                                    </div>

                                    <div className='certtsiisfoimdivimgmain'>
                                        <img src={img33} alt='...' />
                                    </div>


                                    <div className='certtsiisfoimdivimgmain'>
                                        <img src={img44} alt='...' />

                                    </div>
                                    <div className='certtsiisfoimdivimgmain'>
                                        <img src={img55} alt='...' />
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>


                    <div className='experenceallbgimg' id="achievement">
                        <div className='experenceallbgimgmain'>
                            <img src={achievementtt} alt='' />
                            <div className='maincontentwrap'>
                                <h2 className='sec_headingexpertxtpp'>Achievements</h2>
                                <p className='sec_desctextppecp'>Our journey is marked by a relentless pursuit of excellence and innovation, reflected in our key achievements. We prioritize efficiency and innovation in every project, ensuring that our digital transformation efforts emphasize utmost commitment. Our collaborative approach allows us to thoroughly understand the unique challenges of your business before diving into research and ideating sustainable solutions. Our role as catalysts for growth is anchored in mutual success through trusted partnerships. With extensive expertise in cutting-edge technologies, we generate critical insights that drive our ability to deliver high-impact results. We are committed to driving progress for our clients through our specialized consulting services. By maximizing the potential of their businesses, we empower them to uncover valuable insights from underlying patterns.</p>

                                {/* <Link className='exploreMore_btn' to="/achievement">Explore More</Link> */}

                            </div>
                        </div>
                    </div>

                    <div className='experenceallbgimg' id="award">
                        <div className='experenceallbgimgmain'>
                            <img src={awardsimg} alt='' />
                            <div className='maincontentwrap'>
                                <h2 className='sec_headingexpertxtpp'>Certification</h2>
                                <p className='sec_desctextppecp'>Implement an AI-driven inventory management system that uses machine learning algorithms to predict stock levels, optimize reorder points, and automate purchasing processes</p>

                                {/* <button className='exploreMore_btn'>Explore More</button> */}
                                <div className='certtsiisfoimdivflx'>

                                    <div className='certtsiisfoimdivimgmain'>
                                        <img src={certificationslogoimg} alt='...' />
                                    </div>

                                    <div className='certtsiisfoimdivimgmain'>
                                        <img src={img11} alt='...' />
                                    </div>


                                    <div className='certtsiisfoimdivimgmain'>
                                        <img src={img22} alt='...' />
                                    </div>

                                    <div className='certtsiisfoimdivimgmain'>
                                        <img src={img33} alt='...' />
                                    </div>


                                    <div className='certtsiisfoimdivimgmain'>
                                        <img src={img44} alt='...' />

                                    </div>
                                    <div className='certtsiisfoimdivimgmain'>
                                        <img src={img55} alt='...' />
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>




                    {/* <Link className='OurExperience_Slider' style={{ backgroundColor: "#8e2d8e" }} to="/innovation"  >
                        <div className='content_wrap'>
                            <h2 className='sec_heading'>Innovation</h2>
                            <p className='sec_desctextppecp'>We believe that adaptability prompts to excellence and determined to techniques that carefully stick to imperatives of each client.</p>

                            <button className='exploreMore_btn'>Explore More</button>


                            <div className='row progsecaera'>
                                <div className='col-lg-6 col-md-12 col-12 mb-4'>
                                    <h4 className='progressName'>Digital Transformation</h4>
                                 
                                </div>
                                <div className='col-lg-6 col-md-12 col-12 mb-4'>
                                    <h4 className='progressName'>Catalysts for Growth</h4>
                                 
                                </div>
                                <div className='col-lg-6 col-md-12 col-12 mb-4'>
                                    <h4 className='progressName'>Business Intelligence</h4>
                             
                                </div>
                                <div className='col-lg-6 col-md-12 col-12 mb-4'>
                                    <h4 className='progressName'>Accelerated Innovation</h4>
                        
                                </div>
                            </div>
                        </div>

                        <div className='imagewrap_boxmain'>
                            <div className='imagewrap_box'>
                                <img src={img2} className='img-fluid' alt='...' />
                            </div>
                        </div>
                    </Link> */}




                    {/* <Link className='OurExperience_Slider' style={{ backgroundColor: "#ab6503" }} to="/achievement" >
                        <div className='content_wrap'>
                            <h2 className='sec_heading'>Achievements</h2>
                            <p className='sec_desctextppecp'>Implement an AI-driven inventory management system that uses machine learning algorithms to predict stock levels, optimize reorder points, and automate purchasing processes</p>

                            <button className='exploreMore_btn'>Explore More</button>


                        </div>

                        <div className='imagewrap_boxmain'>
                            <div className='imagewrap_box'>
                                <img src={img3} className='img-fluid' alt='...' />
                            </div>
                        </div>
                    </Link> */}




                    {/* <Link className='OurExperience_Slider' style={{ backgroundColor: "#26a98a" }} to="/awards" >
                        <div className='content_wrap'>
                            <h2 className='sec_heading'>Awards</h2>
                            <p className='sec_desctextppecp'>Implement an AI-driven inventory management system that uses machine learning algorithms to predict stock levels, optimize reorder points, and automate purchasing processes</p>

                            <button className='exploreMore_btn'>Explore More</button>


                        </div>

                        <div className='imagewrap_boxmain'>
                            <div className='imagewrap_box'>
                                <img src={img4} className='img-fluid' alt='...' />
                            </div>
                        </div>
                    </Link> */}




                    {/* <div className='listuldivmainkkk'>
                        <ul className='listuldivmainkkkul'>
                            <li className='listuldivmainkkkli'>
                                <a href='#experince' className='listuldivmainkkkliaaa'></a>
                            </li>
                            <li className='listuldivmainkkkli'>
                                <a href='#innovstion' className='listuldivmainkkkliaaa'></a>
                            </li>
                            <li className='listuldivmainkkkli'>
                                <a href='#achievement' className='listuldivmainkkkliaaa'></a>
                            </li>
                            <li className='listuldivmainkkkli'>
                                <a href='#award' className='listuldivmainkkkliaaa'></a>
                            </li>
                        </ul>
                    </div> */}




                </div>
            </section>
        </>
    )
}

export default OurExperienceSlider