import React from 'react'
import founder from "../../Images/Cygnus Pictures/Our team/founder.png"
const OurFounder = () => {
    return (
        <>
            <section className='ourfounder'>
                <div className='cus_container'>
                    <div className='ourfounderdivflx'>
                        <div className='founderimgdivmain'>
                            <p className='foundertxttpp'>
                                Our Founder
                            </p>
                            <div className='founderimgdiv'>

                                <img src={founder} alt='...' />
                            </div>
                            <p className='Vemtetxtppp'>
                                Surya Vempati
                            </p>
                            <p className='founndtethhtstp'>
                                Founder & President
                            </p>
                        </div>

                        <div className='foundertext'>
                            <p className='foundertextpp'>
                                Our idea of conceiving this company is to solve the complex hurdles in the industry with comprehensive solutions and to provide high satisfaction to clients, is what we aspire to do in a broad, enduring sense. I have always believed that when it comes to defining and communicating a corporate vision and goals, conciseness is a virtue.  This purpose has been evident since the establishment of our corporation when our roots set out to build a premium quality service company. I borrow this opportunity to promise the rebirth as a company. We’re always on a path of static improvement that carries out.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default OurFounder
