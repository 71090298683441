import React from 'react'
import img1 from "../../Images/Cygnus Pictures/Industrial Solutions/azzedine-rouichi-VMB8B-5PeR4-unsplash.jpg";
const PrescientMaintainace = () => {
    return (
        <section className="healthcare_main" id='LogisticsTransportation'>
            <div className="cus_container">
                <div className="healthcares_div">
                    <div className="healthcares_div_left">
                        <div className="">
                            <h4 className="hlthcaretetx_heading_text">Prescient Maintainace</h4>
                            <p className="hlthcaretetx_para_text">
                                Prescient support targets using information from your machine to fabricate prescient models for decreasing expenses and mileage on hardware. With AI, we can assist your office with understanding the ideal chance to plan upkeep and purchase spare parts.
                            </p>
                        </div>
                    </div>
                    <div className="healthcares_div_right">
                        <div className="healthcares_div_img">
                            <img src={img1} alt="..." />
                        </div>
                    </div>
                </div>
                {/* <div className="healthcareswipper">

            <Swiper
                slidesPerView={3}
                spaceBetween={30}
                navigation={true}
                breakpoints={{
                    0: {
                        slidesPerView: 1,
                    },
                    400: {
                        slidesPerView: 2,
                    },
                    639: {
                        slidesPerView: 3,
                    },
                }}
                modules={[Navigation]}
                className='mySwiper'
            >
                <SwiperSlide className="">
                    <div className="healthcare_divsld_img">
                        <img src={img2} alt="..." />
                    </div>
                    <div className="">
                        <p className="slide_text_dtls_pp">
                            Dealer Management & Delivery
                        </p>
                    </div>
                </SwiperSlide>
                <SwiperSlide className="">
                    <div className="healthcare_divsld_img">
                        <img src={img3} alt="..." />
                    </div>
                    <div className="">
                        <p className="slide_text_dtls_pp">
                            Warehouse Management
                        </p>
                    </div>
                </SwiperSlide>
                <SwiperSlide className="">
                    <div className="healthcare_divsld_img">
                        <img src={img4} alt="..." />
                    </div>
                    <div className="">
                        <p className="slide_text_dtls_pp">
                            Transportation Analytics & Data Integration
                        </p>
                    </div>
                </SwiperSlide>
                <SwiperSlide className="">
                    <div className="healthcare_divsld_img">
                        <img src={img3} alt="..." />
                    </div>
                    <div className="">
                        <p className="slide_text_dtls_pp">
                            Warehouse Management
                        </p>
                    </div>
                </SwiperSlide>
                <SwiperSlide className="">
                    <div className="healthcare_divsld_img">
                        <img src={img4} alt="..." />
                    </div>
                    <div className="">
                        <p className="slide_text_dtls_pp">
                            Transportation Analytics & Data Integration
                        </p>
                    </div>
                </SwiperSlide>
                <SwiperSlide className="">
                    <div className="healthcare_divsld_img">
                        <img src={img3} alt="..." />
                    </div>
                    <div className="">
                        <p className="slide_text_dtls_pp">
                            Warehouse Management
                        </p>
                    </div>
                </SwiperSlide>
                <SwiperSlide className="">
                    <div className="healthcare_divsld_img">
                        <img src={img4} alt="..." />
                    </div>
                    <div className="">
                        <p className="slide_text_dtls_pp">
                            Transportation Analytics & Data Integration
                        </p>
                    </div>
                </SwiperSlide>
            </Swiper>
        </div> */}
            </div>
        </section>
    )
}

export default PrescientMaintainace
