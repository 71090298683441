import React from 'react'
import Cloudtechnologiesmain from './Cloudtechnologiesmain'
import Cloudtechnologiescontent from './Cloudtechnologiescontent'
import Cloudtechnologiesmaincontent from './Cloudtechnologiesmaincontent'

const Cloudtechnologies = () => {
    return (
        <>
            <Cloudtechnologiesmain />
            <Cloudtechnologiescontent />
            <Cloudtechnologiesmaincontent />
        </>
    )
}

export default Cloudtechnologies
