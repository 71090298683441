import React from 'react'
import img1 from "../../Images/Cygnus Pictures/Industrial Solutions/jakub-zerdzicki-ykgLX_CwtDw-unsplash.jpg";

const AdvancedAnalytics = () => {
  return (
    <section className="healthcare_main" id='LogisticsTransportation'>
    <div className="cus_container">
        <div className="healthcares_div">
            <div className="healthcares_div_left">
                <div className="">
                    <h4 className="hlthcaretetx_heading_text">Advanced Analytics</h4>
                    <p className="hlthcaretetx_para_text">
                    Influence Polestar’s mastery in the assembling business to make winning systems, for example, execute prescient upkeep by examining the authentic exhibition information of creation units and hardware to foresee and expect disappointments. Enhance production network with cutting edge demonstrating of thousands of boundaries and conditions that affect the absolute productivity. Diminish the quantity of tests needed for quality confirmation utilizing huge information arrangement with AI ability.
                    </p>
                </div>
            </div>
            <div className="healthcares_div_right">
                <div className="healthcares_div_img">
                    <img src={img1} alt="..." />
                </div>
            </div>
        </div>
        {/* <div className="healthcareswipper">

            <Swiper
                slidesPerView={3}
                spaceBetween={30}
                navigation={true}
                breakpoints={{
                    0: {
                        slidesPerView: 1,
                    },
                    400: {
                        slidesPerView: 2,
                    },
                    639: {
                        slidesPerView: 3,
                    },
                }}
                modules={[Navigation]}
                className='mySwiper'
            >
                <SwiperSlide className="">
                    <div className="healthcare_divsld_img">
                        <img src={img2} alt="..." />
                    </div>
                    <div className="">
                        <p className="slide_text_dtls_pp">
                            Dealer Management & Delivery
                        </p>
                    </div>
                </SwiperSlide>
                <SwiperSlide className="">
                    <div className="healthcare_divsld_img">
                        <img src={img3} alt="..." />
                    </div>
                    <div className="">
                        <p className="slide_text_dtls_pp">
                            Warehouse Management
                        </p>
                    </div>
                </SwiperSlide>
                <SwiperSlide className="">
                    <div className="healthcare_divsld_img">
                        <img src={img4} alt="..." />
                    </div>
                    <div className="">
                        <p className="slide_text_dtls_pp">
                            Transportation Analytics & Data Integration
                        </p>
                    </div>
                </SwiperSlide>
                <SwiperSlide className="">
                    <div className="healthcare_divsld_img">
                        <img src={img3} alt="..." />
                    </div>
                    <div className="">
                        <p className="slide_text_dtls_pp">
                            Warehouse Management
                        </p>
                    </div>
                </SwiperSlide>
                <SwiperSlide className="">
                    <div className="healthcare_divsld_img">
                        <img src={img4} alt="..." />
                    </div>
                    <div className="">
                        <p className="slide_text_dtls_pp">
                            Transportation Analytics & Data Integration
                        </p>
                    </div>
                </SwiperSlide>
                <SwiperSlide className="">
                    <div className="healthcare_divsld_img">
                        <img src={img3} alt="..." />
                    </div>
                    <div className="">
                        <p className="slide_text_dtls_pp">
                            Warehouse Management
                        </p>
                    </div>
                </SwiperSlide>
                <SwiperSlide className="">
                    <div className="healthcare_divsld_img">
                        <img src={img4} alt="..." />
                    </div>
                    <div className="">
                        <p className="slide_text_dtls_pp">
                            Transportation Analytics & Data Integration
                        </p>
                    </div>
                </SwiperSlide>
            </Swiper>
        </div> */}
    </div>
</section>
  )
}

export default AdvancedAnalytics
