import React from 'react'
import ongoingsarch from "../../Images/ongoingsarch.jpg";
import StrategicTalent from "../../Images/StrategicTalent.jpg";
import discover from "../../Images/discover.jpg";
import RigorousEvaluation from "../../Images/RigorousEvaluation.jpg";
import Confidential from "../../Images/Confidential.jpg"
const Excutivesearchcontentmain = () => {
  return (
      <>
          <section className="softwaresevices_main" >
        <div className="cus_container">
          <div className="healthcares_divnewadd">
            <div className="healthcares_div_left">
              <div className="">
                <h4 className="hlthcaretetx_heading_text"> Strategic Talent Acquisition</h4>
                <p className="hlthcaretetx_para_text">
                We leverage our extensive network and industry expertise to find leaders who can drive your business forward and achieve long-term growth.
                </p>
              </div>
            </div>
            <div className="healthcares_div_right">
              <div className="healthcares_div_imgmainalada">
                <img src={StrategicTalent} alt="..." />
              </div>
            </div>
          </div>

          <div className="healthcares_divnewadd">
            <div className="healthcares_div_left">
              <div className="">
                <h4 className="hlthcaretetx_heading_text"> Tailored Search Process</h4>
                <p className="hlthcaretetx_para_text">
                Our process is customized to meet your unique needs, ensuring a perfect fit between leadership requirements and company culture.
                </p>
              </div>
            </div>
            <div className="healthcares_div_right">
              <div className="healthcares_div_imgmainalada">
                <img src={discover} alt="..." />
              </div>
            </div>
          </div>

          <div className="healthcares_divnewadd">
            <div className="healthcares_div_left">
              <div className="">
                <h4 className="hlthcaretetx_heading_text">Rigorous Evaluation</h4>
                <p className="hlthcaretetx_para_text">
                We conduct thorough evaluations, including in-depth interviews, background checks, and reference verifications, to present only the most qualified leaders.
                </p>
              </div>
            </div>
            <div className="healthcares_div_right">
              <div className="healthcares_div_imgmainalada">
                <img src={RigorousEvaluation} alt="..." />
              </div>
            </div>
          </div>

          <div className="healthcares_divnewadd">
            <div className="healthcares_div_left">
              <div className="">
                <h4 className="hlthcaretetx_heading_text"> Confidential and Discreet</h4>
                <p className="hlthcaretetx_para_text">
                Our approach is confidential and discreet to ensure that the search process remains secure and undisturbed for your current leadership team.
                </p>
              </div>
            </div>
            <div className="healthcares_div_right">
              <div className="healthcares_div_imgmainalada">
                <img src={Confidential} alt="..." />
              </div>
            </div>
          </div>

          <div className="healthcares_divnewadd">
            <div className="healthcares_div_left">
              <div className="">
                <h4 className="hlthcaretetx_heading_text"> Ongoing Support</h4>
                <p className="hlthcaretetx_para_text">
                Our commitment continues post-placement, providing support for a smooth transition and successful integration into your organization.
                </p>
              </div>
            </div>
            <div className="healthcares_div_right">
              <div className="healthcares_div_imgmainalada">
                <img src={ongoingsarch} alt="..." />
              </div>
            </div>
          </div>

         

        </div>
      </section>
      </>
  )
}

export default Excutivesearchcontentmain
