import React from 'react'
import retailasoftware from "../../Images/retailasoftware.jpg";
import ecommerceintegration from "../../Images/ecommerceintegration.jpg";
import pos from "../../Images/pos.jpg";
import sorfraerrttrstysenfgyy from "../../Images/sorfraerrttrstysenfgyy.jpg";
import uiusyhystetdtesuppiort from "../../Images/uiusyhystetdtesuppiort.jpg";
import ertysyyuyyy from "../../Images/ertysyyuyyy.jpg";
import sippriisolution from "../../Images/sippriisolution.jpg"
const Retailcontentmain = () => {
  return (
    <>
      <section className="softwaresevices_main" >
        <div className="cus_container">
          <div className="healthcares_divnewadd">
            <div className="healthcares_div_left">
              <div className="">
                <h4 className="hlthcaretetx_heading_text">Retail Software Services</h4>
                <p className="hlthcaretetx_para_text">
                  Our bespoke retail software solutions are crafted to meet the specific needs of your business. By leveraging advanced technologies, we streamline operations, improve inventory management, and enhance overall efficiency, empowering you to focus on delivering outstanding customer experiences.
                </p>
              </div>
            </div>
            <div className="healthcares_div_right">
              <div className="healthcares_div_img">
                <img src={retailasoftware} alt="..." />
              </div>
            </div>
          </div>

          <div className="healthcares_divnewadd">
            <div className="healthcares_div_left">
              <div className="">
                <h4 className="hlthcaretetx_heading_text">E-Commerce Integration</h4>
                <p className="hlthcaretetx_para_text">
                  In an increasingly digital world, seamless e-commerce integration is essential for success. We provide comprehensive solutions that connect your online and offline channels, ensuring a cohesive customer journey. From platform selection to implementation, we guide you every step of the way.
                </p>
              </div>
            </div>
            <div className="healthcares_div_right">
              <div className="healthcares_div_img">
                <img src={ecommerceintegration} alt="..." />
              </div>
            </div>
          </div>

          <div className="healthcares_divnewadd">
            <div className="healthcares_div_left">
              <div className="">
                <h4 className="hlthcaretetx_heading_text">POS Systems</h4>
                <p className="hlthcaretetx_para_text">
                  Our cutting-edge Point of Sale (POS) systems are designed to elevate the customer experience while simplifying transactions. We offer tailored solutions that enhance payment processing, inventory tracking, and sales analytics, enabling you to make informed business decisions with ease
                </p>
              </div>
            </div>
            <div className="healthcares_div_right">
              <div className="healthcares_div_img">
                <img src={pos} alt="..." />
              </div>
            </div>
          </div>

          <div className="healthcares_divnewadd">
            <div className="healthcares_div_left">
              <div className="">
                <h4 className="hlthcaretetx_heading_text">Software Architecture, Consulting, and Development</h4>
                <p className="hlthcaretetx_para_text">
                  With a focus on scalability and robustness, our software architecture services ensure that your systems can grow with your business. Our expert consultants work closely with you to design and develop custom solutions that align with your strategic objectives and operational needs.
                </p>
              </div>
            </div>
            <div className="healthcares_div_right">
              <div className="healthcares_div_img">
                <img src={sorfraerrttrstysenfgyy} alt="..." />
              </div>
            </div>
          </div>

          <div className="healthcares_divnewadd">
            <div className="healthcares_div_left">
              <div className="">
                <h4 className="hlthcaretetx_heading_text">UI/UX Design, Testing, and Support</h4>
                <p className="hlthcaretetx_para_text">
                  User experience is paramount in retail. Our dedicated UI/UX services prioritize intuitive design, ensuring that your applications and websites offer a seamless and engaging customer experience. We conduct thorough testing and provide ongoing support to keep your systems running smoothly.
                </p>
              </div>
            </div>
            <div className="healthcares_div_right">
              <div className="healthcares_div_img">
                <img src={uiusyhystetdtesuppiort} alt="..." />
              </div>
            </div>
          </div>

          <div className="healthcares_divnewadd">
            <div className="healthcares_div_left">
              <div className="">
                <h4 className="hlthcaretetx_heading_text">ERP - Enterprise Resource Planning</h4>
                <p className="hlthcaretetx_para_text">
                  Implementing an effective ERP system is vital for managing resources and streamlining processes. Our ERP solutions integrate various functions, allowing for real-time data access and increased collaboration across departments. We work diligently to customize and implement ERP systems that drive efficiency in your organization.
                </p>
              </div>
            </div>
            <div className="healthcares_div_right">
              <div className="healthcares_div_img">
                <img src={ertysyyuyyy} alt="..." />
              </div>
            </div>
          </div>



        </div>
      </section>



      <section className="softwaresevices_main" >
        <div className="cus_container">
          <div className='choosedivflx'>
            <div className='choosedivleft'>
              <h4 className="hlthcaretetx_heading_text">Why Choose Cygnus Professionals?</h4>
              <p className="hlthcaretetx_para_text_new">
                <span> 	Tailored Solutions : </span> We understand that each organization has its own unique challenges. By taking the time to connect with you and comprehend your specific needs, we develop customized strategies that deliver measurable results.
              </p>

              <p className="hlthcaretetx_para_text_new">
                <span>		Deep Industry Expertise : </span> Our team comprises seasoned professionals with extensive experience in the retail sector. This wealth of knowledge allows us to provide insightful guidance and innovative solutions that keep you ahead of the competition.
              </p>


              <p className="hlthcaretetx_para_text_new">
                <span> 	Comprehensive Support :  </span> From initial consultation through to implementation and ongoing maintenance, we offer a fully integrated approach. Our commitment to your success means we are with you at every stage of your journey.
              </p>


              <p className="hlthcaretetx_para_text_new">
                <span> Quality-Driven Results : </span> Our dedication to excellence ensures that we prioritize quality in all our services. You can trust us to deliver outcomes that not only meet but exceed your expectations.
              </p>

            </div>
            <div className='choosedivright'>
              <div className='choosedivrightimgmain'>
                <img src={sippriisolution} alt='...' />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="softwaresevices_main" >
        <div className="cus_container">
          <div className=''>
            <h4 className="hlthcaretetx_heading_text">Partner with Us</h4>
            <p className="hlthcaretetx_para_text">
              Join the growing number of retail organizations that have transformed their operations with Cygnus Professionals. Together, let us navigate the rapidly changing retail landscape and drive your business toward success.
              Contact us today to discover how we can support you in achieving your retail objectives.


            </p>
          </div>
        </div>
      </section>

    </>
  )
}

export default Retailcontentmain
