import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import johnandjhons from "../../Images/johnandjhons.png";
import mayoclinick from "../../Images/mayoclinick.png";
import google from "../../Images/google.png";
import bandn from "../../Images/bandn.png";
import bmw from "../../Images/bmw.jpeg";
import shaw from "../../Images/shaw.png";
import cdw from "../../Images/cdw.png";
import commure from "../../Images/commure.png";
import goldasnsachlogo from "../../Images/goldasnsachlogo.jpeg";
import schndr from "../../Images/schndr.png";
import rallauren from "../../Images/rallauren.png";
import harman from "../../Images/harman.png"
const ClientSays = () => {
    return (
        <section className="clientsayasmain">
            <div className='cus_container'>
                <div className='clientsayasmaincontentdiv'>
                    <div className=''>
                        <h4 class="carrertxtteamhhh">Client Success Testimonials </h4>
                    </div>

                    <div className=''>
                        <div className="">

                            <Swiper
                                slidesPerView={1}
                                spaceBetween={30}
                                navigation={true}
                                loop={true}
                                modules={[Navigation]}
                                className="mySwiper"
                            >

                                <SwiperSlide >
                                    <div className='employeuimgtextdivflxsld'>


                                        <div className='climetsaysyimgdivmain'>
                                            <div className='climetsaysyimgdivbgimhhj'>
                                                <div className='climetsaysyimgdiv'>
                                                    <img src={google} alt='...' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='clinetsttsaytsxtdiv'>
                                            <p className='clinetsttsaytsxtdivpp'>
                                                "Our experience with CYGNUS has been nothing short of exceptional. We were in urgent need of highly skilled software developers to complete a critical project, and the team at CYGNUS delivered beyond our expectations. They quickly understood our technical requirements and provided us with a shortlist of candidates who not only had the expertise we needed but also fit seamlessly into our company culture.”
                                            </p>
                                            <p className='shtrittiyadaytxtpp'>
                                                Google
                                            </p>

                                        </div>

                                    </div>
                                </SwiperSlide>

                                <SwiperSlide >
                                    <div className='employeuimgtextdivflxsld'>


                                        <div className='climetsaysyimgdivmain'>
                                            <div className='climetsaysyimgdivbgimhhj'>
                                                <div className='climetsaysyimgdiv'>
                                                    <img src={bmw} alt='...' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='clinetsttsaytsxtdiv'>
                                            <p className='clinetsttsaytsxtdivpp'>
                                                "Working with Cygnus, for our engineering needs has been a game-changer for our organization. From the outset, their team demonstrated a deep understanding of our project requirements and provided innovative solutions that significantly improved our product's design and functionality.
                                                Their attention to detail and commitment to quality were evident throughout the project. Cygnus Professionals, consistently delivered on time and within budget, which was critical for our tight deadlines. The engineers were not only technically proficient but also proactive in identifying potential challenges and offering practical solutions, which helped us avoid costly delays.
                                                The level of expertise and professionalism we experienced with Cygnus Professionals, has made them our go-to partner for all future engineering projects. I highly recommend their services to any company looking for top-notch engineering support."

                                            </p>
                                            <p className='shtrittiyadaytxtpp'>
                                                BMW
                                            </p>
                                            {/* <p  className='shtrittiyadaytxtppdesgtxtp'> 
                                           Front End Developer
                                        </p> */}
                                        </div>

                                    </div>
                                </SwiperSlide>

                                <SwiperSlide >
                                    <div className='employeuimgtextdivflxsld'>


                                        <div className='climetsaysyimgdivmain'>
                                            <div className='climetsaysyimgdivbgimhhj'>
                                                <div className='climetsaysyimgdiv'>
                                                    <img src={cdw} alt='...' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='clinetsttsaytsxtdiv'>
                                            <p className='clinetsttsaytsxtdivpp'>
                                                "The team for CygnusPro Software Solutions has been instrumental in supporting our staffing initiatives at CDW. Their expertise in identifying the right talent has streamlined our recruitment process and improved our overall effectiveness. We've seen significant enhancements in team performance and productivity. Their dedicated support truly makes them a valued partner in our growth."
                                            </p>
                                            <p className='shtrittiyadaytxtpp'>
                                                CDW
                                            </p>

                                        </div>

                                    </div>
                                </SwiperSlide>

                                
                                <SwiperSlide >
                                    <div className='employeuimgtextdivflxsld'>


                                        <div className='climetsaysyimgdivmain'>
                                            <div className='climetsaysyimgdivbgimhhj'>
                                                <div className='climetsaysyimgdiv'>
                                                    <img src={shaw} alt='...' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='clinetsttsaytsxtdiv'>
                                            <p className='clinetsttsaytsxtdivpp'>
                                                "Engaging with CygnusPro has significantly improved our operational efficiency at Shaw Industries. Their tailored solutions and expert guidance streamlined our processes and helped us achieve key objectives. We value their commitment to delivering results and look forward to future projects together."
                                            </p>
                                            <p className='shtrittiyadaytxtpp'>
                                                Shaw Industries
                                            </p>

                                        </div>

                                    </div>
                                </SwiperSlide>

                                <SwiperSlide >
                                    <div className='employeuimgtextdivflxsld'>


                                        <div className='climetsaysyimgdivmain'>
                                            <div className='climetsaysyimgdivbgimhhj'>
                                                <div className='climetsaysyimgdiv'>
                                                    <img src={commure} alt='...' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='clinetsttsaytsxtdiv'>
                                            <p className='clinetsttsaytsxtdivpp'>
                                                "CygnusPro played a crucial role in helping Commure find top-tier Embedded and Bluetooth engineers. Their understanding of our specific technical requirements and swift recruitment process made all the difference. The expertise of our new hires has significantly advanced our projects. We value Cygnus's dedication to delivering tailored solutions that support our growth."
                                            </p>
                                            <p className='shtrittiyadaytxtpp'>
                                                Commure
                                            </p>

                                        </div>

                                    </div>
                                </SwiperSlide>

                                <SwiperSlide >
                                    <div className='employeuimgtextdivflxsld'>


                                        <div className='climetsaysyimgdivmain'>
                                            <div className='climetsaysyimgdivbgimhhj'>
                                                <div className='climetsaysyimgdiv'>
                                                    <img src={schndr} alt='...' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='clinetsttsaytsxtdiv'>
                                            <p className='clinetsttsaytsxtdivpp'>
                                                "CygnusPro has been an exceptional partner for Schneider. Their innovative solutions and deep industry knowledge have greatly enhanced our operational capabilities. We appreciate their commitment to understanding our needs and delivering tailored results. The collaboration has led to significant improvements in our processes, and we look forward to exploring more opportunities together."
                                            </p>
                                            <p className='shtrittiyadaytxtpp'>
                                                Schneider
                                            </p>

                                        </div>

                                    </div>
                                </SwiperSlide>

                                <SwiperSlide >
                                    <div className='employeuimgtextdivflxsld'>
                                        <div className='climetsaysyimgdivmain'>
                                            <div className='climetsaysyimgdivbgimhhj'>
                                                <div className='climetsaysyimgdiv'>
                                                    <img src={bandn} alt='...' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='clinetsttsaytsxtdiv'>
                                            <p className='clinetsttsaytsxtdivpp'>
                                                "Partnering with Cygnus Professionals, for our managed services has been one of the best decisions we’ve made. Their team has consistently delivered exceptional support, ensuring our IT infrastructure runs smoothly and securely. We’re impressed with their professionalism and the level of service they provide. I highly recommend CYGNUS to any organization seeking reliable and efficient managed services"
                                            </p>
                                            <p className='shtrittiyadaytxtpp'>
                                                Barnes & Noble
                                            </p>

                                        </div>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide >
                                    <div className='employeuimgtextdivflxsld'>


                                        <div className='climetsaysyimgdivmain'>
                                            <div className='climetsaysyimgdivbgimhhj'>
                                                <div className='climetsaysyimgdiv'>
                                                    <img src={goldasnsachlogo} alt='...' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='clinetsttsaytsxtdiv'>
                                            <p className='clinetsttsaytsxtdivpp'>
                                                "Our collaboration with the Cygnus Professionals’ team has been instrumental for Goldman Sachs. Their expertise in staffing has helped us secure top talent, enhancing our operational efficiency. We appreciate Cygnus’ dedication to understanding our needs and delivering high-quality candidates. We look forward to continuing this successful partnership."
                                            </p>
                                            <p className='shtrittiyadaytxtpp'>
                                                Goldman Sachs
                                            </p>

                                        </div>

                                    </div>
                                </SwiperSlide>

                                
                                <SwiperSlide >
                                    <div className='employeuimgtextdivflxsld'>


                                        <div className='climetsaysyimgdivmain'>
                                            <div className='climetsaysyimgdivbgimhhj'>
                                                <div className='climetsaysyimgdiv'>
                                                    <img src={harman} alt='...' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='clinetsttsaytsxtdiv'>
                                            <p className='clinetsttsaytsxtdivpp'>
                                                "Cygnus provided clear insights that helped us address key challenges at Harman. Our experience with their team has been very positive. Their approach led to effective solutions and improved our projects. We appreciate their professionalism and look forward to future collaborations."
                                            </p>
                                            <p className='shtrittiyadaytxtpp'>
                                                Harman
                                            </p>

                                        </div>

                                    </div>
                                </SwiperSlide>


                                <SwiperSlide >
                                    <div className='employeuimgtextdivflxsld'>
                                        <div className='climetsaysyimgdivmain'>
                                            <div className='climetsaysyimgdivbgimhhj'>
                                                <div className='climetsaysyimgdiv'>
                                                    <img src={johnandjhons} alt='...' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='clinetsttsaytsxtdiv'>
                                            <p className='clinetsttsaytsxtdivpp'>
                                                Cygnus team has helped us with their high level of commitment, responsibility, integrity, and standards. They have helped me to fulfill some of my very critical roles in Data Science skills. I am impressed with Cygnus’s hiring criteria/screening of candidates along with their ability to act quickly to serve their customers. I would highly recommend them to my other colleagues within the organization
                                            </p>
                                            <p className='shtrittiyadaytxtpp'>
                                                Johnson & Johnson
                                            </p>

                                        </div>
                                    </div>
                                </SwiperSlide>


                                <SwiperSlide >
                                    <div className='employeuimgtextdivflxsld'>


                                        <div className='climetsaysyimgdivmain'>
                                            <div className='climetsaysyimgdivbgimhhj'>
                                                <div className='climetsaysyimgdiv'>
                                                    <img src={mayoclinick} alt='...' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='clinetsttsaytsxtdiv'>
                                            <p className='clinetsttsaytsxtdivpp'>
                                                “Our experience with Cygnus was terrific! We contracted with Cygnus to help us fill in a couple of Senior PMO positions with Pharma experience. After we received quality resumes of pre-screened candidates, the candidates were tested in four key areas of competence using our internal tests. I would highly recommend Cygnus, and we will certainly use them again.”
                                            </p>
                                            <p className='shtrittiyadaytxtpp'>
                                                Mayo Clinic
                                            </p>
                                            {/* <p  className='shtrittiyadaytxtppdesgtxtp'> 
                                           Front End Developer
                                        </p> */}
                                        </div>

                                    </div>
                                </SwiperSlide>

                                <SwiperSlide >
                                    <div className='employeuimgtextdivflxsld'>


                                        <div className='climetsaysyimgdivmain'>
                                            <div className='climetsaysyimgdivbgimhhj'>
                                                <div className='climetsaysyimgdiv'>
                                                    <img src={rallauren} alt='...' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='clinetsttsaytsxtdiv'>
                                            <p className='clinetsttsaytsxtdivpp'>
                                                "Our collaboration with the Cygnus team has greatly helped us at Ralph Lauren. They took the time to understand our challenges and provided effective solutions. We've noticed real improvements in our processes and efficiency. Their professionalism and quick support have made a positive impact. We appreciate their contributions and look forward to working together in the future."
                                            </p>
                                            <p className='shtrittiyadaytxtpp'>
                                                Ralph lauren
                                            </p>

                                        </div>

                                    </div>
                                </SwiperSlide>






                            </Swiper>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default ClientSays
