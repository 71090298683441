import React from 'react'
import Mspprogrammain from './Mspprogrammain'
import Mspprogramcontent from './Mspprogramcontent'
import Mspprogramcontentmain from './Mspprogramcontentmain'

const Mspprogramindex = () => {
  return (
     <>
     <Mspprogrammain/>
     <Mspprogramcontent/>
     <Mspprogramcontentmain/>
     </>
  )
}

export default Mspprogramindex
