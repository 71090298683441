import React from 'react'
import azure from "../../Images/azure.png";
import cloud from "../../Images/cloud.png"
const AzureData = () => {
    return (
        <>
            <section className="softwaresevices_main" >
                <div className="cus_container">
                    <div className="healthcares_div">
                        <div className="healthcares_div_left">
                            <div className="">
                                <h4 className="hlthcaretetx_heading_text">Explore Our Cygnus Solutions for Azure Data Factory</h4>
                                <p className="hlthcaretetx_para_text">
                                    Having extraordinary involvement in building cloud business insight and progressed analytics frameworks for different organizations around the world, we can build up the ideal solutions that fits your requirements.
                                </p>
                                <ol className='listsftwareoldivol'>
                                    <li className='listsftwareoldivli'>
                                        <p className='listsftwareoldivlitxtpp'>
                                            Speed up and improve dynamic solutions for increased operational effectiveness.
                                        </p>
                                    </li>


                                    <li className='listsftwareoldivli'>


                                        <p className='listsftwareoldivlitxtpp'>
                                            Helps you with a Proof of Value project to distinguish market patterns.
                                        </p>


                                    </li>

                                    <li className='listsftwareoldivli'>
                                        <p className='listsftwareoldivlitxtpp'>
                                            Bringing in huge sustainable cost savings with improved Turnaround time and 100 accuracy
                                        </p>
                                    </li>


                                    <li className='listsftwareoldivli'>
                                        <p className='listsftwareoldivlitxtpp'>
                                            Deliver a recognizable client experience while acquiring an upper hand over business rivals
                                        </p>
                                    </li>

                                    <li className='listsftwareoldivli'>
                                        <p className='listsftwareoldivlitxtpp'>
                                            Ensure plausible and steady information without expecting to change of data sources
                                        </p>
                                    </li>
                                </ol>
                            </div>
                        </div>
                        <div className="healthcares_div_right">
                            <div className="healthcares_div_img">
                                <img src={azure} alt="..." />
                            </div>
                        </div>
                    </div>



                </div>
            </section>

            <section className="softwaresevices_main" >
                <div className="cus_container">
                    <div className="healthcares_div">
                        <div className="healthcares_div_left">
                            <div className="">
                                <h4 className="hlthcaretetx_heading_text">Explore Our Solutions for your Challenges</h4>
                                <p className="hlthcaretetx_para_text">
                                    We derive the cloud infrastructure solutions from saving money through automatic scaling or deriving business value from large scale to cloud-native approaches to data processing & analytics.
                                    We @cygnus follow the cloud adoption framework while establishing the prime aspects in the cloud migration.The framework builds a structure on the rubric of people, process, and technology that customers can work with, providing a solid assessment of where they are in their journey to the cloud and actionable programs that get them to where they want to be. The framework encompasses four key themes of cloud adoption:

                                </p>

                            </div>

                        </div>
                        <div className="healthcares_div_right">
                            <div className="healthcares_div_img">
                                <img src={cloud} alt="..." />
                            </div>
                        </div>
                    </div>

                    <div className='softwaredivspclbgdivnewcloud'>
                        <div className="softwaredivspclbgdivfirstleft">
                            <div className="softwaredivspclbgdivfirstleftbg">
                                <h4 className="fullstxttsrrtppp">Tactical - Lift-and-shift: “Moving out of a data center</h4>
                                <p className="fullstxttsrrtpppshrtdexc">	We move workloads from a source environment to a target environment with minor or no modifications or refactoring</p>
                            </div>
                        </div>

                        <div className="softwaredivspclbgdivfirstleft">
                            <div className="softwaredivspclbgdivfirstleftbg">
                                <h4 className="fullstxttsrrtppp">Strategic - Move and improve: “Application Modernization</h4>
                                <p className="fullstxttsrrtpppshrtdexc">Improve each workload for performance, features, cost, or user experience. The move and improve migration is ideal</p>
                            </div>
                        </div>

                        <div className="softwaredivspclbgdivfirstleft">
                            <div className="softwaredivspclbgdivfirstleftbg">
                                <h4 className="fullstxttsrrtppp">Transformational – Refactor, Rip, Replace: “Building in and for the Cloud</h4>
                                <p className="fullstxttsrrtpppshrtdexc">In this migration, you decommission an existing app and completely redesign and rewrite it as a cloud-native app</p>
                            </div>
                        </div>

                    </div>



                </div>
            </section>

            <section className="softwaresevices_main" >
                <div className="cus_container">
                    <div className="healthcares_div">
                        <div className="healthcares_div_left">
                            <div className="">
                                <h4 className="hlthcaretetx_heading_text">Explore Our Solutions for your AWS Services</h4>
                                <p className="hlthcaretetx_para_text">
                                Cygnus AWS services consists of various cloud services that can used in a combined need for your business requirements. Our AWS services list covers the huge catalog of services offered by the AWS ranging from products of the core compute such as AWS Deepracer for machine learning, EC2.
                                </p>
                                <ol className='listsftwareoldivol'>
                                    <li className='listsftwareoldivli'>
                                        <p className='listsftwareoldivlitxtpp'>
                                        	Our AWS Cloud makes it simple and cost-effective to set up, scale, and manage a search solution for your website, application or web application which assists 34+ languages
                                        </p>
                                    </li>


                                    <li className='listsftwareoldivli'>


                                        <p className='listsftwareoldivlitxtpp'>
                                        	Our services are the easiest to operate, secure, scale, analyze and deploy for real time applications
                                        </p>


                                    </li>

                                  

                                    <li className='listsftwareoldivli'>
                                        <p className='listsftwareoldivlitxtpp'>
                                        	We offer the key capabilities to cost effective services with the flexibility of streaming data and timely insights
                                        </p>
                                    </li>
                                </ol>
                            </div>
                        </div>
                        <div className="healthcares_div_right">
                            <div className="healthcares_div_img">
                                <img src={azure} alt="..." />
                            </div>
                        </div>
                    </div>



                </div>
            </section>


            <section className='healthcarepractice'>
                <div className='cus_container'>
                    <div className='healthcarepracticeflx'>
                        <div className='healthcarepracticedibimg'>
                            <div className='healthcarepracticedibimgmain'>
                                <img src={azure} alt="..." />
                            </div>
                            <p className='slide_text_dtls_pp'>Explore Our Cygnus Solutions for Azure Data Factory</p>
                        </div>
                        <div className='healthcarepracticedibimg'>
                            <div className='healthcarepracticedibimgmain'>
                                <img src={cloud} alt="..." />
                            </div>
                            <p className='slide_text_dtls_pp'>Explore Our Solutions for your Challenges</p>
                        </div>
                        <div className='healthcarepracticedibimg'>
                            <div className='healthcarepracticedibimgmain'>
                                <img src={azure} alt="..." />
                            </div>
                            <p className='slide_text_dtls_pp'>Explore Our Solutions for your AWS Services</p>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default AzureData
