import React from 'react'
import Sapandoraclemain from './Sapandoraclemain'
import Sapandoraclecontent from './Sapandoraclecontent'
import Sapandoraclemaincontent from './Sapandoraclemaincontent'

const Sapandoracle = () => {
  return (
      <>
           <Sapandoraclemain />
            <Sapandoraclecontent />
            <Sapandoraclemaincontent />
      </>
  )
}

export default Sapandoracle
