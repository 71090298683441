import React from 'react'
import AutomationEfficiency from "../../Images/AutomationEfficiency.jpg";
import CLOUDTECHNOLOGIESnwe from "../../Images/CLOUDTECHNOLOGIESnwe.jpg";
import CLOUDTECHNOLOGIES from "../../Images/CLOUDTECHNOLOGIES.jpg";
import BusinessIntelligence from "../../Images/BusinessIntelligence.jpg"
const Cloudtechnologiesmaincontent = () => {
  return (
    <>
      <section className="softwaresevices_main" >
        <div className="cus_container">
          <div className="healthcares_divnewadd">
            <div className="healthcares_div_left">
              <div className="">
                <h4 className="hlthcaretetx_heading_text">Data Strategy and Governance</h4>
                <p className="hlthcaretetx_para_text">
                Develop a solid data strategy for accuracy, consistency, and security. Our governance frameworks ensure effective data management and regulatory compliance.
                </p>
              </div>
            </div>
            <div className="healthcares_div_right">
              <div className="healthcares_div_imgmainalada">
                <img src={CLOUDTECHNOLOGIESnwe} alt="..." />
              </div>
            </div>
          </div>

          <div className="healthcares_divnewadd">
            <div className="healthcares_div_left">
              <div className="">
                <h4 className="hlthcaretetx_heading_text">Advanced Analytics</h4>
                <p className="hlthcaretetx_para_text">
                  Uncover valuable insights using predictive and prescriptive analytics techniques to drive business strategies
                </p>
              </div>
            </div>
            <div className="healthcares_div_right">
              <div className="healthcares_div_imgmainalada">
                <img src={CLOUDTECHNOLOGIES} alt="..." />
              </div>
            </div>
          </div>

          <div className="healthcares_divnewadd">
            <div className="healthcares_div_left">
              <div className="">
                <h4 className="hlthcaretetx_heading_text">Business Intelligence Solutions</h4>
                <p className="hlthcaretetx_para_text">
                Empower decision-making with intuitive dashboards and reports from complex data, enabling real-time performance monitoring.
                </p>
              </div>
            </div>
            <div className="healthcares_div_right">
              <div className="healthcares_div_imgmainalada">
                <img src={BusinessIntelligence} alt="..." />
              </div>
            </div>
          </div>

          <div className="healthcares_divnewadd">
            <div className="healthcares_div_left">
              <div className="">
                <h4 className="hlthcaretetx_heading_text"> Digital Transformation</h4>
                <p className="hlthcaretetx_para_text">
                Integrate advanced analytics for improved customer experiences and operational efficiency on your transformation journey.

                </p>
              </div>
            </div>
            <div className="healthcares_div_right">
              <div className="healthcares_div_imgmainalada">
                <img src={AutomationEfficiency} alt="..." />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Cloudtechnologiesmaincontent
