import React, { useState, useEffect } from 'react';
import mainlogo from "../Images/newlogo.png"
import { Link, NavLink } from 'react-router-dom'

const Header = () => {

  //sidebar Menu
  const [menu, setMenu] = useState(false);
  const [menuscnd, setMenuscnd] = useState(false);
  const [dropwspdoenopen, setdropwspdoenopen] = useState(false);
  const [dropwspdoenopenscbnd, setdropwspdoenopenscbnd] = useState(false);
  const [dropwdowmnopendthrdmodal, setdropwdowmnopendthrdmodal] = useState(false);
  const [dropdwnsunmnodaldj, setdropdwnsunmnodaldj] = useState(false);
  const [droejwsibdmmrnfic, setDroejwsibdmmrnfic] = useState(false);
  const [dooejjjskl,setdooejjjskl]=useState(false);
  const [ghuahsuhj,setGhuahsuhj]=useState(false)
  const handlesubmenu = () => {
    setMenu(!menu)
  }
  const handlesubmenuscnd = () => {
    setMenuscnd(!menuscnd)
  }
  const sidebaropen = () => {
    document.querySelector(".sidebardiv").style.cssText +=
      "transform:translate(0,0)";
  }
  const sidebarclose = () => {
    document.querySelector(".sidebardiv").style.cssText +=
      "transform: translate(-350px, 0)";
  }


  //header sticky
  const [isSticky, setSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) { // Change 100 to whatever offset you need
        setSticky(true);
      } else {
        setSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isSticky]);



  const derpoiiddioisownopne = () => {
    setdropwspdoenopen(!dropwspdoenopen)
  }

  const dropeforservidkfkcloc = () => {
    setdropwspdoenopenscbnd(!dropwspdoenopenscbnd)
  }

  const drowdoendsidebarthrd = () => {
    setdropwdowmnopendthrdmodal(!dropwdowmnopendthrdmodal)
  }

  const drowrssubmenuresposb = () => {
    setdropdwnsunmnodaldj(!dropdwnsunmnodaldj)
  }
  const drowrssubmenurenechsjhsposb = () => {
    setDroejwsibdmmrnfic(!droejwsibdmmrnfic)
  }

  const patrtsyshhdicvxlixk =()=>{
    setdooejjjskl(!dooejjjskl)
  }

  const farrehdiifarrwodmn=()=>{
    setGhuahsuhj(!ghuahsuhj)
  }

  return (
    <>
      <section className={`headermain ${isSticky ? 'sticky' : ''}`}>
        <div className='cus_container'>
          <div className='logomenusubmenudivflx'>
            <Link to="/" className='mainlogodivimg'>
              <img src={mainlogo} className='img-fluid' alt="logo" />
            </Link>

            <div className='hedermenudiv'>


              <ul className='headermenulistul'>

                <li className="headermenulistli">
                  <NavLink to="/" className="listlnktxt">
                    <span className='plusicon'>
                      Home

                    </span>

                  </NavLink>
                  {/* <ul class="submenulist">
                    <li class="submenulistdivli">
                      <NavLink to="/generativeai" className="lnklistheder">
                        Gen AI
                      </NavLink>
                    </li>
                    <li class="submenulistdivli">
                      <NavLink to="/softwareservice" className="lnklistheder">
                        Software Services
                      </NavLink>
                    </li>
                    <li class="submenulistdivli">
                      <NavLink to="/industrial-solution-inner" className="lnklistheder">
                        Industrial Solution
                      </NavLink>
                    </li>
                    <li class="submenulistdivli">
                      <NavLink to="/engineeringindex" className="lnklistheder">
                        Engineering Services
                      </NavLink>
                    </li>
                    <li class="submenulistdivli">
                      <NavLink to="/consulting" className="lnklistheder">
                        Consulting
                      </NavLink>
                    </li>
                    <li class="submenulistdivli">
                      <NavLink to="/mspprogramindex" className="lnklistheder">
                        MSP Program
                      </NavLink>
                    </li>
                  </ul> */}
                </li>
                <li className="headermenulistli">
                  <NavLink to="/" className="listlnktxt">
                    <span className='plusicon'>
                      About Us

                    </span>

                  </NavLink>
                  <ul class="submenulist">
                    <li class="submenulistdivli">
                      <NavLink to="/storymain" className="lnklistheder">
                        About Cygnus
                      </NavLink>
                    </li>
                    <li class="submenulistdivli">
                      <NavLink to="/myteam" className="lnklistheder">
                        Our Team
                      </NavLink>

                    </li>
                  </ul>
                </li>


                <li className="headermenulistli">
                  <NavLink  className="listlnktxt">
                    <span className='plusicon'>
                      Services
                    </span>

                  </NavLink>
                  <ul class="submenulist">
                    <li class="submenulistdivli">
                      <NavLink to="/businessconsulting" className="lnklistheder">
                        Business Consulting
                      </NavLink>
                    </li>
                    <li class="submenulistdivli">
                      <NavLink to="/staffingservice" className="lnklistheder">
                        Staffing Services
                      </NavLink>
                      <ul className="teamsubmenu">
                        <li>
                          <NavLink to="/contigentstuffing" className="lnklisthedersubsublist">Contingent Staffing</NavLink>
                        </li>
                        <li>
                          <NavLink to="/excutivesearch" className="lnklisthedersubsublist">Executive Search</NavLink>
                        </li>
                        <li>
                          <NavLink to="/engineeringindex" className="lnklisthedersubsublist">Engineering Services</NavLink>
                        </li>

                        <li>
                          <NavLink to="/mspprogramindex" className="lnklisthedersubsublist">Msp </NavLink>
                        </li>
                      </ul>
                    </li>
                    <li class="submenulistdivli">
                      <NavLink className="lnklistheder">
                        Software Developemt
                      </NavLink>

                      <ul className="teamsubmenu">
                        <li>
                          <NavLink to="/digitalanalytics" className="lnklisthedersubsublist">Digital & Analytics
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/cloudtechnologies" className="lnklisthedersubsublist">Cloud Technologies</NavLink>
                        </li>
                        <li>
                          <NavLink to="/aiml" className="lnklisthedersubsublist">AI/ML</NavLink>
                        </li>

                        <li>
                          <NavLink to="/fullstack" className="lnklisthedersubsublist">FullStack

                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/legacymodernization" className="lnklisthedersubsublist">Legacy Modernisation
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/sapandoracle" className="lnklisthedersubsublist">SAP/Oracle
                          </NavLink>
                        </li>

                        <li>
                          <NavLink to="/mobileapps" className="lnklisthedersubsublist">Mobile Apps
                          </NavLink>
                        </li>
                      </ul>
                    </li>
                  </ul>

                </li>

                <li className="headermenulistli">
                  <NavLink  className="listlnktxt">
                    <span className='plusicon'>
                      Solutions

                    </span>

                  </NavLink>
                  <ul class="submenulist">
                    <li class="submenulistdivli">
                      <NavLink to="/pharma" className="lnklistheder">
                        Pharma

                      </NavLink>
                    </li>
                    <li class="submenulistdivli">
                      <NavLink to="/bfsi" className="lnklistheder">
                      BFSI

                      </NavLink>
                    </li>
                    <li class="submenulistdivli">
                      <NavLink to="/automotive" className="lnklistheder">
                        Automotive

                      </NavLink>
                    </li>

                    <li class="submenulistdivli">
                      <NavLink to="/retail" className="lnklistheder">
                        Retail
                      </NavLink>
                    </li>
                  </ul>

                </li>

                <li className="headermenulistli">
                  <NavLink to="/practices" className="listlnktxt">
                    <span className='plusicon'>
                      Practices


                    </span>

                  </NavLink>
                  <ul class="submenulist">
                    <li class="submenulistdivli">
                      <NavLink to="/pharmacomplience" className="lnklistheder">
                        Pharma Complaince


                      </NavLink>
                    </li>
                    <li class="submenulistdivli">
                      <NavLink to="/cybersecurity" className="lnklistheder">
                        Cyber Security


                      </NavLink>
                    </li>

                  </ul>

                </li>


                <li className="headermenulistli">
                  <NavLink  className="listlnktxt">
                    <span className='plusicon'>
                      Framework
                    </span>

                  </NavLink>
                  <ul class="submenulist">
                    <li class="submenulistdivli">
                      <NavLink to="/generativeai" className="lnklistheder">
                        Gen Ai
                      </NavLink>
                    </li>


                  </ul>

                </li>

                <li className="headermenulistli">
                  <NavLink to="/workmain" className="listlnktxt">
                    Career
                  </NavLink>
                </li>

                <li className="headermenulistli">
                  <NavLink to="/contact-us" className="listlnktxt">
                    Contact
                  </NavLink>
                </li>

              </ul>

              <div className='headerrightmain'>
                <div class="sidebaropen" onClick={sidebaropen}>
                  <i class="fa-solid fa-bars"></i>
                </div>
              </div>


            </div>



          </div>

        </div>
      </section>



      {/******sidebar */}
      <section className="sidebardiv">
        <div className='sidetop'>

          <div className='frtsalonlogo'>
            <img src={mainlogo} alt="..." />
          </div>

          <div className='crosssideiocn' onClick={sidebarclose}>
            <i className="fa-solid fa-xmark"></i>
          </div>
        </div>
        <div className='sidebottom'>
          {/* <ul className='headermenulistulsidebar'>

            <li className="ftrmenulistli" onClick={handlesubmenu}>
              <NavLink to="/" className="listlnktxtsider">
                <span className='plusicon'>
                  What we do
                  
                </span>

              </NavLink>
              <ul class={menu ? "submenulistftrnew" : "submenulistftr"}>
                <li class="submenulistdivliftrside">
                  <NavLink to="/generativeai" className="listlnktxtftr" onClick={sidebarclose}>
                    Gen Ai
                  </NavLink>
                </li>
                <li class="submenulistdivliftrside">
                  <NavLink to="/softwareservice" className="listlnktxtftr" onClick={sidebarclose}>
                    Software Services
                  </NavLink>
                </li>
                <li class="submenulistdivliftrside">
                  <NavLink to="/industrial-solution-inner" className="listlnktxtftr" onClick={sidebarclose}>
                    Industrial Solution
                  </NavLink>
                </li>
                <li class="submenulistdivliftrside">
                  <NavLink to="/Engineeringindex" className="listlnktxtftr" onClick={sidebarclose}>
                    Engineering Services
                  </NavLink>
                </li>
                <li class="submenulistdivliftrside">
                  <NavLink to="/consulting" className="listlnktxtftr" onClick={sidebarclose}>
                    Consulting
                  </NavLink>
                </li>
                <li class="submenulistdivliftrside">
                  <NavLink to="/mspprogramindex" className="listlnktxtftr" onClick={sidebarclose}>
                    MSP Program
                  </NavLink>
                </li>

              </ul>
            </li>

            <li className="ftrmenulistli" onClick={handlesubmenuscnd}>
              <NavLink to="/" className="listlnktxtsider">
                <span className='plusicon'>
                  Who we are
                 
                </span>

              </NavLink>
              <ul class={menuscnd ? "submenulistftrnew" : "submenulistftr"}>
                <li class="submenulistdivliftrside">
                  <NavLink to="/storymain" className="listlnktxtftr" onClick={sidebarclose}>
                    About Cygnus
                  </NavLink>
                </li>
                <li class="submenulistdivliftrside">
                  <NavLink to="/myteam" className="listlnktxtftr" onClick={sidebarclose}>
                    Our Team
                  </NavLink>
                </li>

              </ul>
            </li>


            <li className="ftrmenulistli">
              <NavLink to="/workmain" className="listlnktxtsider" onClick={sidebarclose}>
                What we think
              </NavLink>
            </li>
            <li className="ftrmenulistli">
              <NavLink to="/contact-us" className="listlnktxtsider" onClick={sidebarclose}>
                Contact
              </NavLink>
            </li>
          </ul> */}
          <ul className='headermenulistulsidebar'>

            <li className="headermenulistlibshujsus">
              <NavLink to="/" className="listlnktxtsidebar" onClick={sidebarclose}>
                <span className='plusicon'>
                  Home

                </span>

              </NavLink>

            </li>

            <li className="headermenulistlibshujsus">
              <NavLink className="listlnktxtsidebar" >
                <span className='plusicon' onClick={derpoiiddioisownopne}>
                  About Us
                  <i className="fa-solid fa-circle-chevron-down"></i>
                </span>


              </NavLink>
              {dropwspdoenopen && <ul class="submenulistsidebvatyu">
                <li class="submenulistdivli" onClick={sidebarclose}>
                  <NavLink to="/storymain" className="listlnktxtsidebarttsyypp">
                    About Cygnus
                  </NavLink>
                </li>
                <li class="submenulistdivli" onClick={sidebarclose} >
                  <NavLink to="/myteam" className="listlnktxtsidebarttsyypp">
                    Our Team
                  </NavLink>

                </li>
              </ul>}
            </li>


            <li className="headermenulistlibshujsus">
              <NavLink to="/" className="listlnktxtsidebar"  >
                <span className='plusicon' onClick={dropeforservidkfkcloc}>
                  Services
                  <i className="fa-solid fa-circle-chevron-down"></i>
                </span>

              </NavLink>
              {dropwspdoenopenscbnd && <ul class="submenulistsidebvatyu">
                <li class="submenulistdivli"  onClick={sidebarclose}>
                  <NavLink to="/businessconsulting" className="listlnktxtsidebarttsyypp">
                    Business Consulting
                  </NavLink>
                </li>
                <li class="submenulistdivli"  onClick={sidebarclose}>
                  <NavLink to="/staffingservice" className="listlnktxtsidebarttsyypp">

                    <span className='plusicon' onClick={drowrssubmenuresposb}>
                      Staffing Services
                      <i className="fa-solid fa-circle-chevron-down"></i>
                    </span>
                  </NavLink>
                  {dropdwnsunmnodaldj && <ul className="teamsubmenusidebatsdpbk">
                    <li className='sadSADae'  onClick={sidebarclose}>
                      <NavLink to="/contigentstuffing" className="listlnktxtsidebarsidebsubn">Contingent Staffing</NavLink>
                    </li>
                    <li className='sadSADae'  onClick={sidebarclose}> 
                      <NavLink to="/excutivesearch" className="listlnktxtsidebarsidebsubn">Executive Search</NavLink>
                    </li>
                    <li className='sadSADae'  onClick={sidebarclose}>
                      <NavLink to="/engineeringindex" className="listlnktxtsidebarsidebsubn">Engineering Services</NavLink>
                    </li>
                    <li className='sadSADae'  onClick={sidebarclose}>
                      <NavLink to="/mspprogramindex" className="listlnktxtsidebarsidebsubn">Msp </NavLink>
                    </li>
                  </ul>}

                </li>
                <li class="submenulistdivli">
                  <NavLink className="listlnktxtsidebarttsyypp"  >

                    <span className='plusicon' onClick={drowrssubmenurenechsjhsposb}>
                      Software Developemt
                      <i className="fa-solid fa-circle-chevron-down"></i>
                    </span>
                  </NavLink>

                  {droejwsibdmmrnfic && <ul className="teamsubmenusidebatsdpbk">
                    <li className='sadSADae'  onClick={sidebarclose}>
                      <NavLink to="/digitalanalytics" className="listlnktxtsidebarsidebsubn">Digital & Analytics
                      </NavLink>
                    </li>
                    <li className='sadSADae'  onClick={sidebarclose}>
                      <NavLink to="/cloudtechnologies" className="listlnktxtsidebarsidebsubn">Cloud Technologies</NavLink>
                    </li>
                    <li className='sadSADae'  onClick={sidebarclose}>
                      <NavLink to="/aiml" className="listlnktxtsidebarsidebsubn">AI/ML</NavLink>
                    </li>

                    <li className='sadSADae'  onClick={sidebarclose}>
                      <NavLink to="/fullstack" className="listlnktxtsidebarsidebsubn">FullStack

                      </NavLink>
                    </li>
                    <li className='sadSADae'  onClick={sidebarclose}> 
                      <NavLink to="/legacymodernization" className="listlnktxtsidebarsidebsubn">Legacy Modernisation
                      </NavLink>
                    </li>
                    <li className='sadSADae'  onClick={sidebarclose}>
                      <NavLink to="/sapandoracle" className="listlnktxtsidebarsidebsubn">SAP/Oracle
                      </NavLink>
                    </li>

                    <li className='sadSADae'  onClick={sidebarclose}>
                      <NavLink to="/mobileapps" className="listlnktxtsidebarsidebsubn">Mobile Apps
                      </NavLink>
                    </li>
                  </ul>}
                </li>
              </ul>}

            </li>

            <li className="headermenulistlibshujsus">
              <NavLink to="/" className="listlnktxtsidebar"  >
                <span className='plusicon' onClick={drowdoendsidebarthrd}>
                  Solutions
                  <i className="fa-solid fa-circle-chevron-down"></i>
                </span>

              </NavLink>
              {dropwdowmnopendthrdmodal && <ul class="submenulistsidebvatyu">
                <li class="submenulistdivli" onClick={sidebarclose}>
                  <NavLink to="/pharma" className="listlnktxtsidebarttsyypp">
                    Pharma

                  </NavLink>
                </li>
                <li class="submenulistdivli" onClick={sidebarclose}>
                  <NavLink to="/bfsi" className="listlnktxtsidebarttsyypp">
                    BFSI

                  </NavLink>
                </li>
                <li class="submenulistdivli" onClick={sidebarclose}>
                  <NavLink to="/automotive" className="listlnktxtsidebarttsyypp">
                    Automotive

                  </NavLink>
                </li>

                <li class="submenulistdivli" onClick={sidebarclose}>
                  <NavLink to="/retail" className="listlnktxtsidebarttsyypp">
                    Retail
                  </NavLink>
                </li>
              </ul>}

            </li>

            <li className="headermenulistlibshujsus">
              <NavLink to="/practices" className="listlnktxtsidebar" >
                <span className='plusicon' onClick={patrtsyshhdicvxlixk}>
                  Practices
                  <i className="fa-solid fa-circle-chevron-down"></i>
                </span>

              </NavLink>
              {dooejjjskl && <ul class="submenulistsidebvatyu">
                <li class="submenulistdivli" onClick={sidebarclose}>
                  <NavLink to="/pharmacomplience" className="listlnktxtsidebarttsyypp">
                    Pharma Complaince


                  </NavLink>
                </li>
                <li class="submenulistdivli" onClick={sidebarclose}>
                  <NavLink to="/cybersecurity" className="listlnktxtsidebarttsyypp">
                    Cyber Security


                  </NavLink>
                </li>

              </ul>}

            </li>


            <li className="headermenulistlibshujsus">
              <NavLink to="/" className="listlnktxtsidebar">
                <span className='plusicon' onClick={farrehdiifarrwodmn}>
                  Framework
                  <i className="fa-solid fa-circle-chevron-down"></i>
                </span>

              </NavLink>
              {ghuahsuhj &&<ul class="submenulistsidebvatyu">
                <li class="submenulistdivli" onClick={sidebarclose}>
                  <NavLink to="/generativeai" className="listlnktxtsidebarsidebsubn">
                    Gen Ai
                  </NavLink>
                </li>


              </ul>}

            </li>

            <li className="headermenulistlibshujsus">
              <NavLink to="/workmain" className="listlnktxtsidebar" onClick={sidebarclose}>
                Career
              </NavLink>
            </li>

            <li className="headermenulistlibshujsus">
              <NavLink to="/contact-us" className="listlnktxtsidebar" onClick={sidebarclose}>
                Contact
              </NavLink>
            </li>

          </ul>
        </div>


      </section>
    </>
  )
}

export default Header