import React from 'react'
import "../../Css/Story.css";
import strydtls from "../../Images/strydtls.png"
const Storydetails = () => {
    return (
        <>
            <section className='storydetilsdivmain'>
                <div className='cus_container'>
                    <div className='stotyimgtextdivflx'>


                        <div className='storydetailsdiv'>
                            <h4 className='srtyydetailstxtp'>Our Story </h4>
                            <p className='storydetlas_txtp'>
                                Prioritizing enduring solutions
                                over fleeting trends
                            </p>
                            <p className='cygnusprofetxtopp'>
                                Cygnus Professionals Inc. is a next-generation global Information Technology Solution and Consulting Company. Powered by a
                                strong management and leadership team with
                            </p>


                        </div>
                        <div className='storydetailsimgdiv'>
                            <div className='storydetailsimg'>
                                <img src={strydtls} alt='...' />
                            </div>
                        </div>
                    </div>

                    <div className=''>
                        <p className='cygnusprofetxtopp'>
                        over 30+ years of experience across the industries and technologies helping enterprises with agility to achieve competitive advantage. Cygnus was incorporated in 2010 with its deep domain knowledge with successful client engagements to break down granary, comprehend advanced intricacy and offer to market faster. We help companies boldly tackle technology-related problems and make confident business decisions. Our 360-degree approach to design and develop products ensure that our clients’ IT departments and operating models are responsive and efficient, by cutting through the clutter of short- lived technology trends and providing them the robust product to create sustainable results for their end-users. Our rich project management experience, strong knowledge management, and center of excellence approach enable us to meet expectations. We believe that expectations and requirements vary from customer to customer and our deep understanding of the business domain, solution centric offerings, and flexible business models help us to meet clients’ requirements individually and offer better business outcomes. We empower IT to move at the speed of business, leveraging emerging technologies and the efficiencies of continuous delivery to spur business innovation. Operating in 5 workstations across India and US and we’re reliably viewed as probably the best work environment, encapsulated each day with great motivation by our triumphant culture of entrepreneurial, collaborative and dedicated employees. With our best practices always exhibiting in front, we help solve companies’ most complex technology and business-related challenges. Get in touch with us today to enable IT to drive competitive advantages for the best business outcomes.
                        </p>
                    </div>


                </div>
            </section>
        </>
    )
}

export default Storydetails
