import React from 'react'
import Achievment from "../../Images/Cygnus Pictures/Homepage/Achievment.png";
import img1 from "../../Images/Cygnus Pictures/img1.png";
import img2 from "../../Images/Cygnus Pictures/img2.png";
import img3 from "../../Images/Cygnus Pictures/img3.jpeg";
import img4 from "../../Images/Cygnus Pictures/img4.jpeg";
import img5 from "../../Images/Cygnus Pictures/img5.jpeg";
const Achievements = () => {
  return (
 <>
     <section className='achebvennemeygsttsection' style={{ backgroundImage: `url(${Achievment})` }}>
            <div className='cus_container'>
                <div className='innovatioinbgimg' >
                    <div className='innovationndivoimmggpading'>
                        <h4 className='iinovvationntxtppop'>Achievements</h4>
                        <p className='certtaiinitxtppp'>
                        Our journey is marked by a relentless pursuit of excellence and innovation, reflected in our key achievements. We prioritize efficiency and innovation in every project, ensuring that our digital transformation efforts emphasize utmost commitment. Our collaborative approach allows us to thoroughly understand the unique challenges of your business before diving into research and ideating sustainable solutions. Our role as catalysts for growth is anchored in mutual success through trusted partnerships. With extensive expertise in cutting-edge technologies, we generate critical insights that drive our ability to deliver high-impact results. We are committed to driving progress for our clients through our specialized consulting services. By maximizing the potential of their businesses, we empower them to uncover valuable insights from underlying patterns.
                        </p>

                        <div className='certtsiisfoimdivflx'>

                            <div className='certtsiisfoimdivimgmain'>
                                <img src={img1} alt='...' />
                            </div>


                            <div className='certtsiisfoimdivimgmain'>
                                <img src={img2} alt='...' />
                            </div>

                            <div className='certtsiisfoimdivimgmain'>
                                <img src={img3} alt='...' />
                            </div>


                            <div className='certtsiisfoimdivimgmain'>
                                <img src={img4} alt='...' />

                            </div>
                            <div className='certtsiisfoimdivimgmain'>
                                <img src={img5} alt='...' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
 </>
  )
}

export default Achievements
