import React from 'react'
import "./Achievement.css";
import innovationimg from "../../Images/Cygnus Pictures/Homepage/innovationimg.png";
import img1 from "../../Images/Cygnus Pictures/img1.png";
import img2 from "../../Images/Cygnus Pictures/img2.png";
import img3 from "../../Images/Cygnus Pictures/img3.jpeg";
import img4 from "../../Images/Cygnus Pictures/img4.jpeg";
import img5 from "../../Images/Cygnus Pictures/img5.jpeg";
const Innovation = () => {
    return (
        <section className='achebvennemeygsttsection' style={{ backgroundImage: `url(${innovationimg})` }}>
            <div className='cus_container'>
                <div className='innovatioinbgimg' >
                    <div className='innovationndivoimmggpading'>
                        <h4 className='iinovvationntxtppop'>Innovation</h4>
                        <p className='certtaiinitxtppp'>
                            {/* Certificates and awards that we were awarded by. */}
                            We stay at the forefront of technology, consistently adopting the latest innovations and best practices to provide cutting-edge solutions. Sustainability across all industries and geographies we bring deep, functional expertise and capture silos across boundaries
Our commitment to innovation drives our work in automation and software services, ensuring that our clients stay ahead of the curve. Our automated solutions are designed to streamline processes, reduce manual intervention, and increase efficiency across various sectors.

                        </p>

                        <div className='certtsiisfoimdivflx'>

                            <div className='certtsiisfoimdivimgmain'>
                                <img src={img1} alt='...' />
                            </div>


                            <div className='certtsiisfoimdivimgmain'>
                                <img src={img2} alt='...' />
                            </div>

                            <div className='certtsiisfoimdivimgmain'>
                                <img src={img3} alt='...' />
                            </div>


                            <div className='certtsiisfoimdivimgmain'>
                                <img src={img4} alt='...' />

                            </div>
                            <div className='certtsiisfoimdivimgmain'>
                                <img src={img5} alt='...' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Innovation
