import React from 'react'
import img1 from "../../Images/Cygnus Pictures/Industrial Solutions/kelly-sikkema-M98NRBuzbpc-unsplash.jpg";
const SupportMultiple = () => {
  return (
    <section className="healthcare_main" id='LogisticsTransportation'>
    <div className="cus_container">
        <div className="healthcares_div">
            <div className="healthcares_div_left">
                <div className="">
                    <h4 className="hlthcaretetx_heading_text">Support Multiple Tax rats & Payment Models</h4>
                    <p className="hlthcaretetx_para_text">
                    Our Cygnus Retail Management Software supports numerous tax rates, enabling you to deal with different tax authorities plus taxes. It is also flexible to multiple payment modes, as it accepts cards and bank transfers in addition to cash giving your retail business the flexibility to complete transactions and accept payment anywhere.
                    </p>
                </div>
            </div>
            <div className="healthcares_div_right">
                <div className="healthcares_div_img">
                    <img src={img1} alt="..." />
                </div>
            </div>
        </div>
        {/* <div className="healthcareswipper">

            <Swiper
                slidesPerView={3}
                spaceBetween={30}
                navigation={true}
                breakpoints={{
                    0: {
                        slidesPerView: 1,
                    },
                    400: {
                        slidesPerView: 2,
                    },
                    639: {
                        slidesPerView: 3,
                    },
                }}
                modules={[Navigation]}
                className='mySwiper'
            >
                <SwiperSlide className="">
                    <div className="healthcare_divsld_img">
                        <img src={img2} alt="..." />
                    </div>
                    <div className="">
                        <p className="slide_text_dtls_pp">
                            Dealer Management & Delivery
                        </p>
                    </div>
                </SwiperSlide>
                <SwiperSlide className="">
                    <div className="healthcare_divsld_img">
                        <img src={img3} alt="..." />
                    </div>
                    <div className="">
                        <p className="slide_text_dtls_pp">
                            Warehouse Management
                        </p>
                    </div>
                </SwiperSlide>
                <SwiperSlide className="">
                    <div className="healthcare_divsld_img">
                        <img src={img4} alt="..." />
                    </div>
                    <div className="">
                        <p className="slide_text_dtls_pp">
                            Transportation Analytics & Data Integration
                        </p>
                    </div>
                </SwiperSlide>
                <SwiperSlide className="">
                    <div className="healthcare_divsld_img">
                        <img src={img3} alt="..." />
                    </div>
                    <div className="">
                        <p className="slide_text_dtls_pp">
                            Warehouse Management
                        </p>
                    </div>
                </SwiperSlide>
                <SwiperSlide className="">
                    <div className="healthcare_divsld_img">
                        <img src={img4} alt="..." />
                    </div>
                    <div className="">
                        <p className="slide_text_dtls_pp">
                            Transportation Analytics & Data Integration
                        </p>
                    </div>
                </SwiperSlide>
                <SwiperSlide className="">
                    <div className="healthcare_divsld_img">
                        <img src={img3} alt="..." />
                    </div>
                    <div className="">
                        <p className="slide_text_dtls_pp">
                            Warehouse Management
                        </p>
                    </div>
                </SwiperSlide>
                <SwiperSlide className="">
                    <div className="healthcare_divsld_img">
                        <img src={img4} alt="..." />
                    </div>
                    <div className="">
                        <p className="slide_text_dtls_pp">
                            Transportation Analytics & Data Integration
                        </p>
                    </div>
                </SwiperSlide>
            </Swiper>
        </div> */}
    </div>
</section>
  )
}

export default SupportMultiple
