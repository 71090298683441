import React from 'react'
import teambanner from "../../Images/Cygnus Pictures/Our team/teambanner.png"
const Innerbanner = () => {
    return (
        <>

            <section className='industrialsolution_banner'>
                <div className='bgimage_'>
                    <img src={teambanner} className='img-fluid' alt='..' />
                </div>
                <div className='banner_wrap'>
                    <h2 className='sec_headingbannertset'> Our <span className='team-span'>Team</span>  </h2>
                </div>
            </section>
        </>
    )
}

export default Innerbanner
