import React from 'react'
import mobileapp from "../../Images/mobileapp.png";
import discover from "../../Images/discover.jpg"
const Mobileappdevlopment = () => {
    return (
        <>
            <section className="softwaresevices_main" >
                <div className="cus_container">


                    <div className="">
                        <h4 className="hlthcaretetx_heading_text">WHY SHOULD CYGNUS BE YOUR FIRST CHOICE FOR MOBILE APP DEVELOPMENT PARTNER?</h4>
                    </div>

                    <div className="healthcares_div">
                        <div className="healthcares_div_left">
                            <p className="hlthcaretetx_para_text">
                                Explore Our Cygnus Solutions for Mobile App Development
                                As per Statista – there are practically 3.3 million applications accessible in the Google Play Store, and every day a large number of applications are publishing to the Google Play and Apple App Stores. Starting mobile games to social network apps, each application is battling for its endurance. Yet, what the number of truly save their spot-on clients’ mobiles?
                                Mobile app development is viewed as a gear testing and modern activity by numerous firms, inferable from its various platforms, battling technologies, and eccentric clients’ behaviour, it’s truly basic to take all the components like the market, clients, cost, time, and so on into thought before hailing off the undertaking.
                                We fetch full-cycle mobile app services, including idea, UI/UX plan, prototyping, and uphold. We realize how to convey great & high-quality programming and how to do compelling business with you. Fast, transparent, time-and cost-productive advancement aren’t the attributes we attempt to dazzle our clients with; these are what make up the premise of our cognition of any project.

                            </p>
                        </div>

                        <div className="healthcares_div_right">
                            <div className="healthcares_div_img">
                                <img src={mobileapp} alt="..." />
                            </div>
                        </div>
                    </div>



                </div>
            </section>

            <section className='softwaresevices_main'>
                <div className='cus_container'>

                    <h4 className='assisttxtpp'>APPS FOR ALL SCREENS</h4>
                    
                    <div className='softeawrealexalefmaindivflx'>

                        <div className='disprocremnchamdivtopbottomdiv'>
                            <div className='disprocremnchamdivtopbottobg'>
                                <div className='disprocremnchamdivtop'>
                                    <img src={discover} alt='...' />
                                </div>
                                <div className='disprocremnchamdivbottom'>
                                    <h4 className='assitstxtpphhhh'>
                                    ANDROID APP DEVELOPMENT
                                    </h4>
                                    <ol className="listsftwareoldivol">
                                        <li className='listsftwareoldivli'>
                                            <p className='listsftwareoldivlitxtppboxtxt'>
                                            Design and development of apps for smartphones and tablets.
                                            </p>
                                        </li>
                                        <li className='listsftwareoldivli'>
                                            <p className='listsftwareoldivlitxtppboxtxt'>
                                            Passionate and professional in crafting the highest quality applications.
                                            </p>
                                        </li>
                                        <li className='listsftwareoldivli'>
                                            <p className='listsftwareoldivlitxtppboxtxt'>
                                            Latest development tools to create effective, intuitive, and engaging design.
                                            </p>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>


                        <div className='disprocremnchamdivtopbottomdiv'>
                            <div className='disprocremnchamdivtopbottobg'>
                                <div className='disprocremnchamdivtop'>
                                    <img src={discover} alt='...' />
                                </div>
                                <div className='disprocremnchamdivbottom'>
                                    <h4 className='assitstxtpphhhh'>
                                    PHONE APPLICATION DEVELOPMENT
                                    </h4>
                                    <ol className="listsftwareoldivol">
                                        <li className='listsftwareoldivli'>
                                            <p className='listsftwareoldivlitxtppboxtxt'>
                                            Elegant and engaging iPhone applications
                                            </p>
                                        </li>
                                        <li className='listsftwareoldivli'>
                                            <p className='listsftwareoldivlitxtppboxtxt'>
                                            Performance-oriented lucrative.

                                            </p>
                                        </li>
                                        <li className='listsftwareoldivli'>
                                            <p className='listsftwareoldivlitxtppboxtxt'>
                                            User-friendly design and develop of intuitive mobile apps.
                                            </p>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>


                        <div className='disprocremnchamdivtopbottomdiv'>
                            <div className='disprocremnchamdivtopbottobg'>
                                <div className='disprocremnchamdivtop'>
                                    <img src={discover} alt='...' />
                                </div>
                                <div className='disprocremnchamdivbottom'>
                                    <h4 className='assitstxtpphhhh'>
                                    IPAD APP DEVELOPMENT
                                    </h4>
                                    <ol className="listsftwareoldivol">
                                        <li className='listsftwareoldivli'>
                                            <p className='listsftwareoldivlitxtppboxtxt'>
                                            High Yielding iPad apps with superior quality.
                                            </p>
                                        </li>
                                        <li className='listsftwareoldivli'>
                                            <p className='listsftwareoldivlitxtppboxtxt'>
                                            Idea of iPad apps top the chart by executing.
                                            </p>
                                        </li>
                                        <li className='listsftwareoldivli'>
                                            <p className='listsftwareoldivlitxtppboxtxt'>
                                            Tenacity in making completely bug-free iPad apps.
                                            </p>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>



                </div>
            </section>
        </>
    )
}

export default Mobileappdevlopment
