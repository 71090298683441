import React from 'react'
import Backbg from "../../Images/Cygnus Pictures/Industrial Solutions/isis-franca-hsPFuudRg5I-unsplash.jpg"

const IndustrialSolutionBanner = () => {
    return (
        <>
            <section className='industrialsolution_banner'>
                <div className='bgimage_'>
                    <img src={Backbg} className='img-fluid' alt='..' />
                </div>
                <div className='banner_wrap'>
                    <h2 className='sec_headingbannertset'> End to end <span> Industrial Solutions </span> </h2>
                </div>
            </section>
        </>
    )
}

export default IndustrialSolutionBanner