import React from 'react'
import Staffingmain from './Staffingmain'
import Staffingcontent from './Staffingcontent'

const Staffingservice = () => {
    return (
        <>
            <Staffingmain />
            <Staffingcontent />
        </>
    )
}

export default Staffingservice
