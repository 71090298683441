import React from 'react'

const Sapandoraclecontent = () => {
  return (
    <>
      <section className='SectorWeServer_sec'>
        <div className='cus_container'>
          <h2 className='sec_heading'>Your SAP and Oracle Solutions Partner</h2>
          <p className='hlthcaretetx_para_text'>
          SAP and Oracle solutions are crucial for large-scale enterprise resource planning and management. Cygnus offers specialized services to help you implement, manage, and optimize SAP and Oracle systems, ensuring they deliver maximum value to your organization. 
          </p>

          <h2 className='ggpappadinbtm'>
          Our services include :
          </h2>


        </div>
      </section>
    </>
  )
}

export default Sapandoraclecontent
