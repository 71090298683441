import React from 'react'
import engineering from "../../Images/engineering.jpeg"
const Practicesmain = () => {
  return (
    <>
       <section className='industrialsolution_banner'>
        <div className='bgimage_'>
          <img src={engineering} className='img-fluid' alt='..' />
        </div>
        <div className='banner_wrap'>
          <h2 className='sec_headingbannertset'> PRACTICES</h2>
        </div>
      </section>
    </>
  )
}

export default Practicesmain
