import React from 'react'
import gurudasnew from "../../Images/gurudasnew.png";
import experteam2 from "../../Images/experteam2.png";
import experteam3 from "../../Images/experteam3.png";
import experteam4 from "../../Images/experteam4.png";
import experteam5 from "../../Images/experteam5.png";
import experteam6 from "../../Images/experteam6.png";
import experteam7 from "../../Images/experteam7.png";
import experteam8 from "../../Images/experteam8.png";
import arddaduop from "../../Images/arddaduop.png"
const ExpertTeam = () => {
    return (
        <section className='expertteammainsection'>
            <div className='cus_container'>
                <div className=''>
                    <h4 className='experteamhhh'>
                        Meet our team of experts in tech and marketing
                    </h4>
                </div>
                <div className='experteamdivbg_main'>
                  

                    <div className='experteamdivbg'>

                        <img src={experteam2} alt='...' />

                        <div className='descriptiondivmainbox'>
                            <p className='desigbtetxttppyyuuyp'>
                                Dhanashri Nair specializes in the Business Development, Client and Employee Relations, Building Recruitment Strategies, Merger, and Acquisitions. Focusing on innovation and delivering the right talent to cater the client demands in the cloud and big data solutions are her expertise in various industry domains. She is passionate about meeting commitments and highly values all organizational relationships
                            </p>
                        </div>
                    </div>

                    <div className='experteamdivbg' >

                        <img src={experteam5} alt='...' />


                        <div className='descriptiondivmainbox'>
                            <p className='desigbtetxttppyyuuyp'>
                                Poonam is proficient in Client relationship management with proven career track in managing substantial, meaningful & indelible Client Relationship. She is well-practiced and seasoned liaison between the Client’s senior executives & committed team at Cygnus Profes
                            </p>
                        </div>
                    </div>

                    <div className='experteamdivbg'>

                        <img src={experteam6} alt='...' />
                        <div className='descriptiondivmainbox'>
                            <p className='desigbtetxttppyyuuyp'>
                                Reema is an accomplished technology professional with a strong foundation of analytical and cross-functional wisdom. She has a proven record of success in all phases of diverse functional and technology projects. Skilled in Interviewing, Business Process Improvement, Recruiting, Resource Management, and Infrastructure Management. Strong business development professional with a Master’s Degree focused in Human Resources Development.
                            </p>
                        </div>
                    </div>

                    <div className='experteamdivbg'>

                        <img src={experteam7} alt='...' />

                        <div className='descriptiondivmainbox'>
                            <p className='desigbtetxttppyyuuyp'>
                            Eugene is a seasoned Information Systems professional with 20+ years of diversified Analysis, Design, Programming, Implementation, Consulting, and Management experience focusing on the iSeries environment. Ability to hit the ground running and make immediate contributions. He has a proven record of success in all phases of diverse functional and technology projects.
                            </p>
                        </div>
                    </div>

                    <div className='experteamdivbg'>

                        <img src={experteam8} alt='...' />

                        <div className='descriptiondivmainbox'>
                            <p className='desigbtetxttppyyuuyp'>
                            Zameer Shaik is experienced Operations Personnel with 12+ years of working History in IT Consulting and Software development industry. He is skillful operations professional with a proven record of accomplishment in Operations Excellence, People Management, Strategic Program Management, Process design, Improvement, Innovation, Employee relationship, Consulting, Sales, Technical Recruiting and Staffing Services.
                            </p>
                        </div>
                    </div>

                    <div className='experteamdivbg'>

                        <img src={experteam4} alt='...' />

                        
                        <div className='descriptiondivmainbox'>
                            <p className='desigbtetxttppyyuuyp'>
                            An accomplished professional with consistent success of over 10+ years in achieving sustained revenue and profitability growth in dynamic & changing markets. With a Good understanding of Pharma Sales & Marketing, he has a proven record of managing and staffing all requisitions and contractors for the MSP & SI Clients – Experis, Kelly Services, Wipro across USA Geo. & India.
                            </p>
                        </div>
                    </div>

                    <div className='experteamdivbg' >

                        <img src={experteam3} alt='...' />

                        <div className='descriptiondivmainbox'>
                            <p className='desigbtetxttppyyuuyp'>
                            With over 12 years of experience in human resources and talent management, Uday is currently the Global Head of Delivery at Cygnus Professionals Inc., a leading IT consulting and staffing firm. In this role, he provides strategic and operational leadership for the global talent and resource management function. Uday works across multiple geographies, industries, and clients to deliver customized and scalable solutions that align with clients' talent needs and business goals.
                            </p>

                            <p className='desigbtetxttppyyuuyp'>
                            Uday's core competencies include employee engagement, performance management, talent acquisition, vendor management, and client relations. He has a proven track record of achieving high employee satisfaction, retention, and productivity, as well as enhancing the quality and diversity of the talent pool. Passionate about mentoring, coaching, and developing his team, Uday fosters a culture of excellence, collaboration, and innovation. With an MBA in HR, he leverages his knowledge and skills to design and implement best practices and policies that align with the company's vision and values.
                            </p>
                        </div>
                    </div>

                    <div className='experteamdivbg' >

                        <img src={arddaduop} alt='...' />

                        <div className='descriptiondivmainbox'>
                            <p className='desigbtetxttppyyuuyp'>
                            As Vice President, Commercial Pharma Global PMO, during last 27 years, Arup has been driving business IT transformations strategies to improve business functions, product adoption, and client satisfaction in strategic and tactical business relationship. Proven track record of leading large-scale operational and strategic initiatives in complex IT environments and developing successful solutions based on cost-effective emerging technologies. Known as an inclusive, assertive leader who cultivates high performing teams to meet enterprise-wide scalability needs. Out-of-the-box thinker who thrives in fast-paced environments and committed to defining and monitoring consistent IT operations to reduce inefficiencies while exceeding performance targets. Earned recognition for personal integrity and commitment to building coalitions and aggressively implementing best practices that yield results above expectation.
                            </p>

                        </div>
                    </div>

                    {/* <div className='experteamdivbg'>
                        <div className='experteamdivbgdiv'>
                            <div className='experteamdivbgdivtop'>
                                <p className='experttemname'>Chris Mezy</p>
                                <p className='experttemnamedesg'>Data enginner</p>
                            </div>

                            <div className='experteamdivbgdivbottom'>
                                <img src={experteam4} alt='...' />
                            </div>
                        </div>
                    </div>

                    <div className='experteamdivbg' >
                        <div className='experteamdivbgdiv'>
                            <div className='experteamdivbgdivtop'>
                                <p className='experttemname'>Chris Mezy</p>
                                <p className='experttemnamedesg'>Data enginner</p>
                            </div>

                            <div className='experteamdivbgdivbottom'>
                                <img src={experteam3} alt='...' />
                            </div>
                        </div>
                    </div>

                    
                    <div className='experteamdivbg'>
                        <div className='experteamdivbgdiv'>
                            <div className='experteamdivbgdivtop'>
                                <p className='experttemname'>Chris Mezy</p>
                                <p className='experttemnamedesg'>Data enginner</p>
                            </div>

                            <div className='experteamdivbgdivbottom'>
                                <img src={experteam2} alt='...' />
                            </div>
                        </div>
                    </div>

                    <div className='experteamdivbg'>
                        <div className='experteamdivbgdiv'>
                            <div className='experteamdivbgdivtop'>
                                <p className='experttemname'>Chris Mezy</p>
                                <p className='experttemnamedesg'>Data enginner</p>
                            </div>

                            <div className='experteamdivbgdivbottom'>
                                <img src={experteam1} alt='...' />
                            </div>
                        </div>
                    </div> */}





                </div>

            </div>
        </section>
    )
}

export default ExpertTeam
