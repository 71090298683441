import React from 'react'
import Generativemain from "../GenerativeAi/Generativemain";
import Generativeaicontent from "../GenerativeAi/Generativeaicontent";
import Generativecontentmain from "../GenerativeAi/Generativecontentmain"
const Generativeindex = () => {
    return (
        <>
            <Generativemain />
            <Generativeaicontent />
            <Generativecontentmain />
        </>
    )
}

export default Generativeindex
