import React from 'react'
import softwarebg from "../../Images/softwarebg.png"
const Softwaremain = () => {
  return (
    <>
          <section className='industrialsolution_banner'>
                <div className='bgimage_'>
                    <img src={softwarebg} className='img-fluid' alt='..' />
                </div>
                <div className='banner_wrap'>
                    <h2 className='sec_headingbannertset'> End to end <span> SOFTWARE SERVICES  </span> </h2>
                </div>
            </section>
    </>
  )
}

export default Softwaremain
