import React from 'react'
import Genaibanner from "../../Images/Genaibanner.jpeg"
const Generativemain = () => {
    return (
        <section className='industrialsolution_banner'>
            <div className='bgimage_'>
                <img src={Genaibanner} className='img-fluid' alt='..' />
            </div>
            <div className='banner_wrap'>
                <h2 className='sec_headingbannertset'>Generative <span className='ai-span'>Ai </span> </h2>
            </div>
        </section>
    )
}

export default Generativemain
