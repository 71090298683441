import React from 'react'
import Machinelearning from "../../Images/Machinelearning.png";
import fullstack from "../../Images/fullstack.jpg";
import fullstackbanner from "../../Images/fullstackbanner.jpg";
import enedtoend from "../../Images/enedtoend.jpg";
import scalability from "../../Images/scalability.jpg"
const Fullstackmaincontent = () => {
  return (
    <>
      <section className="softwaresevices_main" >
        <div className="cus_container">
          <div className="healthcares_divnewadd">
            <div className="healthcares_div_left">
              <div className="">
                <h4 className="hlthcaretetx_heading_text">Front-End Development</h4>
                <p className="hlthcaretetx_para_text">
                  We craft engaging and intuitive user interfaces using cutting-edge technologies and frameworks for a seamless, responsive user experience across devices and platforms.
                </p>
              </div>
            </div>
            <div className="healthcares_div_right">
              <div className="healthcares_div_imgmainalada">
                <img src={fullstack} alt="..." />
              </div>
            </div>
          </div>

          <div className="healthcares_divnewadd">
            <div className="healthcares_div_left">
              <div className="">
                <h4 className="hlthcaretetx_heading_text">Back-End Development</h4>
                <p className="hlthcaretetx_para_text">
                  Our team builds secure and efficient back-end systems supporting your applications, encompassing database management, server-side logic, and API integrations.
                </p>
              </div>
            </div>
            <div className="healthcares_div_right">
              <div className="healthcares_div_imgmainalada">
                <img src={fullstackbanner} alt="..." />
              </div>
            </div>
          </div>

          <div className="healthcares_divnewadd">
            <div className="healthcares_div_left">
              <div className="">
                <h4 className="hlthcaretetx_heading_text">End-to-End Solutions</h4>
                <p className="hlthcaretetx_para_text">
                  From initial design to testing and deployment, we manage every phase of application development, providing integrated solutions tailored to meet your business needs.
                </p>
              </div>
            </div>
            <div className="healthcares_div_right">
              <div className="healthcares_div_imgmainalada">
                <img src={enedtoend} alt="..." />
              </div>
            </div>
          </div>

          <div className="healthcares_divnewadd">
            <div className="healthcares_div_left">
              <div className="">
                <h4 className="hlthcaretetx_heading_text">Scalability and Performance</h4>
                <p className="hlthcaretetx_para_text">
                  Our focus on scalability and performance ensures applications can handle increasing loads and maintain peak performance as your business expands.

                </p>
              </div>
            </div>
            <div className="healthcares_div_right">
              <div className="healthcares_div_imgmainalada">
                <img src={scalability} alt="..." />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Fullstackmaincontent
