import React from 'react'

const SectorWeServer = () => {
    return (
        <>
            <section className='SectorWeServer_sec'>
                <div className='cus_container'>
                    <h2 className='sec_heading'>The Sector We Serve Industrial Solutions</h2>

                    {/* <ul className='seclist'>
                        <li><a href='#healthcare_main'>Healthcare</a></li>
                        <li><a href='#PharmaceuticalIndustry'>Pharmaceutical Industry</a></li>
                        <li><a href='#Bankingtelecomindustry'>Banking and Financial Industries</a></li>
                        <li><a href='#Telecom'>Telecom</a></li>
                        <li><a href='#PublicSector'>Public Sector</a></li>
                        <li><a href='#RetailManagement'>Retail Management</a></li>
                        <li><a href='#Manufacturing'>Manufacturing</a></li>
                        <li><a href='#LogisticsTransportation'>Logistics & Transportation</a></li>
                    </ul> */}

                </div>
            </section>
        </>
    )
}

export default SectorWeServer