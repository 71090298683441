import React from 'react'
import img1 from "../../Images/Cygnus Pictures/Industrial Solutions/pina-messina-kfJkpeI6Lgc-unsplash.jpg";
import img3 from "../../Images/Cygnus Pictures/Industrial Solutions/thisisengineering-mvbtVeRVJzg-unsplash.jpg"
import img4 from "../../Images/Cygnus Pictures/Industrial Solutions/Screenshot 2024-07-11 172342.png";
const Pharmabankingfacturing = () => {
  return (
    <section className='healthcarepractice'>
                <div className='cus_container'>
                    <div className='healthcarepracticeflx'>
                        <div className='healthcarepracticedibimg'>
                            <div className='healthcarepracticedibimgmain'>
                                 <img src={img1} alt="..." />
                            </div>
                            <p className='slide_text_dtls_pp'>Pharmaceutical and Life Sciences Industry</p>
                        </div>
                        <div className='healthcarepracticedibimg'>
                            <div className='healthcarepracticedibimgmain'>
                            <img src={img3} alt="..." />
                            </div>
                            <p className='slide_text_dtls_pp'>Clinical Trail Management Software Development</p>
                        </div>
                        <div className='healthcarepracticedibimg'>
                            <div className='healthcarepracticedibimgmain'>
                            <img src={img4} alt="..." />
                            </div>
                            <p className='slide_text_dtls_pp'>Pharma Manufacturing & commercialization Software Development</p>
                        </div>
                    </div>
                </div>
    </section>
  )
}

export default Pharmabankingfacturing
