import React from 'react'
import staffingservices from "../../Images/staffingservices.png"
const Staffingmain = () => {
  return (
    <>
      <section className='industrialsolution_banner'>
            <div className='bgimage_'>
                <img src={staffingservices} className='img-fluid' alt='..' />
            </div>
            <div className='banner_wrap'>
                <h2 className='sec_headingbannertset'> End to end <span> STAFFING SERVICES  </span> </h2>
            </div>
        </section>
    </>
  )
}

export default Staffingmain
