import React from 'react'
import legacybanner1 from "../../Images/legacybannerbanner1.jpg"
const Legacymodernizationmain = () => {
  return (
      <>
        <section className='industrialsolution_banner'>
        <div className='bgimage_'>
          <img src={legacybanner1} className='img-fluid' alt='..' />
        </div>
        <div className='banner_wrap'>
          <h2 className='sec_headingbannertset'> End to end <span> LEGACY MODERNIZATION </span> </h2>
        </div>
      </section>
      </>
  )
}

export default Legacymodernizationmain
