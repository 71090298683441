import React from 'react'
import Machinelearning from "../../Images/Machinelearning.png";
import dataanalytics from "../../Images/dataanalytics.png";
import DIGITALANALYTICS from "../../Images/DIGITALANALYTICS.jpg";
import BusinessIntelligence from "../../Images/BusinessIntelligence.jpg";
import cuuut from "../../Images/cuuut.jpeg"
const Digitalandanalyticsmaincontent = () => {
  return (
    <>
      <section className="softwaresevices_main" >
        <div className="cus_container">
          <div className="healthcares_divnewadd">
            <div className="healthcares_div_left">
              <div className="">
                <h4 className="hlthcaretetx_heading_text">Data Strategy and Governance</h4>
                <p className="hlthcaretetx_para_text">
                  Craft robust data strategies ensuring accuracy, consistency, and security, supported by effective governance frameworks.
                </p>
              </div>
            </div>
            <div className="healthcares_div_right">
              <div className="healthcares_div_imgmainalada">
                <img src={dataanalytics} alt="..." />
              </div>
            </div>
          </div>

          <div className="healthcares_divnewadd">
            <div className="healthcares_div_left">
              <div className="">
                <h4 className="hlthcaretetx_heading_text">Advanced Analytics</h4>
                <p className="hlthcaretetx_para_text">
                  Uncover valuable insights using predictive and prescriptive analytics techniques to drive business strategies
                </p>
              </div>
            </div>
            <div className="healthcares_div_right">
              <div className="healthcares_div_imgmainalada">
                <img src={DIGITALANALYTICS} alt="..." />
              </div>
            </div>
          </div>

          <div className="healthcares_divnewadd">
            <div className="healthcares_div_left">
              <div className="">
                <h4 className="hlthcaretetx_heading_text">Business Intelligence Solutions</h4>
                <p className="hlthcaretetx_para_text">
                  Transform complex data into intuitive dashboards, enabling real-time performance monitoring and informed decision-making.
                </p>
              </div>
            </div>
            <div className="healthcares_div_right">
              <div className="healthcares_div_imgmainalada">
                <img src={BusinessIntelligence} alt="..." />
              </div>
            </div>
          </div>

          <div className="healthcares_divnewadd">
            <div className="healthcares_div_left">
              <div className="">
                <h4 className="hlthcaretetx_heading_text"> Digital Transformation</h4>
                <p className="hlthcaretetx_para_text">
                  Integrate advanced analytics into existing systems for enhanced customer experiences and operational efficiency.
                  Stay ahead in the data-driven world with Cygnus's transformative services.

                </p>
              </div>
            </div>
            <div className="healthcares_div_right">
              <div className="healthcares_div_imgmainalada">
                <img src={cuuut} alt="..." />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Digitalandanalyticsmaincontent
