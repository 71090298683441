import React from 'react'
import Legacymodernizationmain from './Legacymodernizationmain'
import Legacymodernizationcontent from './Legacymodernizationcontent'
import Legacymodernizationmaincontent from './Legacymodernizationmaincontent'

const Legacymodernization = () => {
    return (
        <>
            <Legacymodernizationmain />
            <Legacymodernizationcontent />
            <Legacymodernizationmaincontent />
        </>
    )
}

export default Legacymodernization
