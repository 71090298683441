import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';

import { Navigation } from 'swiper/modules';

import img1 from "../../Images/Cygnus Pictures/Industrial Solutions/Screenshot 2024-07-11 202251.png";
import img2 from "../../Images/Cygnus Pictures/Industrial Solutions/nik-73_kRzs9sqo-unsplash.jpg";
import img3 from "../../Images/Cygnus Pictures/Industrial Solutions/azzedine-rouichi-VMB8B-5PeR4-unsplash.jpg";
import img4 from "../../Images/Cygnus Pictures/Industrial Solutions/Screenshot 2024-07-11 172510.png";

const PublicSector = () => {
    return (
        <>
            <section className="healthcare_main" id='PublicSector'>
                <div className="cus_container">
                    <div className="healthcares_div">
                        <div className="healthcares_div_left">
                            <div className="">
                                <h4 className="hlthcaretetx_heading_text">Mobile Banking & Loan Management Software</h4>
                                <p className="hlthcaretetx_para_text">
                                We work with the web and on-premises banks to digitalize client onboarding experience and improve on monetary activities. We break down recorded information to decrease hazards, mechanize advance issuance, and cut expenses across credit markets.

                                </p>
                            </div>
                        </div>
                        <div className="healthcares_div_right">
                            <div className="healthcares_div_img">
                                <img src={img1} alt="..." />
                            </div>
                        </div>
                    </div>
                    {/* <div className="healthcareswipper">

                        <Swiper
                            slidesPerView={3}
                            spaceBetween={30}
                            navigation={true}
                            breakpoints={{
                                0: {
                                    slidesPerView: 1,
                                },
                                400: {
                                    slidesPerView: 2,
                                },
                                639: {
                                    slidesPerView: 3,
                                },
                            }}
                            modules={[Navigation]}
                            className='mySwiper'
                        >
                            <SwiperSlide className="">
                                <div className="healthcare_divsld_img">
                                    <img src={img2} alt="..." />
                                </div>
                                <div className="">
                                    <p className="slide_text_dtls_pp">
                                        Network Protection
                                    </p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="">
                                <div className="healthcare_divsld_img">
                                    <img src={img3} alt="..." />
                                </div>
                                <div className="">
                                    <p className="slide_text_dtls_pp">
                                        Prescient Maintainace
                                    </p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="">
                                <div className="healthcare_divsld_img">
                                    <img src={img4} alt="..." />
                                </div>
                                <div className="">
                                    <p className="slide_text_dtls_pp">
                                        General Health & Safetu
                                    </p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="">
                                <div className="healthcare_divsld_img">
                                    <img src={img3} alt="..." />
                                </div>
                                <div className="">
                                    <p className="slide_text_dtls_pp">
                                        Prescient Maintainace
                                    </p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="">
                                <div className="healthcare_divsld_img">
                                    <img src={img4} alt="..." />
                                </div>
                                <div className="">
                                    <p className="slide_text_dtls_pp">
                                        General Health & Safetu
                                    </p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="">
                                <div className="healthcare_divsld_img">
                                    <img src={img3} alt="..." />
                                </div>
                                <div className="">
                                    <p className="slide_text_dtls_pp">
                                        Prescient Maintainace
                                    </p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="">
                                <div className="healthcare_divsld_img">
                                    <img src={img4} alt="..." />
                                </div>
                                <div className="">
                                    <p className="slide_text_dtls_pp">
                                        General Health & Safetu
                                    </p>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div> */}
                </div>
            </section>
        </>
    )
}

export default PublicSector