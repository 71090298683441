import React from 'react'
import iot from "../../Images/iot.png";
import blockchain from "../../Images/blockchain.png";
import automation from "../../Images/automation.png";
import discover from "../../Images/discover.jpg"
const SoftwareIot = () => {
    return (
        <section className="softwaresevices_main" >
            <div className="cus_container">


                <div className="">
                    <h4 className="hlthcaretetx_heading_text">EXPLORE OUR IOT EXPERTISE</h4>
                </div>

                <div className="healthcares_div">
                    <div className="healthcares_div_left">
                        <p className="hlthcaretetx_para_text">
                            Cygnus accumulated extensive experience with Internet of Things solutions and services. Our embedded software development teams create IoT systems that boost operational efficiency and cut costs across a wide range of industries. We cooperate with aviation technology, energy, manufacturing, and other companies supporting their data- driven optimizations and digital transformations.
                            For instance, we cooperate with an in-flight internet provider analyzing the performance of their equipment on the planes. Our dedicated development team uses connectivity, sensors, cloud computing, and data science to monitor hardware and schedule equipment maintenance at the right time. The solution reduced “No Fault Found” instances by nearly 90%.


                        </p>
                    </div>

                    <div className="healthcares_div_right">
                        <div className="healthcares_div_img">
                            <img src={iot} alt="..." />
                        </div>
                    </div>
                </div>




                {/* <div className='softeawrealexaleftrightdivflx'> */}

                {/* </div> */}

                <div className='softeawrealexalefmaindivflx'>

                    <div className='disprocremnchamdivtopbottomdiv'>
                        <div className='disprocremnchamdivtopbottobg'>
                            <div className='disprocremnchamdivtop'>
                                <img src={discover} alt='...' />
                            </div>
                            <div className='disprocremnchamdivbottom'>
                                <h4 className='assitstxtpphhhh'>
                                    IOT PROFICIENCY AND DIVERSE TECH STACK
                                </h4>
                                <ol className="listsftwareoldivol">
                                    <li className='listsftwareoldivli'>
                                        <p className='listsftwareoldivlitxtppboxtxt'>
                                            Control quality and consistency of production
                                        </p>
                                    </li>
                                    <li className='listsftwareoldivli'>
                                        <p className='listsftwareoldivlitxtppboxtxt'>
                                            Experience in IoT-friendly languages like C/C++, Java, Go, Parasail, Python, Assembly, B#, JavaScript.
                                        </p>
                                    </li>
                                    <li className='listsftwareoldivli'>
                                        <p className='listsftwareoldivlitxtppboxtxt'>
                                            N-iX expertise platforms like Amazon Web Services, Microsoft Azure, IBM Watson, etc.
                                        </p>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>


                    <div className='disprocremnchamdivtopbottomdiv'>
                        <div className='disprocremnchamdivtopbottobg'>
                            <div className='disprocremnchamdivtop'>
                                <img src={discover} alt='...' />
                            </div>
                            <div className='disprocremnchamdivbottom'>
                                <h4 className='assitstxtpphhhh'>
                                    SOLUTIONS FOR DEVICE CONNECTIVITY AND SENSORS
                                </h4>
                                <ol className="listsftwareoldivol">
                                    <li className='listsftwareoldivli'>
                                        <p className='listsftwareoldivlitxtppboxtxt'>
                                            Embedded solutions with communication interfaces and networking protocols like NFC, Wi-Fi, and Bluetooth.
                                        </p>
                                    </li>
                                    <li className='listsftwareoldivli'>
                                        <p className='listsftwareoldivlitxtppboxtxt'>
                                            Scalable Software Architectures for easy integration.

                                        </p>
                                    </li>
                                    <li className='listsftwareoldivli'>
                                        <p className='listsftwareoldivlitxtppboxtxt'>
                                            Solutions implies sensors, smart devices, applications, and third-party software.
                                        </p>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>


                    <div className='disprocremnchamdivtopbottomdiv'>
                        <div className='disprocremnchamdivtopbottobg'>
                            <div className='disprocremnchamdivtop'>
                                <img src={discover} alt='...' />
                            </div>
                            <div className='disprocremnchamdivbottom'>
                                <h4 className='assitstxtpphhhh'>
                                    SMART SOLUTIONS AND EMBEDDED SYSTEMS
                                </h4>
                                <ol className="listsftwareoldivol">
                                    <li className='listsftwareoldivli'>
                                        <p className='listsftwareoldivlitxtppboxtxt'>
                                            Embedded hardware prototyping and development of connected systems.
                                        </p>
                                    </li>
                                    <li className='listsftwareoldivli'>
                                        <p className='listsftwareoldivlitxtppboxtxt'>
                                            Experts suggestions through every delivery stage.
                                        </p>
                                    </li>
                                    <li className='listsftwareoldivli'>
                                        <p className='listsftwareoldivlitxtppboxtxt'>
                                            Connected solutions on existing platforms with extending functionality.
                                        </p>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>


                    <div className='disprocremnchamdivtopbottomdiv'>
                        <div className='disprocremnchamdivtopbottobg'>
                            <div className='disprocremnchamdivtop'>
                                <img src={discover} alt='...' />
                            </div>
                            <div className='disprocremnchamdivbottom'>
                                <h4 className='assitstxtpphhhh'>
                                    SMART CONTRACT SOLUTIONS
                                </h4>
                                <ol className="listsftwareoldivol">
                                    <li className='listsftwareoldivli'>
                                        <p className='listsftwareoldivlitxtppboxtxt'>
                                            Data security and feasibility utilizing shrewd agreement
                                        </p>
                                    </li>
                                    <li className='listsftwareoldivli'>
                                        <p className='listsftwareoldivlitxtppboxtxt'>
                                            Push the information to could execute the ideal order Ethereum.
                                        </p>
                                    </li>
                                    <li className='listsftwareoldivli'>
                                        <p className='listsftwareoldivlitxtppboxtxt'>
                                            constructed explicitly for making savvy contracts.
                                        </p>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>


                    <div className='disprocremnchamdivtopbottomdiv'>
                        <div className='disprocremnchamdivtopbottobg'>
                            <div className='disprocremnchamdivtop'>
                                <img src={blockchain} alt='...' />
                            </div>
                            <div className='disprocremnchamdivbottom'>
                                <h4 className='assitstxtpphhhh'>
                                    BLOCKCHAIN CONSULTING
                                </h4>
                                <ol className="listsftwareoldivol">
                                    <li className='listsftwareoldivli'>
                                        <p className='listsftwareoldivlitxtppboxtxt'>
                                            Affirm in right full consulting for a legitimate solution.
                                        </p>
                                    </li>
                                    <li className='listsftwareoldivli'>
                                        <p className='listsftwareoldivlitxtppboxtxt'>
                                            Recommends and learning for the products.
                                        </p>
                                    </li>
                                    <li className='listsftwareoldivli'>
                                        <p className='listsftwareoldivlitxtppboxtxt'>
                                            Eliminate of technology constraints for smooth progression of work.
                                        </p>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>

                    <div className='disprocremnchamdivtopbottomdiv'>
                        <div className='disprocremnchamdivtopbottobg'>
                            <div className='disprocremnchamdivtop'>
                                <img src={discover} alt='...' />
                            </div>
                            <div className='disprocremnchamdivbottom'>
                                <h4 className='assitstxtpphhhh'>
                                    PROOF OF CONCEPT
                                </h4>
                                <ol className="listsftwareoldivol">
                                    <li className='listsftwareoldivli'>
                                        <p className='listsftwareoldivlitxtppboxtxt'>
                                            Specialized methodologies for blockchain structure.
                                        </p>
                                    </li>
                                    <li className='listsftwareoldivli'>
                                        <p className='listsftwareoldivlitxtppboxtxt'>
                                            Unravel and address the solutions which are useful.
                                        </p>
                                    </li>
                                    <li className='listsftwareoldivli'>
                                        <p className='listsftwareoldivlitxtppboxtxt'>
                                            Distinguishing the viewpoints for future references.
                                        </p>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>

                    <div className='disprocremnchamdivtopbottomdiv'>
                        <div className='disprocremnchamdivtopbottobg'>
                            <div className='disprocremnchamdivtop'>
                                <img src={discover} alt='...' />
                            </div>
                            <div className='disprocremnchamdivbottom'>
                                <h4 className='assitstxtpphhhh'>
                                    COMPETITIVENESS
                                </h4>
                                <ol className="listsftwareoldivol">
                                    <li className='listsftwareoldivli'>
                                        <p className='listsftwareoldivlitxtppboxtxt'>
                                            Dealing with blockchain technology with team of experts.
                                        </p>
                                    </li>
                                    <li className='listsftwareoldivli'>
                                        <p className='listsftwareoldivlitxtppboxtxt'>
                                            Best business models and turnkey.
                                        </p>
                                    </li>
                                    <li className='listsftwareoldivli'>
                                        <p className='listsftwareoldivlitxtppboxtxt'>
                                            New source of revenue models for any business.
                                        </p>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>


                </div>


                {/* <div className='softwaredivspclbgdiv'>
                </div> */}


                <div className='disprocremnchamdivtopbottommixflx'>
                    <div className='disprocremnchamdivtopbottommix'>
                        <div className='disprocremnchamdivtopbottobg'>
                            <div className='disprocremnchamdivtop'>
                                <img src={discover} alt='...' />
                            </div>
                            <div className='disprocremnchamdivbottom'>
                                <h4 className='assitstxtpphhhh'>
                                    FULLSTACK ENGINEERING
                                </h4>
                                <p className='assitstxtpphhhhparaa'>
                                    Our architecture team is constantly chipping on each section of development and diverse designs make us unique from others.
                                </p>
                            </div>
                        </div>
                    </div>


                    <div className='disprocremnchamdivtopbottommix'>
                        <div className='disprocremnchamdivtopbottobg'>
                            <div className='disprocremnchamdivtop'>
                                <img src={discover} alt='...' />
                            </div>
                            <div className='disprocremnchamdivbottom'>
                                <h4 className='assitstxtpphhhh'>
                                    PROOF OF CONCEPT
                                </h4>
                                <p className='assitstxtpphhhhparaa'>
                                    Not every problem needs machine learning. That’s why; we run a test on a small system just to verify the viability of the machine-learning model.
                                </p>
                            </div>
                        </div>
                    </div>


                    <div className='disprocremnchamdivtopbottommix'>
                        <div className='disprocremnchamdivtopbottobg'>
                            <div className='disprocremnchamdivtop'>
                                <img src={discover} alt='...' />
                            </div>
                            <div className='disprocremnchamdivbottom'>
                                <h4 className='assitstxtpphhhh'>
                                    CONNECT AND ENGAGE
                                </h4>
                                <p className='assitstxtpphhhhparaa'>
                                    We are chipping away at the most recent tech-stack to associate the trending stamping and assist our customers to engage with the client for the long haul.
                                </p>
                            </div>
                        </div>
                    </div>


                    <div className='disprocremnchamdivtopbottommix'>
                        <div className='disprocremnchamdivtopbottobg'>
                            <div className='disprocremnchamdivtop'>
                                <img src={discover} alt='...' />
                            </div>
                            <div className='disprocremnchamdivbottom'>
                                <h4 className='assitstxtpphhhh'>
                                    ARCHITECTURE AND DESIGN
                                </h4>
                                <p className='assitstxtpphhhhparaa'>
                                    Our main aim to provide an analytics solution for the digitally associated environment for data, people, and frameworks.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className='disprocremnchamdivtopbottommix'>
                        <div className='disprocremnchamdivtopbottobg'>
                            <div className='disprocremnchamdivtop'>
                                <img src={discover} alt='...' />
                            </div>
                            <div className='disprocremnchamdivbottom'>
                                <h4 className='assitstxtpphhhh'>
                                    FASTER AS CLOUD
                                </h4>
                                <p className='assitstxtpphhhhparaa'>
                                    We are catching on quickly to make you mindful of current innovation on the market and accordingly our group is continually dealing with recent updates and new learnings
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className='disprocremnchamdivtopbottommix'>
                        <div className='disprocremnchamdivtopbottobg'>
                            <div className='disprocremnchamdivtop'>
                                <img src={discover} alt='...' />
                            </div>
                            <div className='disprocremnchamdivbottom'>
                                <h4 className='assitstxtpphhhh'>
                                    FOCUSES ON LATEST TOOLS
                                </h4>
                                <p className='assitstxtpphhhhparaa'>
                                    From the latest development methodologies to miniature architecture structures, our designing group is future-proof and utilizes the most recent technologies for you.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className='disprocremnchamdivtopbottommix'>
                        <div className='disprocremnchamdivtopbottobg'>
                            <div className='disprocremnchamdivtop'>
                                <img src={discover} alt='...' />
                            </div>
                            <div className='disprocremnchamdivbottom'>
                                <h4 className='assitstxtpphhhh'>
                                    FOCUSES ON LATEST TOOLS
                                </h4>
                                <p className='assitstxtpphhhhparaa'>
                                    From the latest development methodologies to miniature architecture structures, our designing group is future-proof and utilizes the most recent technologies for you.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className='disprocremnchamdivtopbottommix'>
                        <div className='disprocremnchamdivtopbottobg'>
                            <div className='disprocremnchamdivtop'>
                                <img src={discover} alt='...' />
                            </div>
                            <div className='disprocremnchamdivbottom'>
                                <h4 className='assitstxtpphhhh'>
                                    VISUALIZING THE FUTURE WITH YOU
                                </h4>
                                <p className='assitstxtpphhhhparaa'>
                                    Once a product is launched, our experts can help gather feedback, maintain overabundance and emphasize – making sure you are evolving as fast as your customers and assist you with envisioning what’s to come.
                                </p>
                            </div>
                        </div>
                    </div>


                </div>

                <div className='softeawrealexalefmaindivflx'>
                   
                    <div className='disprocremnchamdivtopbottomdiv'>
                        <div className='disprocremnchamdivtopbottobg'>
                            <div className='disprocremnchamdivtop'>
                                <img src={discover} alt='...' />
                            </div>
                            <div className='disprocremnchamdivbottom'>
                                <h4 className='assitstxtpphhhh'>
                                PROCURE TO PAY” PROCESS AUTOMATION USING RPA
                                </h4>
                                <ol className="listsftwareoldivol">
                                    <li className='listsftwareoldivli'>
                                        <p className='listsftwareoldivlitxtppboxtxt'>
                                        The intact procurement of payment cycle.
                                        </p>
                                    </li>
                                    <li className='listsftwareoldivli'>
                                        <p className='listsftwareoldivlitxtppboxtxt'>
                                        End-to-end using RPA processing time.
                                        </p>
                                    </li>
                                    <li className='listsftwareoldivli'>
                                        <p className='listsftwareoldivlitxtppboxtxt'>
                                        Bringing in huge sustainable cost savings with improved Turnaround time and 100 accuracy
                                        </p>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>


                    <div className='disprocremnchamdivtopbottomdiv'>
                        <div className='disprocremnchamdivtopbottobg'>
                            <div className='disprocremnchamdivtop'>
                                <img src={discover} alt='...' />
                            </div>
                            <div className='disprocremnchamdivbottom'>
                                <h4 className='assitstxtpphhhh'>
                                PROCESS AUTOMATION FOR DRUG RETAILERS IN MARKETPLACE
                                </h4>
                                <ol className="listsftwareoldivol">
                                    <li className='listsftwareoldivli'>
                                        <p className='listsftwareoldivlitxtppboxtxt'>
                                        Explored inheritance applications arranged the data mapping plan
                                        </p>
                                    </li>
                                    <li className='listsftwareoldivli'>
                                        <p className='listsftwareoldivlitxtppboxtxt'>
                                        New work process with extricated & analyzed data

                                        </p>
                                    </li>
                                    <li className='listsftwareoldivli'>
                                        <p className='listsftwareoldivlitxtppboxtxt'>
                                        Significant cost reserves along with 100% accuracy
                                        </p>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>


                    <div className='disprocremnchamdivtopbottomdiv'>
                        <div className='disprocremnchamdivtopbottobg'>
                            <div className='disprocremnchamdivtop'>
                                <img src={automation} alt='...' />
                            </div>
                            <div className='disprocremnchamdivbottom'>
                                <h4 className='assitstxtpphhhh'>
                                TRAVEL BOOKING AUTOMATION USING RPA
                                </h4>
                                <ol className="listsftwareoldivol">
                                    <li className='listsftwareoldivli'>
                                        <p className='listsftwareoldivlitxtppboxtxt'>
                                        Automated cycle of movement set up for the line.
                                        </p>
                                    </li>
                                    <li className='listsftwareoldivli'>
                                        <p className='listsftwareoldivlitxtppboxtxt'>
                                        NLP & RPA solutions with depicting travel inclinations.
                                        </p>
                                    </li>
                                    <li className='listsftwareoldivli'>
                                        <p className='listsftwareoldivlitxtppboxtxt'>
                                        The RPA bots with the NLP support for final itinerary.
                                        </p>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>


                </div>

              



            </div>
        </section>
    )
}

export default SoftwareIot
