import React from 'react'
import { Link } from 'react-router-dom'

const Staffingcontent = () => {
    return (
        <section className='SectorWeServer_sec'>
            <div className='cus_container'>
                {/* <h2 className='sec_heading'>Driving Innovation and Efficiency in a Digital World </h2> */}



                <p className='hlthcaretetx_para_text'>
                In everyday evolving technology time, it’s more important than ever to have an innovation guru on your side. Whether you are working on a new idea or struggling to put one into place, the team at Cygnus is all you need to help you find the best technology solution to provide a perfect fit to your toughest business problems.
                </p>
                <p className='hlthcaretetx_para_text'>
                Cygnus consulting services help organizations better understand emerging technologies, implement agile design standards and take adopt a vital strategy to tools and methodologies. Cygnus consultants work across all IT dimensions—from understanding current business procedures to recognizing gaps to creating making key objectives for digital transformation. Our consulting services allow you to maximize the potential of your business to reveal insights from underlying patterns, derived from your data. We alter our services to deliver solutions that rationalize and simplify the resource deployment and management services.
                </p>
{/* 
                <ul className='setydyydhshjugj'> */}
                   {/* <p className='akjjdjyuhg'> All Staffing Services are : </p> */}
                    {/* <li className='setydyydhshjugjli'>
                        <Link className='setydyydhshjugjliaa' to="/">Contigent Service</Link>
                    </li> */}
                    {/* <li className='setydyydhshjugjli'>
                        <Link className='setydyydhshjugjliaa' to="/">Executive Search</Link>
                    </li>
                    <li className='setydyydhshjugjli'>
                        <Link className='setydyydhshjugjliaa' to="/">Engineering Service</Link>
                    </li> */}
                {/* </ul> */}

            </div>
        </section>


    )
}

export default Staffingcontent
