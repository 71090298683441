import React from 'react'
import Pharmacontent from './Pharmacontent'
import Pharmamain from './Pharmamain'
import Pharmacontentmain from './Pharmacontentmain'

const Pharma = () => {
    return (
        <>
            <Pharmamain />
            <Pharmacontent />
            <Pharmacontentmain />
        </>
    )
}

export default Pharma
