import React from 'react'
import HomeBanner from './HomeBanner'
import OurExperienceSlider from './OurExperienceSlider'
import ServicesHome from './ServicesHome'
import HomeCareer from './HomeCareer'
import ClientSays from '../Work/ClientSays'

const Index = () => {
    return (
        <>
            <HomeBanner />
            <OurExperienceSlider />

            <ServicesHome />

        

            <ClientSays/>

            <HomeCareer/>
        </>
    )
}

export default Index