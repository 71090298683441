import React from 'react'
import Machinelearning from "../../Images/Machinelearning.png";
import PracticeManagement from "../../Images/PracticeManagement.jpg";
import Ehr from "../../Images/Ehr.jpg";
import HospitalManagement from "../../Images/HospitalManagement.png";
import bspoke from "../../Images/bspoke.jpg";
import clicnical from "../../Images/clicnical.png";
import PharmaManufacturing from "../../Images/PharmaManufacturing.jpg";
import PharmaDistribution from "../../Images/PharmaDistribution.jpg"
const Pharmacompliencemaincontent = () => {
  return (
      <>
              <section className="softwaresevices_main" >
                <div className="cus_container">
                    <div className="healthcares_divnewadd">
                        <div className="healthcares_div_left">
                            <div className="">
                                <h4 className="hlthcaretetx_heading_text">Practice Management </h4>
                                <p className="hlthcaretetx_para_text">
                                Streamlining financial, technological, and operational aspects for optimal patient care
                                </p>
                            </div>
                        </div>
                        <div className="healthcares_div_right">
                            <div className="healthcares_div_imgmainalada">
                                <img src={PracticeManagement} alt="..." />
                            </div>
                        </div>
                    </div>

                    <div className="healthcares_divnewadd">
                        <div className="healthcares_div_left">
                            <div className="">
                                <h4 className="hlthcaretetx_heading_text">Electronic Health Records (EHR)</h4>
                                <p className="hlthcaretetx_para_text">
                                Providing unmatched accuracy, security, and accessibility for superior care delivery.
                                </p>
                            </div>
                        </div>
                        <div className="healthcares_div_right">
                            <div className="healthcares_div_imgmainalada">
                                <img src={Ehr} alt="..." />
                            </div>
                        </div>
                    </div>

                    <div className="healthcares_divnewadd">
                        <div className="healthcares_div_left">
                            <div className="">
                                <h4 className="hlthcaretetx_heading_text">Hospital Management Systems (HMS)</h4>
                                <p className="hlthcaretetx_para_text">
                                Revolutionizing hospital administration for enhanced efficiency and exemplary healthcare services.
                                </p>
                            </div>
                        </div>
                        <div className="healthcares_div_right">
                            <div className="healthcares_div_imgmainalada">
                                <img src={HospitalManagement} alt="..." />
                            </div>
                        </div>
                    </div>

                    <div className="healthcares_divnewadd">
                        <div className="healthcares_div_left">
                            <div className="">
                                <h4 className="hlthcaretetx_heading_text">	Bespoke Practice Management Software</h4>
                                <p className="hlthcaretetx_para_text">
                                Tailored solutions for pharmaceutical and life sciences practices, driving operational excellence and growth.

                                </p>
                            </div>
                        </div>
                        <div className="healthcares_div_right">
                            <div className="healthcares_div_imgmainalada">
                                <img src={bspoke} alt="..." />
                            </div>
                        </div>
                    </div>

                    <div className="healthcares_divnewadd">
                        <div className="healthcares_div_left">
                            <div className="">
                                <h4 className="hlthcaretetx_heading_text">	Clinical Trial Management Software</h4>
                                <p className="hlthcaretetx_para_text">
                                Expediting drug development and regulatory processes through advanced tools.

                                </p>
                            </div>
                        </div>
                        <div className="healthcares_div_right">
                            <div className="healthcares_div_imgmainalada">
                                <img src={clicnical} alt="..." />
                            </div>
                        </div>
                    </div>

                    <div className="healthcares_divnewadd">
                        <div className="healthcares_div_left">
                            <div className="">
                                <h4 className="hlthcaretetx_heading_text">Pharma Manufacturing & Commercialization Software</h4>
                                <p className="hlthcaretetx_para_text">
                                Enhancing manufacturing and commercialization workflows for optimal quality and efficiency

                                </p>
                            </div>
                        </div>
                        <div className="healthcares_div_right">
                            <div className="healthcares_div_imgmainalada">
                                <img src={PharmaManufacturing} alt="..." />
                            </div>
                        </div>
                    </div>

                    <div className="healthcares_divnewadd">
                        <div className="healthcares_div_left">
                            <div className="">
                                <h4 className="hlthcaretetx_heading_text">Pharma Distribution Software</h4>
                                <p className="hlthcaretetx_para_text">
                                Optimizing distribution operations through automation and seamless integration.

                                </p>
                            </div>
                        </div>
                        <div className="healthcares_div_right">
                            <div className="healthcares_div_imgmainalada">
                                <img src={PharmaDistribution} alt="..." />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="softwaresevices_main">
                <div className="cus_container">
                    <div>
                        <p className="hlthcaretetx_para_text">
                        At Cygnus, we are committed to delivering groundbreaking solutions that drive success in the pharmaceutical and life sciences sectors. Our dedication to excellence ensures you not only meet but surpass industry standards, setting new benchmarks for compliance and operational brilliance.
                        </p>
                    </div>
                </div>
            </section>
      </>
  )
}

export default Pharmacompliencemaincontent
