import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';

import { Navigation } from 'swiper/modules';
import img2 from "../../Images/Cygnus Pictures/Industrial Solutions/nik-73_kRzs9sqo-unsplash.jpg"


function Telecom() {
    return (
        <>
            <section className="healthcare_main" id='Telecom'>
                <div className="cus_container">
                    <div className="healthcares_div">
                        <div className="healthcares_div_left">
                            <div className="">
                                <h4 className="hlthcaretetx_heading_text">Payment Gateway & Easy Integration</h4>
                                <p className="hlthcaretetx_para_text">
                                Global events have shifted our reality overnight, turning most of our connections virtual and the need for the network services provide has never been greater. Regardless of whether you are hoping to digitalize your organization to scale quicker to satisfy expanding limit needs, limit blackout hazards, or get your groups inclined up on important abilities, Cygnus is here to help.
                                </p>
                            </div>
                        </div>
                        <div className="healthcares_div_right">
                            <div className="healthcares_div_img">
                                <img src={img2} alt="..." />
                            </div>
                        </div>
                    </div>
                    {/* <div className="healthcareswipper">

                        <Swiper
                            slidesPerView={3}
                            spaceBetween={30}
                            navigation={true}
                            breakpoints={{
                                0: {
                                    slidesPerView: 1,
                                },
                                400: {
                                    slidesPerView: 2,
                                },
                                639: {
                                    slidesPerView: 3,
                                },
                            }}
                            modules={[Navigation]}
                            className='mySwiper'
                        >
                            <SwiperSlide className="">
                                <div className="healthcare_divsld_img">
                                    <img src={img2} alt="..." />
                                </div>
                                <div className="">
                                    <p className="slide_text_dtls_pp">
                                        Network planning and Designing
                                    </p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="">
                                <div className="healthcare_divsld_img">
                                    <img src={img3} alt="..." />
                                </div>
                                <div className="">
                                    <p className="slide_text_dtls_pp">
                                        Inventory Management Solution to Enhance Operation
                                    </p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="">
                                <div className="healthcare_divsld_img">
                                    <img src={img4} alt="..." />
                                </div>
                                <div className="">
                                    <p className="slide_text_dtls_pp">
                                        Geo-Spatial Data Analytics
                                    </p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="">
                                <div className="healthcare_divsld_img">
                                    <img src={img3} alt="..." />
                                </div>
                                <div className="">
                                    <p className="slide_text_dtls_pp">
                                        Inventory Management Solution to Enhance Operation
                                    </p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="">
                                <div className="healthcare_divsld_img">
                                    <img src={img4} alt="..." />
                                </div>
                                <div className="">
                                    <p className="slide_text_dtls_pp">
                                        Geo-Spatial Data Analytics
                                    </p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="">
                                <div className="healthcare_divsld_img">
                                    <img src={img3} alt="..." />
                                </div>
                                <div className="">
                                    <p className="slide_text_dtls_pp">
                                        Inventory Management Solution to Enhance Operation
                                    </p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="">
                                <div className="healthcare_divsld_img">
                                    <img src={img4} alt="..." />
                                </div>
                                <div className="">
                                    <p className="slide_text_dtls_pp">
                                        Geo-Spatial Data Analytics
                                    </p>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div> */}
                </div>
            </section>
        </>
    )
}

export default Telecom