import React from 'react'
import "../../Css/Team.css";
import Innerbanner from './Innerbanner';
import OurFounder from './OurFounder';
import ExpertTeam from './ExpertTeam';
import Advisioryboard from './Advisioryboard';
import GurudasComp from './GurudasComp';

const Myteam = () => {
    return (
        <>
         <Innerbanner/>
         <OurFounder/>

         <GurudasComp/>
         <ExpertTeam/>
         <Advisioryboard/>
        </>
    )
}

export default Myteam
