import React from 'react'
import img4 from "../../Images/Cygnus Pictures/Industrial Solutions/PracticeManagement.png";
const PracticeManagement = () => {
  return (
    <section className="healthcare_main" id='PharmaceuticalIndustry'>
    <div className="cus_container">
        <div className="healthcares_div">
            <div className="healthcares_div_left">
                <div className="">
                    <h4 className="hlthcaretetx_heading_text">Practice Management Software</h4>
                    <p className="hlthcaretetx_para_text">
                    Practice management includes the activities, actions and resources that guarantee the everyday tasks of clinical practice. Dealing with services practice can be difficult and tedious may manage financial, and technological tasks on top of ensuring patients get the most ideal consideration.

                    </p>
                </div>
            </div>
            <div className="healthcares_div_right">
                <div className="healthcares_div_img">
                    <img src={img4} alt="..." />
                </div>
            </div>
        </div>
        {/* <div className="healthcareswipper">

        <Swiper
                slidesPerView={3}
                spaceBetween={30}
                navigation={true} 
                modules={[Navigation]}
                breakpoints={{
                    0: {
                      slidesPerView: 1,
                    },
                    400:{
                      slidesPerView:2,
                    },
                    639: {
                      slidesPerView: 3,
                    },
                  }}
                className='mySwiper'
            >
                <SwiperSlide className="">
                    <div className="healthcare_divsld_img">
                        <img src={img2} alt="..." />
                    </div>
                    <div className="">
                        <p className="slide_text_dtls_pp">
                            Clinical Management Software Development
                        </p>
                    </div>
                </SwiperSlide>
                <SwiperSlide className="">
                    <div className="healthcare_divsld_img">
                        <img src={img3} alt="..." />
                    </div>
                    <div className="">
                        <p className="slide_text_dtls_pp">Pharma Manufacturing & commercialization Software Development</p>
                    </div>
                </SwiperSlide>
                <SwiperSlide className="">
                    <div className="healthcare_divsld_img">
                        <img src={img4} alt="..." />
                    </div>
                    <div className="">
                        <p className="slide_text_dtls_pp">Pharma Distribution Software Development</p>
                    </div>
                </SwiperSlide>
                <SwiperSlide className="">
                    <div className="healthcare_divsld_img">
                        <img src={img3} alt="..." />
                    </div>
                    <div className="">
                        <p className="slide_text_dtls_pp">Pharma Manufacturing & commercialization Software Development</p>
                    </div>
                </SwiperSlide>
                <SwiperSlide className="">
                    <div className="healthcare_divsld_img">
                        <img src={img4} alt="..." />
                    </div>
                    <div className="">
                        <p className="slide_text_dtls_pp">Pharma Distribution Software Development</p>
                    </div>
                </SwiperSlide>
                <SwiperSlide className="">
                    <div className="healthcare_divsld_img">
                        <img src={img3} alt="..." />
                    </div>
                    <div className="">
                        <p className="slide_text_dtls_pp">Pharma Manufacturing & commercialization Software Development</p>
                    </div>
                </SwiperSlide>
                <SwiperSlide className="">
                    <div className="healthcare_divsld_img">
                        <img src={img4} alt="..." />
                    </div>
                    <div className="">
                        <p className="slide_text_dtls_pp">Pharma Distribution Software Development</p>
                    </div>
                </SwiperSlide>
            </Swiper>
        </div> */}
    </div>
</section>
  )
}

export default PracticeManagement
