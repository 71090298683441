import React from 'react'
import Retailmain from './Retailmain'
import Retailcontent from './Retailcontent'
import Retailcontentmain from './Retailcontentmain'

const Retail = () => {
  return (
    <>
      <Retailmain />
      <Retailcontent />
      <Retailcontentmain />
    </>
  )
}

export default Retail
