import React from 'react'
import alexa from "../../Images/alexa.png";
import discover from "../../Images/discover.jpg"
const Softwarealexa = () => {
    return (
        <>
            <section className="softwaresevices_main" >
                <div className="cus_container">
                    <div className="healthcares_div">
                        <div className="healthcares_div_left">
                            <div className="">
                                <h4 className="hlthcaretetx_heading_text">EXPLORE OUR CYGNUS SOLUTIONS FOR ALEXA AND GOOGLE ASSISTANT</h4>
                                <p className="hlthcaretetx_para_text">
                                    According to a Google Blog Post, “41% of folks who have voice-activated speakers say that using it feels like talking to a friend, or at least another person. They are even saying ‘please,’ ‘thank you,’ and even ‘sorry’.”
                                </p>
                                <ol className='listsftwareoldivol'>
                                    <li className='listsftwareoldivli'>
                                        <p className='listsftwareoldivlitxtpp'>
                                            It is clear that capitalizing on powerful enterprise solutions for Alexa and Google Home assistants directly means establishing your brand in an “at-home” atmosphere while superbly excelling in offering greater customer satisfaction and ensuring repeat business.
                                        </p>
                                    </li>


                                    <li className='listsftwareoldivli'>


                                        <p className='listsftwareoldivlitxtpp'>
                                            It is clear that capitalizing on powerful enterprise solutions for Alexa and Google Home assistants directly means establishing your brand in an “at-home” atmosphere while superbly excelling in offering greater customer satisfaction and ensuring repeat business.
                                        </p>


                                    </li>

                                    <li className='listsftwareoldivli'>
                                        <p className='listsftwareoldivlitxtpp'>
                                            Our experts can support your business from design through implementation to ongoing maintenance and future.
                                        </p>
                                    </li>
                                </ol>
                            </div>
                        </div>
                        <div className="healthcares_div_right">
                            <div className="healthcares_div_img">
                                <img src={alexa} alt="..." />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='softwaresevices_main'>
                <div className='cus_container'>
                    <div className=''>
                        <h4 className='assisttxtpp'>MAKE VOICE-ACTIVATED SPEAKER YOUR BRAND EXTENSION</h4>

                        <div className='softeawrealexalefmaindivflx'>
                        
                            <div className='disprocremnchamdivtopbottomdiv'>
                                <div className='disprocremnchamdivtopbottobg'>
                                    <div className='disprocremnchamdivtop'>
                                        <img src={discover} alt='...' />
                                    </div>
                                    <div className='disprocremnchamdivbottom'>
                                        <h4 className='assitstxtpphhhh'>
                                            DELIVER GREAT EXPERIENCES ACROSS PLATFORMS
                                        </h4>
                                        <ol className="listsftwareoldivol">
                                            <li className='listsftwareoldivli'>
                                                <p className='listsftwareoldivlitxtppboxtxt'>
                                                    Faster deployment.
                                                </p>
                                            </li>
                                            <li className='listsftwareoldivli'>
                                                <p className='listsftwareoldivlitxtppboxtxt'>
                                                    Competitive cost.
                                                </p>
                                            </li>
                                            <li className='listsftwareoldivli'>
                                                <p className='listsftwareoldivlitxtppboxtxt'>
                                                    On-going Maintenance.
                                                </p>
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                            </div>


                            <div className='disprocremnchamdivtopbottomdiv'>
                                <div className='disprocremnchamdivtopbottobg'>
                                    <div className='disprocremnchamdivtop'>
                                        <img src={discover} alt='...' />
                                    </div>
                                    <div className='disprocremnchamdivbottom'>
                                        <h4 className='assitstxtpphhhh'>
                                        TURN INSIGHTS INTO POWERFUL ACTION
                                        </h4>
                                        <ol className="listsftwareoldivol">
                                            <li className='listsftwareoldivli'>
                                                <p className='listsftwareoldivlitxtppboxtxt'>
                                                Gain application usage.
                                                </p>
                                            </li>
                                            <li className='listsftwareoldivli'>
                                                <p className='listsftwareoldivlitxtppboxtxt'>
                                                Discover pain points and plan to add additional.
                                                </p>
                                            </li>
                                            <li className='listsftwareoldivli'>
                                                <p className='listsftwareoldivlitxtppboxtxt'>
                                                Take the application to the next.
                                                </p>
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                            </div>


                            <div className='disprocremnchamdivtopbottomdiv'>
                                <div className='disprocremnchamdivtopbottobg'>
                                    <div className='disprocremnchamdivtop'>
                                        <img src={discover} alt='...' />
                                    </div>
                                    <div className='disprocremnchamdivbottom'>
                                        <h4 className='assitstxtpphhhh'>
                                        GAIN INCREASED VISIBILITY INTO CUSTOMER EXPERIENCES
                                        </h4>
                                        <ol className="listsftwareoldivol">
                                            <li className='listsftwareoldivli'>
                                                <p className='listsftwareoldivlitxtppboxtxt'>
                                                Repeat Users.
                                                </p>
                                            </li>
                                            <li className='listsftwareoldivli'>
                                                <p className='listsftwareoldivlitxtppboxtxt'>
                                                Average time to complete transactions.
                                                </p>
                                            </li>
                                            <li className='listsftwareoldivli'>
                                                <p className='listsftwareoldivlitxtppboxtxt'>
                                                Features accessed by users.
                                                </p>
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
            </section>
        </>
    )
}

export default Softwarealexa
