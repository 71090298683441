import React from 'react'
import Engineeringmain from './Engineeringmain'
import Engineeringcontent from './Engineeringcontent'
import Engineeringcontentmain from './Engineeringcontentmain'
const Engineeringindex = () => {
  return (
   <>
     <Engineeringmain/>
     <Engineeringcontent/>
     <Engineeringcontentmain/>
   </>
  )
}

export default Engineeringindex

