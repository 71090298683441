import React from 'react'
import Cybersecuritymain from './Cybersecuritymain'
import Cybersecuritycontent from './Cybersecuritycontent'
import Cybersecuritymaincontent from './Cybersecuritymaincontent'

const Cybersecurity = () => {
    return (
        <>
            <Cybersecuritymain />
            <Cybersecuritycontent />
            <Cybersecuritymaincontent />
        </>
    )
}

export default Cybersecurity
