import React from 'react'

import img1 from "../../Images/Cygnus Pictures/Industrial Solutions/pina-messina-kfJkpeI6Lgc-unsplash.jpg";
import img2 from "../../Images/Cygnus Pictures/Industrial Solutions/thisisengineering-mvbtVeRVJzg-unsplash.jpg";
import img3 from "../../Images/Cygnus Pictures/Industrial Solutions/Screenshot 2024-07-11 172342.png";
import img4 from "../../Images/Cygnus Pictures/Industrial Solutions/Screenshot 2024-07-11 172510.png";
const Pharmacontentmain = () => {
  return (
    <>
      <section className="softwaresevices_main" >
        <div className="cus_container">
          <div className="healthcares_divnewadd">
            <div className="healthcares_div_left">
              <div className="">
                <h4 className="hlthcaretetx_heading_text">Pharmaceutical and Life Sciences Industry</h4>
                <p className="hlthcaretetx_para_text">
                  Serving the needs of pharmaceutical and life-science medical services through the technology with an extraordinary depth and breadth of services. With agenda of Innovative, cost effective solutions to meet the demands companies that are seeking ways to enrich the R&D productivity, increase the efficiency of its operations.
                </p>
              </div>
            </div>
            <div className="healthcares_div_right">
              <div className="healthcares_div_img">
                <img src={img1} alt="..." />
              </div>
            </div>
          </div>

          <div className="healthcares_divnewadd">
            <div className="healthcares_div_left">
              <div className="">
                <h4 className="hlthcaretetx_heading_text">Clinical Trail Management Software Development</h4>
                <p className="hlthcaretetx_para_text">
                  Speed up your business drug advancement and administrative cycles with a protected and grievance preclinical and clinical preliminary administration programming, like Electronic Patient-Reported Outcome data collection, Patient recruitment solutions, Early clinical data management solutions.
                </p>
              </div>
            </div>
            <div className="healthcares_div_right">
              <div className="healthcares_div_img">
                <img src={img2} alt="..." />
              </div>
            </div>
          </div>

          <div className="healthcares_divnewadd">
            <div className="healthcares_div_left">
              <div className="">
                <h4 className="hlthcaretetx_heading_text">Pharma Manufacturing & commercialization Software Development</h4>
                <p className="hlthcaretetx_para_text">
                  Stay coordinated, keep up severe quality administration and smooth out pharma assembling to meet customer’s determinations and administrative prerequisites with the assistance such as Production planning and procurement software, Traceability and recall management software, Product quality management software.
                </p>
              </div>
            </div>
            <div className="healthcares_div_right">
              <div className="healthcares_div_img">
                <img src={img3} alt="..." />
              </div>
            </div>
          </div>

          <div className="healthcares_divnewadd">
            <div className="healthcares_div_left">
              <div className="">
                <h4 className="hlthcaretetx_heading_text">Pharma Distribution Software Development</h4>
                <p className="hlthcaretetx_para_text">
                  Smooth out the progression of requesting, selling, warehousing and delivery drug and life sciences items with the assistance of Accounting, customer, and service management. Automation and inventory software, Warehouse management solutions, Logistics solutions.
                </p>
              </div>
            </div>
            <div className="healthcares_div_right">
              <div className="healthcares_div_img">
                <img src={img4} alt="..." />
              </div>
            </div>
          </div>





        </div>
      </section>
    </>
  )
}

export default Pharmacontentmain
